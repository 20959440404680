import {
  BaseShift,
  BaseInterval,
  HalfHourInterval,
  DayInterval,
} from '@shared/components/basetimeline';
import { VacayRequestModel } from '@shared/models';
import { FULL_DATE_FORMAT } from '@helpers';


export class Shift extends BaseShift {

  private _fromBase;
  private _toBase;

  constructor(protected _request: VacayRequestModel) {
    super(
      _request.code,
      new Date(_request.dateFrom.format(FULL_DATE_FORMAT)),
      new Date(_request.dateTo.format(FULL_DATE_FORMAT))
    );

    const from = this._dateFrom;
    const to = this._dateTo;

    this._fromBase = new Date(from.getFullYear(), from.getMonth(), from.getDate());

    // fixme date to in database, must be same date all the time
    this._toBase = new Date(to.getFullYear(), to.getMonth(), to.getDate());
    if (from.getDate() == to.getDate()) {
      this._toBase.setDate(this._toBase.getDate() + 1);
    }
  }

  /**
   * Get interval width
   * @returns {number}
   */
  public width() {
    const config = this._grid.config();
    const hours = config.interval == HalfHourInterval;
    const scale = this._grid.scale();
    const from = hours ? this._dateFrom.getTime() : this._fromBase.getTime();
    const to = hours ? this._dateTo.getTime() : this._toBase.getTime();

    return scale.invert(to - from);
  }

  /**
   * Replaced standard comparator
   * @param interval
   * @returns {boolean}
   */
  public compare(interval: BaseInterval) {
    const config = this._grid.config();
    switch (config.interval) {
      case DayInterval:
        return interval.dateFrom().getTime() == this._fromBase.getTime();
      case HalfHourInterval:
        return interval.dateFrom().getTime() == this._dateFrom.getTime();
    }
  }

}

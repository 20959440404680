import { EventEmitter } from '@angular/core';

import { BaseShift } from './base.shift';
import { Timeline } from '../timeline';
import { Grid } from '../grid/grid';
import { IBrushConfig } from '../brush/brush';
import { RelatedResizableShift } from './related-resizable.shift';

import { pathForRoundedRect, translate, scale, xmlns } from '@helpers';

import * as d3 from 'd3';
import * as moment from 'moment-mini';
import { debounce as _debounce } from 'lodash-es';

export interface IResizableShiftConfig extends IBrushConfig {
  leftLimit?: number;
  rightLimit?: number;
}

export class ResizableShift extends BaseShift {
  get isChanged() {
    return !(this.initialPosition.x1 === this.tagsPositions.left && this.initialPosition.x2 === this.tagsPositions.right);
  }
  public onCancel = new EventEmitter();
  public onApply = new EventEmitter();

  protected $bodyContainer: d3.Selection<any, any, any, any>;

  protected $left_time: d3.Selection<any, any, any, any>;
  protected $left_time_bg: d3.Selection<any, any, any, any>;
  protected $left_time_text: d3.Selection<any, any, any, any>;

  protected $right_time: d3.Selection<any, any, any, any>;
  protected $right_time_bg: d3.Selection<any, any, any, any>;
  protected $right_time_text: d3.Selection<any, any, any, any>;

  protected $leftCircle: d3.Selection<any, any, any, any>;
  protected $rightCircle: d3.Selection<any, any, any, any>;

  protected $dialogContainer: d3.Selection<any, any, any, any>;
  protected $dialogApplyButton: d3.Selection<any, any, any, any>;
  protected $dialogCancelButton: d3.Selection<any, any, any, any>;

  protected _dragDebouncer;

  protected _dragLeftTag: d3.DragBehavior<any, any, any>;
  protected _dragRightTag: d3.DragBehavior<any, any, any>;

  protected scale = d3.scaleTime();
  protected ticksPositionsList: number[] = [];

  protected tagsPositions = {
    left: 0,
    right: 0
  };

  protected positions = {
    baseX1: 0,
    baseX2: 0,
    x1: 0,
    x2: 0,
    y: 0
  };

  protected _sizes = {
    width: 0,
    shiftMargin: 0,
    semiWidth: 11,
    shiftHeight: 0,
    offset: 0
  };

  protected _config: IResizableShiftConfig = {
    startDay: null,
    endDay: null,
    shortDateFormat: 'HH:mm',
    fullDateFormat: 'D MMM HH:mm',
    tickFunction: d3.timeMinute,
    tickInterval: 30
  };

  protected relatedShifts = {
    left: {
      related: null,
      active: [],
      overlap: []
    },
    right: {
      related: null,
      active: [],
      overlap: []
    }
  };

  protected initialPosition = {
    x1: 0,
    x2: 0
  };

  private _mouseOverBody = false;
  private inDragMode = false;

  constructor(protected _shift: BaseShift, config: IResizableShiftConfig = {}) {
    super(_shift.title, _shift.dateFrom(), _shift.dateTo());

    this._timeline = _shift.timeline() as Timeline;
    this._grid = _shift.grid() as Grid;
    this._item = _shift.item() as any;
    this._interval = _shift.interval() as any;
    this._defs = _shift.defs() as any;

    this.updateConfig(config);
  }

  public render() {
    this.destroy();

    this.initFakeRelatedShifts();

    this.scaleInit();

    this.initSizes();

    this.InitAndUpdateNodes();

    this.$el.classed('resizable-shift', true);
    this.$el.attr('transform', translate(this._interval.x(), this.positions.y));

    this.$leftCircle.attr('x', this.calcTagPosition('left')).attr('y', this._sizes.shiftHeight / 2 + 11 / 2 + this._sizes.offset);
    this.$leftCircle.call(this._dragLeftTag);

    this.$rightCircle
      .attr('x', this.calcTagPosition('right'))
      .attr('y', this._sizes.shiftHeight / 2 + this._sizes.semiWidth / 2 + this._sizes.offset);
    this.$rightCircle.call(this._dragRightTag);

    this._timeline.overMenuSysContainer.node().appendChild(this.el);

    // Dialog
    this.renderDialog();

    this.events();

    /** Tooltips init **/
    this.initLeftTextTooltips();
    this.initRightTextTooltips();

    this._config.startDay = moment(this.dateFrom()).dayOfYear();
    this._config.endDay = moment(this.dateTo()).dayOfYear();

    // Create and move tooltips to their positions
    this.moveTooltip(this.$left_time, this.$left_time_bg, this.$left_time_text, this.tagsPositions.left, this.findLeftNearTick);
    this.moveTooltip(
      this.$right_time,
      this.$right_time_bg,
      this.$right_time_text,
      this.tagsPositions.right,
      this.findRightNearTick
    );

    this.changeArrowMode('drag');

    this._dragDebouncer = _debounce(() => {
      this.inDragMode = false;
      this.changeArrowMode('still');

      if (this._mouseOverBody) {
        this.hideDialog();
        this.showTime();
      } else {
        setTimeout(() => {
          this.hideTime();
          this.showDialog();
        }, 300);
      }
    }, 200);

    return this.el;
  }

  /**
   * Initialize scale line and ticks position list
   */
  public scaleInit() {
    const sizes = this._timeline.sizes();

    const intervals = this._grid.intervals();
    const firstInterval = intervals[0];
    const lastInterval = intervals[intervals.length - 1];

    this.scale
      .domain([firstInterval.dateFrom(), lastInterval.dateTo()])
      .range([firstInterval.x(), lastInterval.x() + sizes.intervalWidth]);

    const ticks = this.scale.ticks(this._config.tickFunction.every(this._config.tickInterval));
    ticks.forEach(tick => {
      this.ticksPositionsList.push(Math.round(this.scale(tick)));
    });
  }

  public events() {
    this.$el.on('mouseenter', () => {
      if (!this.inDragMode) {
        this.changeArrowMode('drag');
      }
    });

    this.$el.on('mouseleave', () => {
      if (!this.inDragMode) {
        this.changeArrowMode('still');
      }
    });

    this.$bodyContainer.on('mouseenter', () => {
      this._mouseOverBody = true;
      if (this.inDragMode) {
        return;
      }

      this.showTime();
      this.hideDialog();
    });

    this.$bodyContainer.on('mouseleave', () => {
      this._mouseOverBody = false;
      if (this.inDragMode) {
        return;
      }

      setTimeout(() => {
        this.hideTime();
        this.showDialog();
      }, 300);
    });

    this.$dialogCancelButton.on('click', () => {
      this.destroy();
      this.onCancel.emit(true);
    });
    this.$dialogApplyButton.on('click', () => {
      this.destroy();
      this.onApply.emit(this.shiftsResult());
    });

    this._dragLeftTag.on('start', () => {
      this.inDragMode = true;
      this.changeArrowMode('drag');
      this.hideDialog();
      this._dragDebouncer.cancel();
    });

    this._dragLeftTag.on('end', () => {
      this._dragDebouncer();
    });

    this._dragRightTag.on('start', () => {
      this.inDragMode = true;
      this.changeArrowMode('drag');
      this.hideDialog();
      this._dragDebouncer.cancel();
    });

    this._dragRightTag.on('end', () => {
      this._dragDebouncer();
    });

    this._dragLeftTag.on('drag', () => this.moveLeft());

    this._dragRightTag.on('drag', () => this.moveRight());
  }

  /**
   * On move left tag
   */
  public moveLeft() {
    const dx = d3.event.dx;
    const x = this.positions.x1 + dx;

    const nearPos = this.findLeftNearPosition(x + this._interval.x());
    const newX = nearPos - this._interval.x() + this._sizes.shiftMargin;

    const intervals = this._grid.intervals();
    const intervalAfterMenu = intervals[this._grid.countIntervalsUnderMenu()];

    if (
      this.tagsPositions.right - newX < 10 ||
      (this._config.leftLimit && nearPos < this._config.leftLimit) ||
      (intervalAfterMenu && nearPos < intervalAfterMenu.x())
    ) {
      return;
    }

    if (this.tagsPositions.left !== newX) {
      this.tagsPositions.left = newX;
      this.positions.baseX1 = nearPos;
      this.updateLeftSideRelatedShifts(nearPos);

      this.updateLeftMovableNodes();
      this.moveTooltip(this.$left_time, this.$left_time_bg, this.$left_time_text, this.tagsPositions.left, this.findLeftNearTick);
    }

    this.positions.x1 = x;
  }

  /**
   * Update left related shifts
   * @param pos
   */
  public updateLeftSideRelatedShifts(pos) {
    // Update left related shift
    if (this.relatedShifts.left.related) {
      this.relatedShifts.left.related.updateLength(pos);
    }

    // Get new overlaped
    const overlaped = this.overlapLeft();

    // Update previous overlaped shifts
    this.relatedShifts.left.overlap.forEach(sh => {
      sh.updateLength(pos);
    });

    this.relatedShifts.left.overlap = overlaped;

    // Update overlaped shifts
    overlaped.forEach(sh => {
      sh.updateLength(pos);
    });
  }

  /**
   * On move right tag
   */
  public moveRight() {
    const circle = this.$rightCircle;
    const x = this.positions.x2 + d3.event.dx;

    const newPos = this.findRightNearPosition(x + this._interval.x());
    const newX = newPos - this._interval.x();

    if (
      newX + this._sizes.shiftMargin - this.tagsPositions.left < 10 ||
      (this._config.rightLimit && newPos > this._config.rightLimit)
    ) {
      return;
    }

    if (+circle.attr('x') !== newX) {
      this.tagsPositions.right = newX;
      this.positions.baseX2 = newPos;
      this.updateRightSideRelatedShifts(newPos);

      this.updateRightMovableNodes();
      this.moveTooltip(
        this.$right_time,
        this.$right_time_bg,
        this.$right_time_text,
        this.tagsPositions.right,
        this.findRightNearTick
      );
    }
    this.positions.x2 = x;
  }

  /**
   * Update right related shifts
   * @param pos
   */
  public updateRightSideRelatedShifts(pos) {
    // Update right related shift
    if (this.relatedShifts.right.related) {
      this.relatedShifts.right.related.updateLength(pos);
    }

    // Get new overlaped
    const overlaped = this.overlapRight();

    // Update previous overlaped shifts
    this.relatedShifts.right.overlap.forEach(sh => {
      sh.updateLength(pos);
    });

    this.relatedShifts.right.overlap = overlaped;

    // Update overlaped shifts
    overlaped.forEach(sh => {
      sh.updateLength(pos);
    });
  }

  /**
   * Destroy shift
   */
  public destroy() {
    // We should use try/catch here because at the first initialize we don't have these variables
    try {
      this.$el.on('mouseenter', null);
      this.$el.on('mouseleave', null);

      this.$dialogCancelButton.on('click', null);
      this.$dialogApplyButton.on('click', null);

      this._dragLeftTag.on('start', null);
      this._dragLeftTag.on('end', null);

      this._dragRightTag.on('start', null);
      this._dragRightTag.on('end', null);

      this._dragLeftTag.on('drag', null);
      this._dragRightTag.on('drag', null);
    } catch (e) { }

    this.ticksPositionsList.length = 0;

    if (this.relatedShifts.left.related) {
      this.relatedShifts.left.related.ownShift.show();
      this.relatedShifts.left.related.destroy();
    }
    if (this.relatedShifts.right.related) {
      this.relatedShifts.right.related.ownShift.show();
      this.relatedShifts.right.related.destroy();
    }

    this.relatedShifts.left.active.concat(this.relatedShifts.right.active).forEach(shift => {
      shift.ownShift.show();
      shift.destroy();
    });

    this.$el.selectAll('*').remove();
    this.$el.node().remove();
  }

  protected initSizes() {
    const sizes = this._timeline.sizes();
    const rh = this._item.config.height;
    const shiftMargin = sizes.shiftMargin;
    const width = Math.round(this.width() - shiftMargin * 2); // fixme any ideas ?

    this._sizes.shiftHeight = rh * 0.825;
    this._sizes.offset = rh * 0.0925;

    this.positions.y = this._item.shiftPosition(this);
    this.positions.x2 = width + shiftMargin;

    this.positions.baseX1 = this._interval.x();
    const endInterval = this.endInterval();

    if (endInterval) {
      this.positions.baseX2 = endInterval.x();
    }

    this._sizes.shiftMargin = shiftMargin;

    this._sizes.width = width;

    this.tagsPositions.left = shiftMargin;
    this.tagsPositions.right = this.findRightNearPosition(width);

    this.initialPosition.x1 = this.tagsPositions.left;
    this.initialPosition.x2 = this.tagsPositions.right;
  }

  /**
   * Render cancel/apply dialog
   */
  protected renderDialog() {
    const container = document.createElementNS(xmlns, 'g');
    this.$dialogContainer = d3.select(container).classed('dialog-container', true);

    this.$dialogCancelButton = this.$dialogContainer.append('g').classed('cancel-btn', true);

    this.$dialogCancelButton
      .append('rect')
      .attr('width', 40)
      .attr('height', 28)
      .attr('rx', 3)
      .attr('ry', 3);

    this.$dialogCancelButton
      .append('text')
      .attr('class', 'material-icons')
      .text('clear')
      .attr('x', 10)
      .attr('y', 24);

    this.$dialogApplyButton = this.$dialogContainer.append('g').classed('apply-btn', true);

    this.$dialogApplyButton
      .append('rect')
      .attr('width', 40)
      .attr('height', 28)
      .attr('rx', 3)
      .attr('ry', 3);

    this.$dialogApplyButton
      .append('text')
      .attr('class', 'material-icons')
      .text('check')
      .attr('x', 10)
      .attr('y', 24);
  }

  /**
   * Show cancel/apply dialog
   */
  protected showDialog() {
    if (!this.isChanged) {
      return;
    }
    this.$el.node().appendChild(this.$dialogContainer.node());

    const buttonWidth = this.$dialogCancelButton.node().getBoundingClientRect().width;

    this.$dialogApplyButton.attr('transform', translate(buttonWidth + 5, 0));

    const dialogWidth = this.$dialogContainer.node().getBoundingClientRect().width;

    this.$dialogContainer.attr('transform', translate(this._sizes.width / 2 - dialogWidth / 2 + this.tagsPositions.left, -27));
  }

  /**
   * Hide cancel/apply dialog
   */
  protected hideDialog() {
    this.$el.selectAll('.dialog-container').remove();
  }

  protected showTime() {
    this.$left_time.attr('visibility', 'visible');
    this.$right_time.attr('visibility', 'visible');
  }

  protected hideTime() {
    this.$left_time.attr('visibility', 'hidden');
    this.$right_time.attr('visibility', 'hidden');
  }

  /**
   * Init nodes from cloned element
   * @constructor
   */
  protected InitAndUpdateNodes() {
    this.$bodyContainer = this.$el.append('g').classed('body-container', true);

    const baseShift = d3.select<any, any>(this._shift.clone());

    this.el = this.$el.node();

    this.$shadow = baseShift.select('.shadow');
    this.$background = baseShift
      .select('.background')
      .attr('stroke', '#2d425d')
      .attr('stroke-dasharray', '5,5')
      .attr('stroke-linecap', 'round');

    this.$title = baseShift.select('text');

    const decorator = baseShift.select('.decorator');
    if (decorator) {
      this.$decorator = decorator;
    }

    this.$bodyContainer.node().appendChild(this.$shadow.node());
    this.$bodyContainer.node().appendChild(this.$background.node());
    this.$bodyContainer.node().appendChild(this.$decorator.node());
    this.$bodyContainer.node().appendChild(this.$title.node());

    this.$leftCircle = this.$bodyContainer
      .append('use')
      .classed('circle-tag', true)
      .attr('href', this._defs.semisphere());

    this.$rightCircle = this.$bodyContainer
      .append('use')
      .classed('circle-tag', true)
      .attr('transform', scale(-1, 1))
      .attr('href', this._defs.semisphere());

    this._dragLeftTag = d3.drag();
    this._dragRightTag = d3.drag();
  }

  protected initFakeRelatedShifts() {
    // all item shifts
    const itemShifts = (this.item() as any).shifts() ;

    let leftShiftTo;
    // Get left-hand side item
    const leftShift = this._item.leftHandSideShift(this._shift);
    const leftDiff = this.differenceBetweenShiftsInIntervals(leftShift, this._shift);

    if (leftShift && leftDiff !== false && leftDiff === 0) {
      this.relatedShifts.left.related = new RelatedResizableShift(leftShift as any, 'left', true);
      leftShift.hide();
      leftShiftTo = leftShift;
    } else {
      leftShiftTo = this._shift;
    }

    // Get all items from left side exclude first left item
    this.relatedShifts.left.active = itemShifts.slice(0, itemShifts.indexOf(leftShiftTo)).reduce((acc, item) => {
      if (item.interval()) {
        const newSh = new RelatedResizableShift(item, 'left');
        item.hide();
        acc.push(newSh);
      }
      return acc;
    }, []);

    let rightShiftTo;
    // Get left-hand side item
    const rightShift = this._item.rightHandSideShift(this._shift);
    const rightDiff = this.differenceBetweenShiftsInIntervals(this._shift, rightShift);

    if (rightShift && rightDiff !== false && rightDiff === 0) {
      this.relatedShifts.right.related = new RelatedResizableShift(rightShift as any, 'right', true);
      rightShift.hide();
      rightShiftTo = rightShift;
    } else {
      rightShiftTo = this._shift;
    }

    // Get all items from right side exclude first left item
    this.relatedShifts.right.active = itemShifts
      .slice(itemShifts.indexOf(rightShiftTo) + 1, itemShifts.length)
      .reduce((acc, item) => {
        if (item.interval()) {
          const newSh = new RelatedResizableShift(item, 'right');
          item.hide();
          acc.push(newSh);
        }

        return acc;
      }, []);
  }

  /**
   * Merge config
   * @param config
   */
  protected updateConfig(config: IBrushConfig) {
    Object.assign(this._config, config);
  }

  /**
   * Init tooltip groups
   */
  protected initLeftTextTooltips() {
    this.$left_time = this.$el.append('g').classed('time-places', true);
    this.$left_time_bg = this.$left_time
      .append('rect')
      .attr('height', 18)
      .attr('rx', 3)
      .attr('ry', 3);
    this.$left_time_text = this.$left_time.append('text').attr('y', 13);
  }

  /**
   * Init tooltip groups
   */
  protected initRightTextTooltips() {
    this.$right_time = this.$el.append('g').classed('time-places', true);
    this.$right_time_bg = this.$right_time
      .append('rect')
      .attr('height', 18)
      .attr('rx', 3)
      .attr('ry', 3);
    this.$right_time_text = this.$right_time.append('text').attr('y', 13);
  }

  /**
   * Update all related nodes when moving left tag
   */
  protected updateLeftMovableNodes() {
    this.$leftCircle.attr('x', this.calcTagPosition('left'));

    this._sizes.width = this.tagsPositions.right - this.tagsPositions.left - this._sizes.shiftMargin;
    this.$shadow.attr('x', this.tagsPositions.left).attr('width', this._sizes.width);
    this.$background.attr('x', this.tagsPositions.left).attr('width', this._sizes.width);

    this.$title.attr('x', this._sizes.width / 2 + this.tagsPositions.left);

    if (this.$decorator) {
      this.$decorator
        .attr('d', pathForRoundedRect(0, 0, this._sizes.width, 4, 2.5, false, false, true, true))
        .attr('transform', translate(this.tagsPositions.left, this._sizes.shiftHeight));
    }
  }

  /**
   * Update all related nodes when moving right tag
   */
  protected updateRightMovableNodes() {
    this.$rightCircle.attr('x', this.calcTagPosition('right'));

    this._sizes.width = this.tagsPositions.right - this.tagsPositions.left - this._sizes.shiftMargin;

    this.$shadow.attr('width', this._sizes.width);
    this.$background.attr('width', this._sizes.width);

    this.$title.attr('x', this._sizes.width / 2 + this.tagsPositions.left);

    if (this.$decorator) {
      this.$decorator
        .attr('d', pathForRoundedRect(0, 0, this._sizes.width, 4, 2.5, false, false, true, true))
        .attr('transform', translate(this.tagsPositions.left, this._sizes.shiftHeight));
    }
  }

  /**
   * Move tooltip and change format if it's needed
   * @param el
   * @param el_bg
   * @param el_text
   * @param correctPosition
   * @param tickFunction
   */
  protected moveTooltip(el, el_bg, el_text, correctPosition, tickFunction) {
    const tick = tickFunction.call(this, correctPosition + this._interval.x());

    if (!tick) {
      return;
    }

    const currentDate = moment(tick.getTime());
    this._config.startDay = currentDate.dayOfYear();

    let format;

    if (this._config.startDay !== this._config.endDay) {
      format = this._config.fullDateFormat;
    } else {
      format = this._config.shortDateFormat;
    }

    el_text.text(moment(tick.getTime()).format(format));
    const tw = el_text.node().getBoundingClientRect();
    const w = tw.width + 6;
    el_text.attr('x', w / 2);
    el_bg.attr('width', w);

    el.attr('transform', translate(-w / 2 + correctPosition, -20));
  }

  /**
   * Looking near tick from left-hand-side
   * @param position - position by x
   * @returns {any}
   */
  protected findLeftNearTick(position) {
    const ticks = this.scale.ticks(this._config.tickFunction.every(this._config.tickInterval));

    let result = null;
    ticks.forEach(tick => {
      const pos = this.scale(tick);

      if (pos <= position) {
        result = tick;
      }
    });
    return result;
  }

  /**
   * Looking near tick from right-hand-side
   * @param position
   * @returns {any}
   */
  protected findRightNearTick(position) {
    const ticks = this.scale.ticks(this._config.tickFunction.every(this._config.tickInterval));

    let result = null;
    ticks.reverse().forEach(tick => {
      const pos = this.scale(tick);

      if (pos >= position) {
        result = tick;
      }
    });
    return result;
  }

  /**
   * Looking left-hand-side near position (for ticks)
   * @param position
   * @returns {any}
   */
  protected findLeftNearPosition(position) {
    let result = null;
    this.ticksPositionsList.forEach(pos => {
      if (pos <= position) {
        result = pos;
      }
    });

    return result;
  }

  /**
   * Looking right-hand-side near position (for ticks)
   * @param position
   * @returns {any}
   */
  protected findRightNearPosition(position) {
    let result = null;
    this.ticksPositionsList.every(pos => {
      result = pos;
      return pos < position;
    });

    return result;
  }

  /**
   * Calculation for left/right tag position
   * @param position
   * @returns {number}
   */
  protected calcTagPosition(position) {
    switch (position) {
      case 'left': {
        return -1 + this.tagsPositions.left;
      }
      case 'right': {
        return -(this.tagsPositions.right - this._sizes.shiftMargin);
      }
    }
  }

  /**
   * Change arrow modes
   * @param mode
   */
  protected changeArrowMode(mode) {
    switch (mode) {
      case 'drag':
        {
          this.$leftCircle.attr('href', this._defs.dragArrow());
          this.$rightCircle.attr('href', this._defs.dragArrow());
        }
        break;

      case 'still': {
        this.$leftCircle.attr('href', this._defs.semisphere());
        this.$rightCircle.attr('href', this._defs.semisphere());
      }
    }
  }

  /**
   * Overlapping with some shift
   */
  protected overlapRight() {
    const to = this.scale.invert(this.tagsPositions.right + this._interval.x()).getTime();

    return this.relatedShifts.right.active.reduce((acc, shift) => {
      const start = shift.dateFrom().getTime();

      if (to >= start) {
        acc.push(shift);
      }

      return acc;
    }, []);
  }

  /**
   * Overlapping with some shift
   */
  protected overlapLeft() {
    const from = this.scale.invert(this.tagsPositions.left + this._interval.x()).getTime();

    return this.relatedShifts.left.active.reduce((acc, shift) => {
      const end = shift.ownShift.dateTo().getTime();

      if (from <= end) {
        acc.push(shift);
      }

      return acc;
    }, []);
  }

  protected shiftsResult() {
    const target = this.targetResult();
    const [leftChanged, leftDeleted] = this.leftResults();
    const [rightChanged, rightDeleted] = this.rightResults();

    return {
      deletedLeft: leftDeleted,
      deletedRight: rightDeleted,
      overlapedLeft: leftChanged,
      overlapedRight: rightChanged,
      target
    };
  }

  protected targetResult() {
    const gridConfig = this._grid.config();
    const dateFrom = this.scale.invert(this.positions.baseX1);
    const dateTo = this.scale.invert(this.positions.baseX2);

    return {
      target: this._shift,
      leftPos: this.positions.baseX1,
      rightPos: this.positions.baseX2,
      dateFrom: gridConfig.interval.precision(dateFrom),
      dateTo: gridConfig.interval.precision(dateTo)
    };
  }

  protected leftResults() {
    const gridConfig = this._grid.config();
    const leftRealted = this.relatedShifts.left;

    const deteledLeft = leftRealted.active.filter(shift => shift.isHidden).map(shift => shift.ownShift);

    const leftChanged = leftRealted.active
      .filter(shift => shift.isChanged && !shift.isHidden)
      .map(shift => {
        const dateFrom = this.scale.invert(shift.leftPosition);
        const dateTo = this.scale.invert(shift.rightPosition);

        return {
          target: shift.ownShift,
          leftPos: shift.leftPosition,
          rightPos: shift.rightPosition,
          dateFrom: gridConfig.interval.precision(dateFrom),
          dateTo: gridConfig.interval.precision(dateTo)
        };
      });

    if (leftRealted.related && leftRealted.related.isHidden) {
      deteledLeft.push(leftRealted.related.ownShift);
    } else if (leftRealted.related && leftRealted.related.isChanged) {
      const dateFrom = this.scale.invert(leftRealted.related.leftPosition);
      const dateTo = this.scale.invert(leftRealted.related.rightPosition);

      leftChanged.push({
        target: leftRealted.related.ownShift,
        leftPos: leftRealted.related.leftPosition,
        rightPos: leftRealted.related.rightPosition,
        dateFrom: gridConfig.interval.precision(dateFrom),
        dateTo: gridConfig.interval.precision(dateTo)
      });
    }

    return [leftChanged, deteledLeft];
  }

  protected rightResults() {
    const gridConfig = this._grid.config();
    const rightRelated = this.relatedShifts.right;

    const rightDeleted = rightRelated.active.filter(shift => shift.isHidden).map(shift => shift.ownShift);

    const rightChanged = rightRelated.active
      .filter(shift => shift.isChanged && !shift.isHidden)
      .map(shift => {
        const dateFrom = this.scale.invert(shift.leftPosition);
        const dateTo = this.scale.invert(shift.rightPosition);

        return {
          target: shift.ownShift,
          leftPos: shift.leftPosition,
          rightPos: shift.rightPosition,
          dateFrom: gridConfig.interval.precision(dateFrom),
          dateTo: gridConfig.interval.precision(dateTo)
        };
      });

    if (rightRelated.related && rightRelated.related.isHidden) {
      rightDeleted.push(rightRelated.related.ownShift);
    } else if (rightRelated.related && rightRelated.related.isChanged) {
      const dateFrom = this.scale.invert(rightRelated.related.leftPosition);
      const dateTo = this.scale.invert(rightRelated.related.rightPosition);

      rightChanged.push({
        target: rightRelated.related.ownShift,
        leftPos: rightRelated.related.leftPosition,
        rightPos: rightRelated.related.rightPosition,
        dateFrom: gridConfig.interval.precision(dateFrom),
        dateTo: gridConfig.interval.precision(dateTo)
      });
    }

    return [rightChanged, rightDeleted];
  }
}

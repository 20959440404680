import * as moment from 'moment-mini';
import { VacayRequestStatisticActivity } from './vacay-request-statistic-activity';


// so far used in loadStatistics and subscriptionUpdateStatistic
export class VacayRequestStatisticModel {

  public date: moment.Moment;
  public warningStatus: number;
  public activitiesMap: Map<number, VacayRequestStatisticActivity>;

  // VacayStatisticResponseData - so far seems to be conflicting between usage in loadStatistics and subscriptionUpdateStatistic
  constructor(data: any = {}) {
    // Extract format string to constant or code to function in date.ts - HZN-2637
    this.date = (data.date && moment.utc(data.date, 'YYYY-MM-DDTHH:mm:ssZ')) || void 0;
    this.warningStatus = data.warningStatus || void 0;

    this.activitiesMap = new Map(data.coverage.map((coverageValue: number, shiftAliasId: number) => {
      const activity = new VacayRequestStatisticActivity({
        date: this.date,
        warningStatus: this.warningStatus,
        coverage: coverageValue
      });

      return ([shiftAliasId, activity]);
    }));
  }

  /**
   *
   * Activities
   * @desc set each activity which have values of
   *  - Day off
   *  - Morning
   *  - Day
   *  - Night
   *  - Evening
   *
   */
  set activities(activities) {
    this.activitiesMap = new Map();

    if (!activities) { return; }

    Object.keys(activities).forEach((key) => {
      this.activitiesMap.set(
        Number(key), new VacayRequestStatisticActivity(activities[key])
      );
    });
  }

  get activities() {
    return this.activitiesMap;
  }

}



import { AvaiJobStatus, EmployeeModel } from '@models';

export interface IAvaiJobResponse {
  id: string;
  isActive: boolean;
  module: string;
  status: AvaiJobStatus;
  targetId: number;
  targetType: string;
  type: string;
  createdAt: string;
  createdBy: EmployeeModel;
  updatedAt: string;
  data: {
    latestVersion: number;
    obtainedTime: string;
    solutionId: string;
  };
}

export class AvaiJobResponseModel {
  public id: string;
  public isActive: boolean;
  public module: string;
  public status: AvaiJobStatus;
  public targetId: number;
  public targetType: string;
  public type: string;
  public createdAt: string;
  public createdBy: EmployeeModel;
  public updatedAt: string;

  constructor(data: Partial<IAvaiJobResponse> = {}) {
    this.id = data.id || null;
    this.isActive = data.isActive;
    this.module = data.module || null;
    this.status = data.status || null;
    this.targetId = data.targetId || null;
    this.targetType = data.targetType || null;
    this.type = data.type || null;
    this.createdAt = data.createdAt || null;
    this.createdBy = new EmployeeModel(data.createdBy) || undefined;
    this.updatedAt = data.updatedAt || null;
  }
}

import { Subject } from 'rxjs';
import { ShiftAliasModel } from '@shared/models';
import { Day } from '../calendar';

export class HealthGroup {
  protected _next: HealthGroup;
  protected _prev: HealthGroup;

  protected _relatedDays: Day[] = [];
  protected _mapAliasRelatedDays: Map<string, ShiftAliasModel>;

  protected _onDestroy = new Subject();

  constructor(protected _healthPoint: Day) {
    this.storage.addHealthGroup(this);

    this.lookupPrev();
    this.lookupNext();
  }

  set next(healthGroup: HealthGroup) {
    this._next = healthGroup;

    this.lookupDays();
    this.update();
  }

  get next() {
    return this._next;
  }

  set prev(healthGroup: HealthGroup) {
    this._prev = healthGroup;
  }

  get prev() {
    return this._prev;
  }

  get index() {
    return this.storage.healthGroups.indexOf(this);
  }

  set relatedDays(days: Day[]) {
    this._relatedDays = days;

    this.relatedDays.forEach(day => (day.health.healthGroup = this));
  }

  get relatedDays() {
    return this._relatedDays;
  }

  get healthPoint() {
    return this._healthPoint;
  }

  get dateFrom() {
    return this._healthPoint.date;
  }

  get storage() {
    return this._healthPoint.storage;
  }

  /**
   * Update health group
   */
  public update() {
    this.genAliasRelatedDays();
  }

  /**
   * Destroy health group
   */
  public destroy() {
    this._healthPoint.updateHealthStatus();
    this._relatedDays.forEach(day => {
      day.health.healthGroup = void 0;
      day.updateHealthStatus();
    });

    if (this.prev) {
      this.prev.next = this.next;
    }

    if (this.next) {
      this.next.prev = this.prev;
    }

    this.storage.removeHealthGroup(this);

    this._onDestroy.next();
  }

  /**
   * Generate map of aliases of related days
   */
  protected genAliasRelatedDays() {
    this._mapAliasRelatedDays = new Map();

    this._relatedDays.forEach(day => {
      const alias = day.activity && day.activity.model.shiftAlias;

      this._mapAliasRelatedDays.set(day.keyDate, alias);
    });
  }

  /**
   * Lookup prev health group
   */
  protected lookupPrev() {
    this.prev = this.storage.healthGroups[this.index - 1];

    if (this.prev) {
      this.prev.next = this;
    }
  }

  /**
   * Lookup next health group
   */
  protected lookupNext() {
    this.next = this.storage.healthGroups[this.index + 1];

    if (this.next) {
      this.next.prev = this;
    }
  }

  /**
   * Lookup related days
   */
  protected lookupDays() {
    this.relatedDays.forEach(day => {
      day.health.healthGroup = void 0;
      day.updateHealthStatus();
    });

    this.relatedDays = [];

    let index = this.healthPoint.indexByStorage;
    const nextIndex = this.next && this.next.healthPoint.indexByStorage;

    if (nextIndex > 0) {
      this.relatedDays = this.storage.days.slice(++index, nextIndex);
    }
  }
}

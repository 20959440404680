import { SettingValue } from '@models';

export interface AveragingPeriodsForGroups {
  [groupId: number]: SettingValue[];
}

export interface EmployeeAveragingPeriodOffsetResult {
  offset: number;
  averagingPeriod: {
    id: number;
  };
}

export interface SingleAveragingPeriodStatistic {
  count: number;
  averagePeriod: number;
  duration: number;
  offset?: number;
}
export interface EmployeeAveragingPeriodStatistics {
  [employeeId: number]: SingleAveragingPeriodStatistic[];
}

import * as moment from 'moment-mini';

import { UTC_OFFSET_FORMAT } from '@helpers';
import { VacayRequestStatisticActivityData } from './vacay-request-statistic-activity-data.model';

export class VacayRequestStatisticActivity {
  public date: moment.Moment;
  public coverage: number;
  public warningStatus: number;

  constructor(data: VacayRequestStatisticActivityData = new VacayRequestStatisticActivityData()) {
    this.date = (data.date && moment.utc(data.date, UTC_OFFSET_FORMAT)) || data.date;
    this.coverage = data.coverage;
    this.warningStatus = data.warningStatus;
  }
}

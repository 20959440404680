import { GroupModel } from '../companies';
import { ShiftAliasModel } from '../activities/shift-alias';
import { RostrRequestBaseModel } from '../rostr/rostr-request-base.model';


export class RequiredShiftModel extends RostrRequestBaseModel {

  public group: GroupModel;
  public alias: ShiftAliasModel;

  constructor(data: any = {}) {
    super(data);

    this.group = (data.group && new GroupModel(data.group)) || undefined;
    this.alias = (data.shiftAlias && new ShiftAliasModel(data.shiftAlias)) || undefined;
  }

  get start() {
    const dateStart = this.dateFrom.clone().startOf('day');
    return this.dateFrom.diff(dateStart, 'minutes');
  }

  get duration() {
    return this.dateTo.diff(this.dateFrom, 'minutes');
  }

}

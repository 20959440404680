export class OverviewColumn {
  name: string;
  isHours: boolean;
  week: number;
  index: number;
  isWeekend: boolean;
  duration: number;
  timestamp: string;
  content: string;
  holidayName: string;
  isIncluded: boolean;
  year?: number;
}

import * as moment from 'moment-mini';
import { CommentModel } from '@model/comment/comment.model';
import { getISODateOnlyString } from '@helpers';
import { CommentTypes } from '@enums';
import {
  AgendaActivityModel,
  AgendaBaselineActivityModel,
  MasterplanAssignedActivityModel,
  ScheduleAssignedActivityModel
} from '../schedules';
import { VacayRequestModel } from '../vacay';
import { EmployeeModel } from '../auth';
import { VacayStatistics } from '../rostr/rostr-statistic.model';
import { ITimelineEmployeeModelParams } from '../interfaces';
import { TimelineGroupModel } from './group.model';
export class TimelineEmployeeModel extends EmployeeModel {
  public scheduleActivities: ScheduleAssignedActivityModel[];
  public masterplanActivities: MasterplanAssignedActivityModel[];
  public agendaActivities: AgendaActivityModel[];
  public agendaBaselineActivities: AgendaBaselineActivityModel[];
  public vacayRequests: VacayRequestModel[];
  public comments: CommentModel[];
  public weekendPattern: moment.Moment[];
  public timelineGroup: TimelineGroupModel;

  public statistics: {
    bySchedule: VacayStatistics;
    byWeeks: { [weekNumber: number]: VacayStatistics };
  };

  constructor(data: ITimelineEmployeeModelParams) {
    super(data);

    this.scheduleActivities =
      (data.assignedActivities && data.assignedActivities.map(el => new ScheduleAssignedActivityModel(el))) || [];
    this.masterplanActivities = (data.masterShifts && data.masterShifts.map(el => new MasterplanAssignedActivityModel(el))) || [];
    this.agendaActivities = (data.agendaRequests && data.agendaRequests.map(el => new AgendaActivityModel(el))) || [];
    this.agendaBaselineActivities =
      (data.agendaBaselineRequests && data.agendaBaselineRequests.map(el => new AgendaBaselineActivityModel(el))) || [];
    this.vacayRequests = (data.vacayRequests && data.vacayRequests.map(el => new VacayRequestModel(el))) || [];
    this.comments = (data.comments && data.comments.map(comment => new CommentModel({
      id: comment.id,
      correspondingEmployeeId: comment.correspondingEmployee.id,
      typeId: comment.type.id,
      message: comment.message,
      color: comment.color,
      correspondingDay: comment.correspondingDay,
      createdAt: comment.createdAt,
      createdBy: comment.createdBy
    }))) || [];
    this.weekendPattern = (data.weekendPattern && data.weekendPattern.map(el => moment.utc(el, 'YYYY-MM-DDTHH:mm:ssZ'))) || [];
    this.statistics = { bySchedule: new VacayStatistics(), byWeeks: {} };
  }

  public getCommentsByDayAndType(day: string, commentType: CommentTypes) {
    return this.comments.filter(
      c => c.correspondingDay === getISODateOnlyString(day) && c.typeId === commentType
    );
  }
}

export class AgendaRequestDetailsResult {
  createdBy: {
    firstName: string;
    lastName: string;
  };
  description: string;
  shiftCode: {
    code: string;
    start: number;
    duration: number;
    workingTimeInMinutes: number;
    isGlobal: boolean;
    skills: { id: number; name: string }[];
    licenses: { id: number; name: string }[];
    unitEndorsements: { id: number; title: string }[];
  };
  objective: {
    project: {
      title: string;
    };
  };
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAvaiIssueData } from '../interfaces/avai-issue-report.interface';
import { ConfigService } from '@core';

@Injectable({
  providedIn: 'root'
})
export class AvaiIssueReportService {
  private readonly url: string;

  constructor(private readonly http: HttpClient, private readonly configService: ConfigService) {
    this.url = configService.config.rootApiDomain;
  }

  reportAvaiIssue(scheduleId: number, requestBody: IAvaiIssueData) {
    const endpoint = `${this.url}/api/rostr/${scheduleId}/reportIssue`;
    return this.http.post<IAvaiIssueData>(endpoint, requestBody);
  }
}

import { Day } from '../calendar';
import { HealthGroup } from './health-group';
import { takeUntil } from 'rxjs/operators';

export class DayHealth {
  public healthGroup: HealthGroup;

  constructor(protected _day: Day) {
    this.subscribes();
  }

  /** Check if selected day is day from which health group can be created.
   *  isFirstDayOfSchedule is HACK. When schedule is starting without day off/vacation color indicators weren't working.
   */
  get isHealthPoint() {
    const isFirstDayOfSchedule = this._day.prev === null || this._day.prev === undefined;
    return this._day.isDayOff || this._day.isVacation || isFirstDayOfSchedule;
  }

  get storage() {
    return this._day.storage;
  }

  /**
   * Check health point
   */
  public check() {
    if (this.healthGroup && !this.isHealthPoint && this.healthGroup.healthPoint === this._day) {
      this.healthGroup.destroy();
      return;
    }

    if (this.healthGroup && this.isHealthPoint && this.healthGroup.healthPoint !== this._day) {
      this.healthGroup = new HealthGroup(this._day);
      return;
    }

    if (this.healthGroup) {
      this.healthGroup.update();
      return;
    }

    if (!this.isHealthPoint) {
      return;
    }

    this.healthGroup = new HealthGroup(this._day);
  }

  /**
   * Subscribes
   */
  protected subscribes() {
    this._day.timeline.onInitAdditionalData.pipe(takeUntil(this._day.timeline.onDestroy)).subscribe(() => this.check());
  }
}

import * as d3 from 'd3';

import { BaseShift } from './base.shift';
import { Timeline } from '../timeline';
import { Grid } from '../grid/grid';
import { IBrushConfig } from '../brush/brush';

import { pathForRoundedRect, translate } from '@helpers';

export class RelatedResizableShift extends BaseShift {

  get ownShift() {
    return this._shift;
  }

  get leftPosition() {
    return this.positions.baseX1;
  }

  get rightPosition() {
    return this.positions.baseX2;
  }

  public isHidden = false;
  public isChanged = false;

  protected scale = d3.scaleTime();
  protected ticksPositionsList: number[] = [];
  protected leftLimit;
  protected rightLimit;

  protected tagsPositions = {
    left: 0,
    right: 0
  };

  protected positions = {
    baseX1: 0,
    baseX2: 0,
    y: 0
  };

  protected _sizes = {
    width: 0,
    shiftMargin: 0,
    semiWidth: 11,
    shiftHeight: 0
  };

  protected _config: IBrushConfig = {
    tickFunction: d3.timeMinute,
    tickInterval: 15
  };

  constructor(protected _shift: BaseShift,
    protected _type,
    protected _withoutRestrictions = false,
    config: IBrushConfig = {}) {
    super(_shift.title, _shift.dateFrom(), _shift.dateTo());

    this._timeline = _shift.timeline() as Timeline;
    this._grid = _shift.grid() as Grid;
    this._item = _shift.item() as any;
    this._interval = _shift.interval() as any;
    this._defs = _shift.defs() as any;

    this.leftLimit = (this._shift.interval() as any).x();
    const endInterval = (this._shift.endInterval() as any);
    this.rightLimit = (endInterval) ? endInterval.x() : null;
    this.updateConfig(config);

    this.render();
  }

  public render() {
    this.destroy();

    this.scaleInit();

    const sizes = this._timeline.sizes();
    const rh = this._item.config.height;
    const shiftMargin = sizes.shiftMargin;
    const width = Math.round(this.width() - shiftMargin * 2); // fixme any ideas ?

    this._sizes.shiftHeight = rh * 0.825;

    this.InitAndUpdateNodes();

    this.positions.y = this._item.shiftPosition(this);
    this._sizes.shiftMargin = shiftMargin;

    this._sizes.width = width;

    this.positions.baseX1 = this._interval.x();
    const endInterval = this.endInterval();

    if (endInterval) {
      this.positions.baseX2 = endInterval.x();
    }

    this.tagsPositions.left = shiftMargin;
    this.tagsPositions.right = this.findRightNearPosition(width);

    this.$el.attr('transform', translate(this._interval.x(), this.positions.y));

    const shifts = this._timeline.underMenuSysContainer.selectAll('.resizable-shift');
    this._timeline.underMenuSysContainer.node().insertBefore(this.el, shifts.node() as SVGGElement);
    return this.el;
  }

  /**
   * Initialize scale line and ticks position list
   */
  public scaleInit() {
    const sizes = this._timeline.sizes();

    const intervals = this._grid.intervals();
    const firstInterval = intervals[0];
    const lastInterval = intervals[intervals.length - 1];

    this.scale.domain([firstInterval.dateFrom(), lastInterval.dateTo()])
      .range([firstInterval.x(), lastInterval.x() + sizes.intervalWidth]);

    const ticks = this.scale.ticks(this._config.tickFunction.every(this._config.tickInterval));
    ticks.forEach((tick) => {
      this.ticksPositionsList.push(Math.round(this.scale(tick)));
    });
  }

  public events() {
  }

  public updateLength(pos) {
    (this._type === 'left') ? this.updateLeftBorder(pos) : this.updateRightBorder(pos);
  }

  public currentShiftWidth() {
    return this.tagsPositions.right - this.tagsPositions.left - this._sizes.shiftMargin;
  }

  public show() {
    this.$el.attr('visibility', 'visible')
      .attr('transform', translate(this._interval.x(), this._item.shiftPosition(this)));
    return this;
  }

  /**
   * Destroy shift
   */
  public destroy() {
    this.ticksPositionsList.length = 0;

    this.$el.selectAll('*').remove();
    this.$el.node().remove();
  }

  /**
   * Init nodes from cloned element
   * @constructor
   */
  protected InitAndUpdateNodes() {
    this.$el = d3.select<any, any>(this._shift.clone());
    this.el = this.$el.node();

    this.$shadow = this.$el.select('.shadow');
    this.$background = this.$el.select('.background');

    this.$title = this.$el.select('text');

    const decorator = this.$el.select('.decorator');
    if (decorator) {
      this.$decorator = decorator;
    }
  }

  /**
   * Merge config
   * @param config
   */
  protected updateConfig(config: IBrushConfig) {
    Object.assign(this._config, config);
  }

  protected updateLeftBorder(pos) {
    const xPos = this.findLeftNearPosition(pos);
    this.tagsPositions.right = xPos - this._interval.x();

    if (!this._withoutRestrictions &&
      this.rightLimit &&
      this.tagsPositions.right + this._interval.x() > this.rightLimit) {
      this.tagsPositions.right = this.findLeftNearPosition(this.rightLimit) - this._interval.x();
    }

    this.isChanged = this.positions.baseX2 !== this.tagsPositions.right + this._interval.x();
    this.positions.baseX2 = xPos;

    const width = this.currentShiftWidth();

    if (!this.isHidden && width < 10) {
      this.isHidden = true;
      this.hide();
      return;
    }

    if (this.isHidden && width > 10) {
      this.isHidden = false;
      this.show();
    } else if (this.isHidden) {
      return;
    }

    this.$shadow
      .attr('width', width);
    this.$background
      .attr('width', width);

    if (this.$decorator) {
      this.$decorator
        .attr('d', pathForRoundedRect(0, 0, width, 4, 2.5, false, false, true, true));
    }

    this.$title.attr('x', width / 2 + this.tagsPositions.left);
  }

  protected updateRightBorder(pos) {
    const xPos = this.findLeftNearPosition(pos);
    this.tagsPositions.left = xPos - this._interval.x() + this._sizes.shiftMargin;

    if (!this._withoutRestrictions &&
      this.leftLimit &&
      this.tagsPositions.left + this._interval.x() < this.leftLimit) {
      this.tagsPositions.left = this.findLeftNearPosition(this.leftLimit) - this._interval.x();
    }

    this.isChanged = this.positions.baseX1 !== this.tagsPositions.left + this._interval.x();
    this.positions.baseX1 = xPos;

    const width = this.currentShiftWidth();

    if (!this.isHidden && width < 10) {
      this.isHidden = true;
      this.hide();
      return;
    }

    if (this.isHidden && width > 10) {
      this.isHidden = false;
      this.show();
    } else if (this.isHidden) {
      return;
    }

    this.$shadow
      .attr('x', this.tagsPositions.left)
      .attr('width', width - 1);
    this.$background
      .attr('x', this.tagsPositions.left)
      .attr('width', width);

    if (this.$decorator) {
      this.$decorator
        .attr('d', pathForRoundedRect(0, 0, width, 4, 2.5, false, false, true, true))
        .attr('transform', translate(this.tagsPositions.left, this._sizes.shiftHeight));
    }

    this.$title.attr('x', width / 2 + this.tagsPositions.left);
  }

  /**
   * Looking left-hand-side near position (for ticks)
   * @param position
   * @returns {any}
   */
  protected findLeftNearPosition(position) {
    let result = null;
    this.ticksPositionsList.forEach((pos) => {
      if (pos <= position) {
        result = pos;
      }
    });

    return result;
  }

  /**
   * Looking right-hand-side near position (for ticks)
   * @param position
   * @returns {any}
   */
  protected findRightNearPosition(position) {
    let result = null;
    this.ticksPositionsList.every((pos) => {
      result = pos;
      return pos < position;
    });

    return result;
  }
}


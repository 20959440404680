import { Component, Input } from '@angular/core';

const MORE_THAN_99 = '99+';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
  @Input()
  set value(newValue: number) {
    this.update(newValue);
  }
  numberTruncated: string;
  fontSizeClass: string;

  update(newValue: number) {
    if (newValue < 0) {
      throw new Error('Input value should be positive');
    }

    const valueRounded = Math.round(newValue);

    switch (valueRounded.toString().length) {
      case 1:
        this.fontSizeClass = 'text-large';
        this.numberTruncated = valueRounded.toString();
        break;
      case 2:
        this.fontSizeClass = 'text-medium';
        this.numberTruncated = valueRounded.toString();
        break;
      default:
        this.fontSizeClass = 'text-small';
        this.numberTruncated = valueRounded > 99 ? MORE_THAN_99 : valueRounded.toString();
    }
  }
}

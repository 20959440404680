import { ShiftCodeTypeEnum } from '../../enums/shift-code-type.enum';
import { UserBasic } from './user-basic';

export class AssignActivityResult {
  avaiKey: string;
  code: string;
  dateFrom: string;
  dateTo: string;
  id: number;
  parentId: number;
  parentType: string;
  shiftAlias: { id?: number; value: string };
  isLocked: boolean;
  employeeId?: number;
  date?: string;
  updatedBy?: UserBasic;
  data: { isLocked: boolean };
  type: ShiftCodeTypeEnum;
  shiftCodeId: number;
  shiftCode?: { id: number; code: string; type: ShiftCodeTypeEnum };
}

import { Component, ElementRef, HostBinding, HostListener } from '@angular/core';
import { TooltipService } from '../tooltip/tooltip.service';
import { WheelMenuItemModel, IWheelMenuShowInterface } from './wheel-menu-item.model';


@Component({
  selector: 'pl-wheel-menu',
  templateUrl: './wheel-menu.component.html',
  styleUrls: ['./wheel-menu.component.scss'],
})

export class WheelMenuComponent {

  @HostBinding('style.top.px') public top: number;
  @HostBinding('style.left.px') public left: number;
  @HostBinding('class.visible') public visible = false;

  public shift;
  public circlesData: WheelMenuItemModel[] = [];
  protected callback: (key: string) => void;
  protected demoData: WheelMenuItemModel[] = [
    new WheelMenuItemModel({ classed: 'material-icons', icon: 'flag', key: 'flag' }),
    new WheelMenuItemModel({ classed: 'material-icons', icon: 'build', key: 'build' }),
    new WheelMenuItemModel({ classed: 'material-icons', icon: 'swap_vert', key: 'swap_vert' }),
    new WheelMenuItemModel({ classed: 'material-icons', icon: 'close', key: 'close' }),
    new WheelMenuItemModel({ classed: 'material-icons', icon: 'person_pin', key: 'person_pin' }),
    new WheelMenuItemModel({ classed: 'material-icons', icon: 'lock', key: 'lock' }),
    new WheelMenuItemModel({ classed: 'material-icons', icon: 'comment', key: 'message' })
  ];

  constructor(
    protected element: ElementRef,
    protected tooltipService: TooltipService
  ) { }

  @HostListener('document:keyup.esc', ['$event.target'])
  @HostListener('document:mousedown', ['$event.target'])
  public close(target: EventTarget) {
    if (this.visible && !this.element.nativeElement.contains(target)) {
      this.visible = false;
      this.showActiveClass(false);
    }
  }

  public closeForce() {
    this.visible = false;
    this.showActiveClass(false);
  }

  public show(config: IWheelMenuShowInterface) {
    const {
      data = null,
      toggleResolvers = null,
      disabledResolvers = null,
      position,
      shift,
      callback,
    } = config;

    this.circlesData = (data) ? data : this.demoData;

    if (toggleResolvers) {
      for (const nameFor in toggleResolvers) {
        this.toggleByKey(nameFor, toggleResolvers[nameFor]());
      }
    }

    if (disabledResolvers) {
      for (const nameFor in disabledResolvers) {
        this.disableByKey(nameFor, disabledResolvers[nameFor]());
      }
    }

    this.visible = false;
    this.moveCircles(true);
    this.tooltipService.closeAll();

    const coordinates = WheelMenuComponent.getCoordinates(position);
    this.top = coordinates.top;
    this.left = coordinates.left;
    this.shift = shift;
    this.showActiveClass(true);
    this.callback = callback;
    setTimeout(() => {
      this.visible = true;
      this.moveCircles();
    }, 200);
  }

  public select(item: WheelMenuItemModel) {
    this.closeForce();
    if (!item.disabled && this.callback) {
      this.callback(item.key);
    }
  }

  public setPickerData(data: WheelMenuItemModel[]) {
    this.demoData = data;
  }

  public toggleByKey(key: string, toggleStatus: boolean) {
    const elem = this.circlesData.find(circle => circle.key === key);

    if (elem) {
      elem.toggleStatus = toggleStatus;
      return true;
    } else {
      return false;
    }
  }

  public disableByKey(key: string, disableStatus: boolean) {
    const elem = this.circlesData.find(circle => circle.key === key);

    if (elem) {
      elem.disabled = disableStatus;
      return true;
    } else {
      return false;
    }
  }

  protected showActiveClass(flag) {
    if (this.shift && this.shift.element()) {
      const el = this.shift.element();
      el.classed('active', flag);
    }
  }

  protected moveCircles(isReset?: boolean) {
    this.circlesData.forEach((circle, i) => {
      const coordinates = this.circleCoordinates(i, isReset);
      circle.styleTop = coordinates.top;
      circle.styleLeft = coordinates.left;
    });
  }

  /**
   * Calculate coordinates for circle
   *
   * @param {number} index
   * @param {boolean} isReset
   *
   * @returns {{top: number, left: number}}
   */
  protected circleCoordinates(index: number, isReset?: boolean): { top: number, left: number } {
    let top;
    let left;
    const radius = 73;
    const radians = 2 * Math.PI * (index / this.circlesData.length);
    left = isReset ? 0 : (Math.sin(radians) * radius);
    top = isReset ? 0 : -(Math.cos(radians) * radius);
    return { top, left };
  }

  /**
   * Get coordinates under position of wheel
   *
   * @param {TargetElement | DOMRect} targetElement
   *
   * @returns {{top: number, left: number}}
   */
  static getCoordinates(targetElement: DOMRect): { top: number, left: number } {

    let { top, left } = targetElement;
    const { width, height } = targetElement;

    left += window.scrollX + width / 2;
    top += window.scrollY + height / 2;

    return { top, left };

  }
}

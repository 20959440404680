import * as d3 from 'd3';
import { Subject } from 'rxjs';

import { translate, xmlns } from '@shared/helpers/d3';
import {
  endOfMonth,
  endOfWeek,
  weekDay,
} from '@shared/helpers/date';

import { Schedule } from '../schedules';
import { Day } from './day';
import { Month } from './month';
import { Week } from './week';
import { WeekDay } from './week-day';


export class Calendar {

  public onReady = new Subject();

  protected el: SVGElement;
  protected $el: d3.Selection<SVGElement, any, any, any>;

  protected $background: d3.Selection<SVGRectElement, any, any, any>;
  protected $boundaries: d3.Selection<SVGElement, any, any, any>;
  protected $labels: d3.Selection<SVGElement, any, any, any>;

  protected $months: d3.Selection<SVGElement, any, any, any>;
  protected $weeks: d3.Selection<SVGElement, any, any, any>;
  protected $weekDays: d3.Selection<SVGElement, any, any, any>;
  protected $days: d3.Selection<SVGElement, any, any, any>;

  protected _months: Month[] = [];
  protected _weeks: Week[] = [];
  protected _weekDays: WeekDay[] = [];
  protected _days: Day[] = [];

  constructor(protected _dateFrom: Date,
              protected _dateTo: Date,
              protected _schedule: Schedule) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).attr('class', 'calendar');

    this.$background = this.$el.append<SVGRectElement>('rect').attr('class', 'background');

    this.$days = this.$el.append<SVGElement>('g').attr('class', 'days');
    this.$weeks = this.$el.append<SVGElement>('g').attr('class', 'weeks');
    this.$weekDays = this.$el.append<SVGElement>('g').attr('class', 'week-days');
    this.$months = this.$el.append<SVGElement>('g').attr('class', 'months');

    this.$labels = this.$el.append<SVGElement>('g').attr('class', 'labels');
    this.$boundaries = this.$el.append<SVGElement>('g').attr('class', 'boundaries');

    this.initWeekDays();
    this.lookupChunks();

    this.onReady.next();
    this.onReady.complete();
  }

  get isLocked() {
    return this._schedule.isLocked;
  }

  get months() {
    return this._months;
  }

  get weeks() {
    return this._weeks;
  }

  get weekDays() {
    return this._weekDays;
  }

  get days() {
    return this._days;
  }

  get height() {
    const cell = this.sizes.cell;
    const headerHeight = this.sizes.headerHeight;

    return this.weeks.length * cell + headerHeight;
  }

  get timeline() {
    return this._schedule.timeline;
  }

  get defs() {
    return this._schedule.defs;
  }

  get sizes() {
    return this._schedule.sizes;
  }

  get storage() {
    return this._schedule.storage;
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.el;
  }

  /**
   * Render calendar
   */
  public render() {
    this.$el.attr('transform', translate(0, this.sizes.tabHeight));

    this.renderBackground();

    this.renderDays();
    this.renderWeeks();
    this.renderMonths();

    this.renderLabels();
    this.renderWeekDays();
    this.renderBoundaries();
  }

  /**
   * Render background
   */
  protected renderBackground() {
    const width = this.sizes.scheduleWidth;
    const height = this.height;

    this.$background
      .attr('width', width)
      .attr('height', height);
  }

  /**
   * Render boundaries
   */
  protected renderBoundaries() {
    this.$boundaries.selectAll('*').remove();

    const width = this.sizes.scheduleWidth;
    const height = this.height;
    const headerHeight = this.sizes.headerHeight;

    this.$boundaries
      .append('line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 0)
      .attr('y2', height);

    this.$boundaries
      .append('line')
      .attr('x1', width)
      .attr('x2', width)
      .attr('y1', 0)
      .attr('y2', height);

    this.$boundaries
      .append('line')
      .classed('gray', true)
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', height)
      .attr('y2', height);

    this.$boundaries
      .append('line')
      .classed('dashed', true)
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', height)
      .attr('y2', height);

    this.$boundaries
      .append('line')
      .classed('gray', true)
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', headerHeight)
      .attr('y2', headerHeight);

    this.$boundaries
      .append('line')
      .classed('dashed', true)
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', headerHeight)
      .attr('y2', headerHeight);
  }

  /**
   * Render label
   */
  protected renderLabels() {
    this.$labels.selectAll('*').remove();

    const cell = this.sizes.cell;
    const headerHeight = this.sizes.headerHeight;

    const labels = this.$labels
      .selectAll('g')
      .data(['m', 'wk'])
      .enter()
      .append('g')
      .attr('class', 'label')
      .attr('transform', (d, i) => translate(i * cell, 0));

    labels
      .append('line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 4)
      .attr('y2', headerHeight);

    labels
      .append('text')
      .text(d => d)
      .attr('x', cell / 2)
      .attr('y', headerHeight / 2)
      .attr('dy', '0.5em');
  }

  /**
   * Render week days
   */
  protected renderWeekDays() {
    const cell = this.sizes.cell;

    this.$weekDays.attr('transform', translate(cell * 2, 0));

    this.weekDays.forEach((workDay) => {
      this.$weekDays.node().appendChild(workDay.node);
      workDay.render();
    });
  }

  /**
   * Render months
   */
  protected renderMonths() {
    const headerHeight = this.sizes.headerHeight;

    this.$months.attr('transform', translate(0, headerHeight));

    this.months.forEach((month) => {
      this.$months.node().appendChild(month.node);
      month.render();
    });
  }

  /**
   * Render weeks
   */
  protected renderWeeks() {
    const cell = this.sizes.cell;
    const headerHeight = this.sizes.headerHeight;

    this.$weeks.attr('transform', translate(cell, headerHeight));

    this.weeks.forEach((week) => {
      this.$weeks.node().appendChild(week.node);
      week.render();
    });
  }

  /**
   * Render days
   */
  protected renderDays() {
    const cell = this.sizes.cell;
    const headerHeight = this.sizes.headerHeight;

    this.$days.attr('transform', translate(cell * 2, headerHeight));

    this.days.forEach((day) => {
      this.$days.node().appendChild(day.node);
      day.render();
    });
  }

  /**
   * Initial week days
   */
  protected initWeekDays() {
    this._weekDays = ['M', 'T', 'W', 'T', 'F', 'S', 'S']
      .map((label, index) => new WeekDay(index, label, this));
  }

  /**
   * Lookup days, weeks and months
   */
  protected lookupChunks() {
    const startDate = new Date(this._dateFrom.getTime());

    while (startDate.getTime() <= this._dateTo.getTime()) {
      const dayDate = new Date(startDate.getTime());
      const day = new Day(dayDate, this);

      this._days.push(day);
      this.storage.addDay(day);

      // Find related week day
      const weekDayIndex = weekDay(dayDate);
      const weekDayClass = this.weekDays.find(weekDayEl => weekDayEl.index === weekDayIndex);

      day.weekDay = weekDayClass;
      weekDayClass.addRelatedDay(day);

      // Find related month
      let month = this._months.find((monthEl) => {
        return monthEl.dateFrom.getTime() <= dayDate.getTime()
          && monthEl.dateTo.getTime() >= dayDate.getTime();
      });

      // If month not found then create it
      if (!month) {
        const monthDateFrom = new Date(startDate.getTime());
        let monthDateTo = endOfMonth(monthDateFrom);

        if (monthDateTo > this._dateTo) {
          monthDateTo = new Date(this._dateTo.getTime());
        }

        month = new Month(monthDateFrom, monthDateTo, this);
        this._months.push(month);
      }

      // Find related week
      let week = this._weeks.find((weekEl) => {
        return weekEl.dateFrom.getTime() <= dayDate.getTime()
          && weekEl.dateTo.getTime() >= dayDate.getTime();
      });

      // If week not found then create it
      if (!week) {
        const weekDateFrom = new Date(startDate.getTime());
        const weekDateTo = endOfWeek(weekDateFrom);

        week = new Week(weekDateFrom, weekDateTo, this);

        // Added relations between week and month
        week.month = month;
        month.addRelatedWeek(week);

        this._weeks.push(week);
      }

      // Add relations with day
      day.week = week;
      day.month = month;
      week.addRelatedDay(day);
      month.addRelatedDay(day);

      startDate.setDate(startDate.getDate() + 1);
    }
  }

}

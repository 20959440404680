import { CUSTOM_PERIOD_MODE, STATS_CATEGORY, STATS_MODE } from '..';
import { EmployeeStatistic } from '../models/stats/statistic.class';

export interface IFetchStatisticsParameters {
  categories: STATS_CATEGORY[];
  dateFrom?: string;
  dateTo?: string;
  affectedEmployeesIds?: number[];
}

export interface IRostrStatisticsQueryVariables {
  schedule: number;
  categories: STATS_CATEGORY[];
  averageAssignedDateFrom?: string;
  averageAssignedDateTo?: string;
  employeesIds?: number[];
}

export interface IRawCategory {
  byAveragePeriod?: IRawStatistics;
  bySchedule?: IRawStatistics;
  byWeek?: IRawStatistics;
}

export interface IRawCategories {
  [STATS_CATEGORY.AssignedDuration]?: IRawCategory;
  [STATS_CATEGORY.Deviation]?: IRawCategory;
  [STATS_CATEGORY.VacayRequests]?: IRawCategory;
  [STATS_CATEGORY.VacayRequestsIgnoreAgenda]?: IRawCategory;
  [STATS_CATEGORY.CustomPeriod]?: IRawCustomPeriod;
  [STATS_CATEGORY.Target]?: IRawCategory;
}

export interface IRawStatistics {
  [employeeId: number]: IStatistic[];
}

export interface IRawCustomPeriod {
  [employeeId: number]: [ICustomPeriod];
}

export interface IStatistic {
  count: number;
  duration: number;
  isoWeek: number;
  year: number;
  schedule?: number;
  averagePeriod?: string;
  satisfiedCount?: number;
  offset?: number;
}

export interface IWeeklyStatFormatted {
  [dateKey: string]: string;
}

export interface IWeeklyStatistics {
  [employeeId: number]: IWeeklyStatFormatted;
}

export interface IMainStatistics {
  [employeeId: number]: string;
}

export interface ICustomPeriodStatistics {
  [employeeId: number]: ICustomPeriod;
}

export interface IStatisticCategory {
  [STATS_MODE.Weekly]: IWeeklyStatistics;
  [STATS_MODE.AveragePeriod]: IMainStatistics;
  [STATS_MODE.Schedule]: IMainStatistics;
}

export interface ICustomPeriod {
  daysOff: number;
  daysWorked: number;
  deviationFromAveragingTarget: number;
  holidaysCount: number;
  nightShiftsCount: number;
  perDay: number;
  perWeek: number;
  totalMinutes: number;
  weekendShiftsCount: number;
  weekendsWorkedCount: number;
}

export interface IAllStatistics {
  [STATS_CATEGORY.AssignedDuration]?: IStatisticCategory;
  [STATS_CATEGORY.Deviation]?: IStatisticCategory;
  [STATS_CATEGORY.VacayRequests]?: IStatisticCategory;
  [STATS_CATEGORY.VacayRequestsIgnoreAgenda]?: IStatisticCategory;
  [STATS_CATEGORY.CustomPeriod]?: ICustomPeriod;
}

export interface IFetchStatisticsResponse {
  rostrStatsForSchedule: IRawCategories;
}

export interface IFormattingFunction {
  (stat: Partial<IStatistic> | ICustomPeriod): string;
}

export interface IStatisticMode {
  getStatsForEmployee(employeeId: number): EmployeeStatistic;
  setData(stats: { [employeeId: number]: [ICustomPeriod] | IStatistic[] }, ids?: number[]): void;
}

export interface ICategoryClass {
  loaded: boolean;
  getStatsForMode(mode: STATS_MODE | CUSTOM_PERIOD_MODE): IStatisticMode;
  setData(data: IRawCategory | IRawCustomPeriod, employeeIds?: number[]): void;
}

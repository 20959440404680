import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ScheduleState } from '@model/schedules/schedule-state.model';

@Component({
  selector: 'app-schedule-state-icon',
  templateUrl: './schedule-state-icon.component.html',
  styleUrls: ['./schedule-state-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleStateIconComponent {
  private _scheduleState: ScheduleState;
  private _activeState: ScheduleState;

  @Input()
  set scheduleState(value: ScheduleState) {
    this._scheduleState = value;
    this.recalculateLabels();
  }
  get scheduleState(): ScheduleState {
    return this._scheduleState;
  }
  @Input()
  set activeState(value: ScheduleState) {
    this._activeState = value;
    this.recalculateLabels();
  }
  get activeState(): ScheduleState {
    return this._activeState;
  }

  @Output() stateChange = new EventEmitter<ScheduleState>();

  componentClass: string;
  tooltip: string;
  icon: string;

  recalculateLabels() {
    this.componentClass = this.getComponentClass();
    this.tooltip = this.getTooltip();
    this.icon = this.getIcon();
  }

  moveToState() {
    if (this.canMoveFromTo(this.activeState, this.scheduleState)) {
      this.stateChange.emit(this.scheduleState);
    }
  }

  cssClasses(): any {
    const classObject = {};
    classObject[this.getComponentClass()] = true;
    classObject[this.getIcon()] = true;
    return classObject;
  }

  getComponentClass(): string {
    const className = this.scheduleState?.toString()?.toLowerCase() || '';
    if (this.scheduleState == this.activeState) {
      return className;
    }
    if (this.canMoveFromTo(this.activeState, this.scheduleState)) {
      return 'can-move-to';
    }
    return 'notactive';
  }

  getTooltip(): string {
    if (this.scheduleState === this.activeState) {
      return `is ${this.scheduleState?.toString()}`;
    }
    return `move to ${this.scheduleState?.toString()}`;
  }

  getIcon(): string {
    switch (this.scheduleState) {
      case ScheduleState.OPEN: {
        return 'editable-file';
      }
      case ScheduleState.PLANNING: {
        return 'list-edit';
      }
      case ScheduleState.DRAFT: {
        return 'document-for-signing';
      }
      case ScheduleState.DISTRIBUTED: {
        return 'document-approved';
      }
    }
  }

  private canMoveFromTo(from: ScheduleState, to: ScheduleState) {
    if (from === ScheduleState.OPEN && to === ScheduleState.PLANNING) {
      return true;
    }
    if (from === ScheduleState.PLANNING && (to === ScheduleState.DRAFT || to === ScheduleState.OPEN)) {
      return true;
    }
    if (from === ScheduleState.DRAFT && (to === ScheduleState.DISTRIBUTED || to === ScheduleState.PLANNING)) {
      return true;
    }
    if (from === ScheduleState.DISTRIBUTED && to === ScheduleState.DRAFT) {
      return true;
    }
  }
}

import { BaseModel } from '../base.model';

export class QualificationLicense extends BaseModel {
  public employeeId: number;
  public name: string;
  public obtained: string;
  public validTo: string;
  public validFrom: string;

  public qualificationId?: number;
}


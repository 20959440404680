import * as d3 from 'd3';

import { CommentsTooltip } from '@shared/index';
import { pathForRoundedRect, translate } from '@shared/helpers/d3';
import { CommentModel, VacayRequestModel } from '@shared/models';

import { ScheduleTimeline } from '../timeline';
import { Activity } from './activity';

export class VacayRequestActivity extends Activity<VacayRequestModel> {
  public commentsTooltip: CommentsTooltip;

  protected $code: d3.Selection<SVGTextElement, any, any, any>;
  protected $background: d3.Selection<SVGRectElement, any, any, any>;
  protected $shadow: d3.Selection<SVGRectElement, any, any, any>;
  protected $wishIndication: d3.Selection<SVGPathElement, any, any, any>;

  protected comments: CommentModel[];

  constructor(model: VacayRequestModel, timeline: ScheduleTimeline) {
    super(model, timeline);

    this.$el.attr('class', 'request');

    this.$shadow = this.$el.append<SVGRectElement>('rect').attr('class', 'request-shadow');
    this.$background = this.$el.append<SVGRectElement>('rect').attr('class', 'request-background');
    this.$code = this.$el.append<SVGTextElement>('text').attr('class', 'request-code');
    this.$wishIndication = this.$el.append<SVGPathElement>('path').attr('class', 'request-wish-indicator');

    // this.comments = model.comments;
  }

  get isDayOff() {
    return this.model.isDayOff;
  }

  get isVacation() {
    return this.model.isVacation;
  }

  get width() {
    const cell = this.sizes.cell;
    return cell - this.offset * 2;
  }

  get offset() {
    const cell = this.sizes.cell;
    return cell * 0.1;
  }

  /**
   * Render vacay request
   */
  public render() {
    if (this.isVacation) {
      return;
    }

    const cell = this.sizes.cell;
    const offset = cell * 0.1;
    const size = cell - offset * 2;
    const halfSize = size / 2;

    this.$el.attr('transform', translate(offset, offset));

    this.$background.attr('width', size).attr('height', size).attr('rx', 3).attr('ry', 3);

    this.$shadow.attr('width', size).attr('height', size).attr('rx', 3).attr('ry', 3);

    this.$wishIndication.classed(this.model.wishStatus.textStatus, true).attr('d', () => {
      return pathForRoundedRect(0.25, size - 4, size - 0.5, 4, 2.5, false, false, true, true);
    });

    this.$code.text(this.model.code).attr('x', halfSize).attr('y', halfSize).attr('dy', '0.5ex');
  }

  /**
   * Remove vacay request
   */
  public remove() {
    this.$el.remove();

    if (this.day && this.isVacation) {
      this.day.week.vacayRequest = void 0;
    }

    if (this.day) {
      this.day.vacayRequest = void 0;
    }
  }
}

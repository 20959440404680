<div class="page">
    <div class="top">
        <span (click)="buttonClicked" *ngIf="showButton" class="button">
            {{ buttonText }} <i class="material-icons">{{ buttonIcon }}</i>
        </span>
        <h1 *ngIf="showPageTitle" class="light center">{{ pageTitle }}</h1>
        <router-outlet></router-outlet>
    </div>
    <div class="bottom">
        <ng-content></ng-content>
    </div>
</div>
import * as d3 from 'd3';
import { isoWeek } from '@helpers';

import { BaseInterval } from './base.interval';
import { Timeline } from '../timeline';
import { IBaseMenu } from '../menu/base-menu.interface';
import { IBaseGrid } from '../grid/base-grid.interface';

export class WeekInterval extends BaseInterval {

  static get type() {
    return WeekInterval.TYPES.Week;
  }

  get title() {
    return isoWeek(this._dateFrom).toString();
  }

  getTitleForTab(dateFrom: Date, isActive?: boolean) {
    return dateFrom.getFullYear().toString();
  }

  protected $month: d3.Selection<any, any, any, any>;
  protected $short: d3.Selection<any, any, any, any>;

  constructor(
    protected _timeline: Timeline,
    protected _grid: IBaseGrid,
    protected _menu: IBaseMenu,
    protected _dateFrom: Date,
    protected _x: number,
    protected _params: any = {}
  ) {
    super(_timeline, _grid, _menu, _dateFrom, _x, _params);
    // this.$month = this.$el.append('text').classed('month', true);
    // this.$short = this.$el.append('line');
    // this.$title.classed('text date-day', true);
  }

  public render() {
    // super.render();
    //
    // let sizes = this._timeline.sizes(),
    //   iw = sizes.intervalWidth,
    //   mh = sizes.menuHeight,
    //   ih = sizes.intervalHeight,
    //   first = Math.round(ih * 0.59),
    //   second = Math.round(ih * 0.36);
    //
    // this.$line.attr('x1', iw)
    //   .attr('x2', iw)
    //   .attr('y1', -first)
    //   .attr('y2', mh)
    //   .attr('class', 'line line-interval');
    //
    // this.$title.text(this.title())
    //   .attr('y', -first / 2)
    //   .attr('x', iw / 2)
    //   .attr('dy', '0.5ex');
    //
    // if (this.isDivider()) {
    //   let offset = this.offset(),
    //     next = new Date(this._dateTo.getTime());
    //   next.setDate(next.getDate() + 1);
    //
    //   this.$short.attr('visibility', 'visible')
    //     .attr('x1', offset)
    //     .attr('x2', offset)
    //     .attr('y1', -ih)
    //     .attr('y2', -first)
    //     .attr('class', 'line');
    //
    //   this.$month.attr('visibility', 'visible')
    //     .text(MONTH_NAMES_LONG[next.getMonth()])
    //     .attr('y', -ih + second / 2)
    //     .attr('x', offset + iw / 2)
    //     .attr('dy', '0.5ex');
    //
    // } else {
    //   this.$short.attr('visibility', 'hidden');
    //   this.$month.attr('visibility', 'hidden');
    // }
    //
    // return this.el;
  }

  /********************************* Dates ************************************************/
  /**
   * Setter for dates
   * @param dateFrom
   * @returns {DayInterval}
   */
  public setDates(dateFrom = this._dateFrom) {
    this._dateFrom = WeekInterval.precision(dateFrom);

    this._dateTo = new Date(this._dateFrom.getTime());
    this._dateTo.setDate(this._dateTo.getDate() + 7);

    this._dateFromUnix = this._dateFrom.getTime();
    this._dateToUnix = this._dateTo.getTime();

    return this;
  }

  /**
   * Date for next interval
   * @returns {Date}
   */
  public next() {
    const date = new Date(this._dateFrom.getTime());
    date.setDate(date.getDate() + 7);
    return date;
  }

  /**
   * Date for prev interval
   * @returns {Date}
   */
  public prev() {
    const date = new Date(this._dateFrom.getTime());
    date.setDate(date.getDate() - 7);
    return date;
  }

  /**
   * Will return count of intervals what will be overlaped by shift
   * @param from {Date}
   * @param to {Date}
   * @returns {number}
   */
  public getCountOfUsedIntervals(from, to) {
    return Math.ceil(Math.round((to.getTime() - from.getTime()) / 60000) / 10080);
  }

  /**
   * Round up to start of month
   * @returns {Date}
   */
  public base() {
    const date = this._dateFrom;
    return new Date(date.getFullYear(), 0, 1);
  }

  /**
   * Start of next month
   * @returns {Date}
   */
  public nextBase() {
    const date = this.base();
    return new Date(date.getFullYear() + 1, 0, 1);
  }

  /**
   * Start of prev month
   * @returns {Date}
   */
  public prevBase() {
    const date = this.base();
    return new Date(date.getFullYear() - 1, 0, 1);
  }

  /*********************************** Static *********************************************/
  /**
   * Round hours/minutes/seconds, set date to Monday
   * @param date
   * @returns {Date}
   */
  static precision(date: Date): Date {
    const day = (date.getDay() % 7 || 7) - 1;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - day);
  }
}

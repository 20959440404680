import { AbstractControl, FormGroup } from '@angular/forms';

export function bothSetOrBothNulls(controlName: string, secondControlName: string) {
  return (fg: AbstractControl) => {
      const formGroup = fg as FormGroup;
      const control =  formGroup.controls[controlName];
      const secondControl = formGroup.controls[secondControlName];
      const controlSetToNull = control.value === null || control.value === undefined;
      const secondControlSetToNull = secondControl.value === null || secondControl.value === undefined;
      if (controlSetToNull !== secondControlSetToNull) {
          const errors = { bothSetOrBothNulls: true };
          secondControl.setErrors(errors);
          return errors;
      } else {
          secondControl.setErrors(null);
          return null;
      }
  };
}

import * as d3 from 'd3';
import { Observable, Subject, of, merge, fromEvent } from 'rxjs';

import { translate, xmlns } from '@shared/helpers/d3';
import { zeroFill } from '@shared/helpers/data';
import { weekDay, getISODateOnlyStringFromLocalMidnight } from '@shared/helpers/date';
import { HolidayModel, CommentModel } from '@shared/models';

import { MasterplanActivity, VacayRequestActivity, AgendaRequestActivity } from '../activities';
import { DayHealth } from '../health';
import { StatisticStatus } from '../../../helpers';
import { DayStatistic } from '../statistic';
import { Calendar } from './calendar';
import { Month } from './month';
import { Week } from './week';
import { WeekDay } from './week-day';
import { takeUntil, flatMap, delay } from 'rxjs/operators';
import { Clicker, TooltipReference } from '@shared/components';
import { ScheduleWishMenuComponent } from '@app/vacay/_shared/components/wish-menu/wish-menu.component';

export class Day {
  public $commentsIndicator: d3.Selection<any, any, any, any>;

  set month(month: Month) {
    this._month = month;
  }

  get month() {
    return this._month;
  }

  set week(week: Week) {
    this._week = week;
  }

  get week() {
    return this._week;
  }

  set weekDay(weekDayToSet: WeekDay) {
    this._weekDay = weekDayToSet;
  }

  get weekDay() {
    return this._weekDay;
  }

  set vacayRequest(request: VacayRequestActivity) {
    this._vacayRequest = request;
    this.render();
  }

  get vacayRequest(): VacayRequestActivity {
    return this._vacayRequest;
  }

  set agendaRequest(agendaRequest: AgendaRequestActivity) {
    this._agendaRequest = agendaRequest;
    this.render();
  }

  get agendaRequest(): AgendaRequestActivity {
    return this._agendaRequest;
  }

  set masterplan(masterplan: MasterplanActivity) {
    this._masterplan = masterplan;
    this.render();
  }

  get masterplan() {
    return this._masterplan;
  }

  /**
   * Get request or masterplan activity by priority
   *
   * @return {VacayRequestActivity|MasterplanActivity}
   */
  get activity() {
    return this.vacayRequest || this.agendaRequest || this.masterplan;
  }

  get statistic() {
    return this._statistic;
  }

  get health() {
    return this._health;
  }

  set holiday(holiday: HolidayModel) {
    this._holiday = holiday;
    this.render();
  }

  get holiday() {
    return this._holiday;
  }

  get comments() {
    return this._comments;
  }

  get date() {
    return this._date;
  }

  get keyDate() {
    const year = this.date.getFullYear();
    const month = zeroFill(this.date.getMonth() + 1, 2);
    const day = zeroFill(this.date.getDate(), 2);

    return year + month + day;
  }

  get indexByStorage() {
    return this.storage.days.indexOf(this);
  }

  get indexByCalendar() {
    return this._calendar.days.indexOf(this);
  }

  get indexByMonth() {
    return this._month.days.indexOf(this);
  }

  get indexByWeek() {
    return this._week.days.indexOf(this);
  }

  get hasVacayRequest() {
    return !!this._vacayRequest;
  }

  get hasAgendaRequest() {
    return !!this._agendaRequest;
  }

  get hasMasterplan() {
    return !!this._masterplan;
  }

  get isHoliday() {
    return !!this._holiday;
  }

  get isLockedVacayDay() {
    if (this._isLockedVacayDay === undefined) {
      const date = getISODateOnlyStringFromLocalMidnight(this.date);
      this._isLockedVacayDay = this.storage.vacayLockedDays.has(date);
    }
    return this._isLockedVacayDay;
  }

  get isDayOff() {
    return this.activity && this.activity.isDayOff;
  }

  get isWeekend() {
    const weekDayIndex = weekDay(this.date);
    return weekDayIndex === 5 || weekDayIndex === 6;
  }

  get isVacation() {
    return this.week.hasVacayRequest;
  }

  get isLocked() {
    return this._calendar.isLocked || this.isVacation;
  }

  /**
   * Get prev day
   *
   * @return {Day}
   */
  get prev() {
    return this.storage.days[this.indexByStorage - 1];
  }

  /**
   * Get next day
   *
   * @return {Day}
   */
  get next() {
    return this.storage.days[this.indexByStorage + 1];
  }

  get timeline() {
    return this._calendar.timeline;
  }

  get defs() {
    return this._calendar.defs;
  }

  get sizes() {
    return this._calendar.sizes;
  }

  get storage() {
    return this._calendar.storage;
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.el;
  }

  public onUpdate = new Subject();

  protected el: SVGElement;
  protected $el: d3.Selection<SVGElement, any, any, any>;

  protected $background: d3.Selection<SVGRectElement, any, any, any>;
  protected $backgroundLock: d3.Selection<SVGRectElement, any, any, any>;

  protected $number: d3.Selection<SVGTextElement, any, any, any>;
  protected $statistic: d3.Selection<SVGPathElement, any, any, any>;
  protected $holiday: d3.Selection<SVGPathElement, any, any, any>;

  protected _month: Month;
  protected _week: Week;
  protected _weekDay: WeekDay;

  protected _agendaRequest: AgendaRequestActivity;
  protected _vacayRequest: VacayRequestActivity;
  protected _masterplan: MasterplanActivity;
  protected _holiday: HolidayModel;
  protected _comments: CommentModel[] = [];

  protected _statistic: DayStatistic;
  protected _health: DayHealth;

  private clicker: Clicker;
  private isMenuOpened = false;
  private onRendering$ = new Subject();

  private _isLockedVacayDay = undefined;

  constructor(
    protected _date: Date,
    protected _calendar: Calendar
  ) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).attr('class', 'day');

    this.$background = this.$el.append<SVGRectElement>('rect').attr('class', 'day-background');
    this.$backgroundLock = this.$el.append<SVGRectElement>('rect').attr('class', 'day-background-lock');
    this.$holiday = this.$el.append<SVGPathElement>('path').attr('class', 'holiday');
    this.$number = this.$el.append<SVGTextElement>('text').attr('class', 'day-number');
    this.$statistic = this.$el.append<SVGPathElement>('path').attr('class', 'statistic');

    this.clicker = new Clicker(this.node);
    this._statistic = new DayStatistic(this, null);
    this._health = new DayHealth(this);

    this.subscribe();
  }

  /**
   * Render day
   */
  public render() {
    this.onRendering$.next();

    this.situateCell();

    this.renderBackground();
    this.renderBackgroundLock();

    this.renderHoliday();
    this.renderStatistic();
    this.renderMasterplan();
    this.renderAgendaRequest();
    this.renderVacayRequest();

    this.renderDayNumber();

    this.checkClasses();
    this.events();
    this.updateCommentIndicator();
  }

  /**
   * Lookup vacay request
   */
  public lookupVacayRequest() {
    const keyDate = this.keyDate;
    const request = this.storage.mapVacayRequestsByDate.get(keyDate);

    if (!request) {
      return;
    }

    if (request.isVacation) {
      this.week.vacayRequest = request;
    }

    this.vacayRequest = request;
    request.day = this;

    this.reRenderCommentIndicator();
  }

  /**
   * Lookup agenda request
   */
  public lookupAgendaRequest() {
    const keyDate = this.keyDate;
    const request = this.storage.mapAgendaRequestsByDate.get(keyDate);

    if (!request) {
      return;
    }

    this.agendaRequest = request;
    request.day = this;

    this.reRenderCommentIndicator();
  }

  public addComment(comment: CommentModel): void {
    if (!this._comments) {
      this._comments = [];
    }

    this._comments.push(comment);
    this.updateCommentIndicator();
  }

  public removeComment(commentToRemove: CommentModel): void {
    this._comments = this._comments.filter(comment => comment.id !== commentToRemove.id);

    const hasComments = this.comments && this.comments.length;
    if (!hasComments) {
      this.removeCommentIndicator();
    }
  }

  public updateCommentIndicator() {
    const hasComments = this.comments && this.comments.length;
    if (!hasComments) {
      this.removeCommentIndicator();
    } else {
      if (!this.$commentsIndicator) {
        this.renderCommentIndicator();
      }
    }
  }

  public reRenderCommentIndicator(): void {
    this.removeCommentIndicator();
    this.renderCommentIndicator();
  }

  public lookupComments() {
    const keyDate = this.keyDate;
    const commentsOnDay = this.storage.commentsByDate.get(keyDate);

    if (!commentsOnDay) {
      return;
    }

    this._comments = commentsOnDay;
  }

  public renderCommentIndicator(): void {
    const hasComments = this.comments && this.comments.length;
    if (!hasComments) {
      return null;
    }

    this.$el.select('.comment-circle').remove();
    const x = this.sizes.cell / 2;
    const y = this.sizes.cell * 0.0925;
    this.$commentsIndicator = this.$el
      .append('circle')
      .classed('comment-circle', true)
      .attr('r', 4)
      .attr('transform', translate(x, y))
      .attr('fill', this.comments[0].color);
  }

  public removeCommentIndicator(): void {
    if (this.$commentsIndicator) {
      this.$commentsIndicator.remove();
      this.$commentsIndicator = undefined;
    }
  }

  /**
   * Lookup masterplan activity
   */
  public lookupMasterplan() {
    this.masterplan = this.storage.mapMasterplanByDate.get(this.keyDate);
  }

  /**
   * Lookup statistic
   */
  public lookupStatistic() {
    this.statistic.statistic = this.storage.mapStatisticByDate.get(this.keyDate);
    this.renderStatistic();
  }

  /**
   * Update health status
   *
   * @param {string} color
   */
  public updateHealthStatus(color = 'transparent') {
    this.$background.attr('fill', color);
  }

  /**
   * Render vacay requests
   */
  protected renderVacayRequest() {
    this.health.check();

    if (this.hasVacayRequest) {
      this.el.appendChild(this.vacayRequest.node);
      this.vacayRequest.render();
    }

    this.renderStatistic();
  }

  /**
   * Render agenda requests
   */
  protected renderAgendaRequest() {
    this.health.check();

    if (this.hasAgendaRequest) {
      this.el.appendChild(this.agendaRequest.node);
      this.agendaRequest.render();
    }
  }

  /**
   * Render masterplan
   */
  protected renderMasterplan() {
    this.health.check();

    if (this.hasMasterplan && !this.hasAgendaRequest) {
      this.el.appendChild(this.masterplan.node);
      this.masterplan.render();
    }
  }

  /**
   * Render statistic
   */
  protected renderStatistic() {
    const sizes = this.sizes;
    const size = sizes.cell * 0.4;
    const offset = sizes.cell * 0.1;

    const statisticStatus = this.statistic.status;

    this.$statistic
      .classed('underDelivery', statisticStatus === StatisticStatus.UnderDelivery)
      .attr('d', `M 0 ${size} L 0 0 L ${size} 0`)
      .attr('transform', translate(0.5, 0.5))
      .raise();

    if (!this.hasVacayRequest) {
      this.$statistic.attr(
        'd',
        () =>
          `M 0 ${size} L 0 0 L ${size} 0
         L ${size - offset} ${offset}
         L ${offset} ${offset}
         L ${offset} ${size - offset}`
      );
    }
  }

  /**
   * Render background
   */
  protected renderBackground() {
    const cell = this.sizes.cell;

    this.$background.attr('fill', 'transparent').attr('width', cell).attr('height', cell);
  }

  /**
   * Render background lock
   */
  protected renderBackgroundLock() {
    const cell = this.sizes.cell;

    this.$backgroundLock.attr('fill', this.defs.lockMask).attr('width', cell).attr('height', cell);
  }

  /**
   * Render day number
   */
  protected renderDayNumber() {
    const cell = this.sizes.cell;

    this.$number
      .text(zeroFill(this.date.getDate(), 2))
      .attr('x', cell / 2)
      .attr('y', cell / 2)
      .attr('dy', '0.5ex');

    const hasData = this.hasVacayRequest || this.hasAgendaRequest || this.hasMasterplan;
    if (hasData && !this.isVacation) {
      this.$number.attr('x', cell * 0.8).attr('y', cell * 0.2);
    }
  }

  /**
   * Render holiday
   */
  protected renderHoliday() {
    if (!this.isHoliday) {
      return;
    }

    const cell = this.sizes.cell;
    const size = cell * 0.25;

    this.$holiday.attr('d', () => {
      return `M ${cell} ${cell} h -${size} l ${size} -${size} Z`;
    });
  }

  /**
   * Lookup holiday
   */
  protected lookupHoliday() {
    this.holiday = this.storage.holidays.find(holiday => holiday.compare(this.date));
  }

  /**
   * Check classes
   */
  protected checkClasses() {
    this.$el
      .classed('isLocked', this.isLocked)
      .classed('isHoliday', this.isHoliday)
      .classed('isLockedVacayDay', this.isLockedVacayDay)
      .classed('isDayOff', this.isDayOff)
      .classed('isWeekend', this.isWeekend)
      .classed('isVacation', this.isVacation)
      .classed('hasVacayRequest', this.hasVacayRequest && !this.isWeekend)
      .classed('hasAgendaRequest', this.hasAgendaRequest)
      .classed('hasMasterplan', this.hasMasterplan);
  }

  /**
   * Events
   */
  protected events() {
    this.subscribeToClickEvent();
    const mouseLeaveEvent = this.subscribeToMouseLeaveEvent();
    this.subscribeToMouseEnterEvent(mouseLeaveEvent);
  }

  protected subscribe() {
    this.timeline.onInitHolidays.pipe(takeUntil(this.timeline.onDestroy)).subscribe(() => this.lookupHoliday());

    this._calendar.onReady.pipe(takeUntil(this.timeline.onDestroy)).subscribe(() => {
      this.lookupHoliday();
      this.lookupStatistic();
      this.lookupMasterplan();
      this.lookupVacayRequest();
      this.lookupAgendaRequest();
      this.lookupComments();
    });
  }

  private subscribeToClickEvent(): void {
    this.clicker.longClick.pipe(takeUntil(merge(this.onRendering$, this.timeline.onDestroy))).subscribe(() => {
      if (!this.timeline.requestsInitialized || this.isLocked) {
        return;
      }

      if (this.isLockedVacayDay && this.hasMasterplan) {
        this.timeline.context.ngZone.run(() => {
          const editMenu = this.initializeWishMenuComponent(false);
          editMenu.onClose.subscribe(() => (this.isMenuOpened = false));
        });
      }
    });

    this.clicker.click.pipe(takeUntil(merge(this.onRendering$, this.timeline.onDestroy))).subscribe(() => {
      const isClickForbidden = this.isLocked || this.isLockedVacayDay;
      const areDataMissing = !this.storage.aliases.length;
      const isRequestInitialized = !this.timeline.requestsInitialized;
      if (isClickForbidden || areDataMissing || isRequestInitialized) {
        return;
      }

      this.isMenuOpened = true;

      this.timeline.context.ngZone.run(() => {
        const editMenu = this.initializeWishMenuComponent(false);
        editMenu.onClose.subscribe(() => (this.isMenuOpened = false));
      });
    });
  }

  private initializeWishMenuComponent(isInfo: boolean): TooltipReference {
    return this.timeline.context.tooltipService.openCustomTooltip({
      component: ScheduleWishMenuComponent,
      targetElement: this.node.getBoundingClientRect(),
      placement: 'right',
      autoPlacement: true,
      context: {
        day: this,
        isInfo: isInfo
      },
      zIndex: 1000,
      classes: 'mobile-centered'
    });
  }

  private subscribeToMouseEnterEvent(mouseLeaveEvent: Observable<any>): void {
    const mouseEnter = fromEvent(this.node, 'mouseenter');
    const isSingleScheduleView = this.timeline.sizes.countVisibleSchedules === 1;

    mouseEnter
      .pipe(
        flatMap(e => of(e).pipe(delay(500), takeUntil(mouseLeaveEvent))),
        takeUntil(merge(this.onRendering$, this.timeline.onDestroy))
      )
      .subscribe(() => {
        if (this.isMenuOpened || this._calendar.isLocked) {
          return;
        }
        if (isSingleScheduleView) {
          return;
        }

        this.timeline.context.ngZone.run(() => {
          this.timeline.context.tooltipService.openCustomTooltip({
            component: ScheduleWishMenuComponent,
            targetElement: this.node.getBoundingClientRect(),
            placement: 'top',
            autoPlacement: true,
            context: {
              day: this,
              isInfo: true
            },
            autoclose: 1000
          });
        });
      });

    mouseEnter.pipe(takeUntil(merge(this.onRendering$, this.timeline.onDestroy))).subscribe(() => {
      if (this.isLocked) {
        return;
      }

      this.$el.classed('hover', true);
    });
  }

  private subscribeToMouseLeaveEvent(): Observable<any> {
    const mouseLeave = fromEvent(this.node, 'mouseleave');

    mouseLeave.pipe(takeUntil(merge(this.onRendering$, this.timeline.onDestroy))).subscribe(() => {
      this.$el.classed('hover', false);
    });

    return mouseLeave;
  }

  private situateCell() {
    const cellSize = this.sizes.cell;
    this.$el.attr('transform', () => {
      return translate(this.indexByWeek * cellSize, this.week.indexByCalendar * cellSize);
    });
  }
}

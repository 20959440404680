export interface IWheelMenuShowInterface {
  data?: WheelMenuItemModel[] | null;
  toggleResolvers?: any;
  disabledResolvers?: any;
  position?: DOMRect;
  shift?: any;
  callback?: (key: string) => void;
}

export interface IWheelMenuItem {
  classed?: string | string[];
  icon?: string | string[];
  key?: string;
  toggleStatus?: boolean;
}

export class WheelMenuItemModel {

  public key: string;
  public toggleStatus = false;
  public disabled = false;

  protected _styleTop: number;
  protected _styleLeft: number;
  protected _classed: string | string[];
  protected _icon: string | string[];

  constructor(data: IWheelMenuItem = {}) {
    this.classed = data.classed;
    this.icon = data.icon;
    this.key = data.key;
    this.toggleStatus = data.toggleStatus || false;
  }

  set classed(classed) {
    if (classed) {
      if (typeof classed === 'string') {
        this._classed = classed;
      } else if (classed instanceof Array) {
        this._classed = classed.slice();
      }
    }
  }

  get classed() {
    return this._classed;
  }

  set icon(icon) {
    if (icon) {
      if (typeof icon === 'string') {
        this._icon = icon;
      } else if (icon instanceof Array) {
        this._icon = icon.slice();
      }
    }
  }

  get icon() {
    return this._icon;
  }

  set styleTop(val) {
    this._styleTop = val;
  }

  get top() {
    return `${this._styleTop}px`;
  }

  set styleLeft(val) {
    this._styleLeft = val;
  }

  get left() {
    return `${this._styleLeft}px`;
  }

  get activeClass() {
    if (this.classed instanceof Array) {
      return this.classed[+this.toggleStatus] || this.classed[0];
    } else {
      return this.classed;
    }
  }

  get activeIcon() {
    if (this.icon instanceof Array) {
      return this.icon[+this.toggleStatus] || this.icon[0];
    } else {
      return this.icon;
    }
  }
}

import { IRouteModule, IRouteChild } from '@interfaces';

export interface IRouteConfig {
  getActiveModule: (module: string) => IRouteModule;
  getChildren: (module: string, roles: string[]) => IRouteChild[];
  getModules(): IRouteModule[];
  getToggledOnModules(): IRouteModule[];
  setModuleToggle(path: string, state: boolean): void;
  getModule(path: string): IRouteModule;
  getPermittedModule(roles: string[]): IRouteModule[];
  vacay: IRouteModule;
  rostr: IRouteModule;
  agenda: IRouteModule;
  argus: IRouteModule;
  entity: IRouteModule;
  settings: IRouteModule;
  planning: IRouteModule;
}

import * as d3 from 'd3';
import { Header } from '../headers/header';
import { Subject } from 'rxjs';
import { IBaseMenu } from '../menu/base-menu.interface';
import { Draggable } from '../draggable/draggable';
import { Defs } from '..';
import { HolidayModel } from '@src/app/_shared/models';
import { Grid } from '../grid/grid';
/**
 * Class has init function, where some init functionality can be placed
 */
export interface IInitable {
  init: () => any;
}
/**
 * Class can visualize something
 */
export interface IRenderable {
  render: () => Node;
}
/**
 * Class has function where it can listen for DOM events
 */
export interface IEventable {
  events: () => any;
}
/**
 * Class can listen event emitters
 */
export interface ISubscribable {
  subscribe: () => any;
}

/**
 * Getters for element and d3 selection of element
 */
export interface IElement {
  element: () => d3.Selection<any, any, any, any>;
  node: () => Element;
}

export interface IDraggable {
  dragStart: (target: EventTarget) => void;
  drag: () => void;
  dragEnd: () => void;
  checkDragEndLimitations?: (limits?: any[]) => boolean;
  checkDropOnRoot?: () => boolean;
  dragInAreaLimit?: (width: number, height: number) => void;
  dragInGroupLimit?: (first: number, last: number) => void;
}

export interface ITimelineConfig {
  url: string;
  startDate?: Date;
  menuWidth?: number;
  shiftMargin?: number;
}

export interface IGridConfig {
  intervalWidth: number;
  interval: any;
  step?: number;
  params?: any;
  current?: boolean;
}

/**
 * All needed timeline sizes
 */
export interface ISizes {
  height?: number;
  width?: number;
  rowHeight?: number;
  intervalWidth?: number;
  menuWidth?: number;
  menuHeight?: number;
  gridWidth?: number;
  headerHeight?: number;
  shiftMargin?: number;
}

export interface IBaseTimeline {
  readonly header: Header,
  onResize: Subject<ISizes>;
  onDestroy: Subject<void>;
  underShiftsSysContainer: d3.Selection<SVGGElement, any, any, any>;
  overShiftsSysContainer: d3.Selection<SVGGElement, any, any, any>;
  overMenuSysContainer: d3.Selection<SVGGElement, any, any, any>;
  underMenuSysContainer: d3.Selection<SVGGElement, any, any, any>;
  holidays: any[];
  onInitHolidays: Subject<HolidayModel[]>;
  config(): ITimelineConfig;
  context(): any;
  sizes(): ISizes;
  menu(): IBaseMenu;
  getColor(index: number): string;
  draggable(): Draggable;
  defs(): Defs;
  grid(): Grid;
}

<div class="wrapper">
  <div class="icon">
    <ng-content select="[icon]"></ng-content>
  </div>

  <div class="header">
    <div class="title" *ngIf="title">
      {{ title }}
    </div>
    <div *ngIf="subtitle" class="subtitle">{{ subtitle }}</div>
  </div>

  <div [class]="color">
    <div class="color-field">
      <div>{{ value }}</div>
      <mat-progress-bar
        color="accent"
        [style.visibility]="progressBarValue !== undefined ? 'visible' : 'hidden'"
        mode="determinate"
        [value]="progressBarValue"
      ></mat-progress-bar>
    </div>
  </div>

  <ng-content select="[footer]"></ng-content>
</div>

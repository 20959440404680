import * as d3 from 'd3';
import { EventEmitter } from '@angular/core';

import { BaseShift } from './base.shift';
import { Draggable } from '../draggable/draggable';
import { OpenableItem } from '../items/openable.item';
import { IArea } from '@src/app/_shared/interfaces/area.interface';


export class DraggableShift extends BaseShift {

  protected _drag: d3.DragBehavior<any, any, any>;
  protected _draggable: Draggable;

  protected onClick = new EventEmitter();
  protected _hasClick = false;

  constructor(protected _title = '',
    protected _dateFrom: Date,
    protected _dateTo: Date) {

    super(_title, _dateFrom, _dateTo);
    this._drag = d3.drag();
    this.$el.classed('draggable', true);
  }

  /**
   * Rendering
   * @returns {Element}
   */
  public render() {
    super.render();

    this.$el.on('mouseenter', () => {
      setTimeout(() => {
        if (this.$bodyContainer) {
          this.$bodyContainer.call(this._drag);
        }

        if (this.$shadow) {
          this.$shadow.classed('hover', true);
        }

        this.events();
      }, 0);

      this.$el.on('mouseenter', null);
    });

    return this.el;
  }

  /**
   * DOM event listeners
   */
  public events() {
    super.events();

    this._drag.on('start', () => {
      return (this.dragAllowed()) ? this.dragStart() : false;
    });
    this._drag.on('drag', () => {
      return (this.dragAllowed()) ? this.drag() : false;
    });
    this._drag.on('end', () => {
      return (this.dragAllowed()) ? this.dragEnd() : false;
    });
  }

  /************************************ drag ********************************************/

  protected dragAllowed() {
    const endInterval = this.endInterval();
    return !!endInterval;
  }

  protected dragStart() {
    this._hasClick = true;

    const draggable = this._timeline.draggable();
    draggable.content(this.dragRender());
    draggable.sizes(this.width(), this.height());
    draggable.area(this.dragArea());
    draggable.coords.apply(draggable, this.dragCoords());
    draggable.show().render();
    this._draggable = draggable;
    this.$el.attr('opacity', 0.7);
    this._grid.element().classed('dragging-pending', true);
  }

  /**
   * Drag event
   */
  protected drag() {
    this._hasClick = false;

    this._draggable.move(d3.event.dx, d3.event.dy);
    this._draggable.droppable.highlightDropArea(this.dragCheck());
  }

  /**
   * Drag end event
   */
  protected dragEnd() {
    if (this._hasClick) {
      this.onClick.emit();
    }
    this.$el.attr('opacity', 1);
    const canDrop = this.dragCheck();
    if (!this.dropOnSamePosition() && canDrop && !this.dragDialog(canDrop)) {
      this.dragSuccess();
    } else {
      this.dragError();
    }
    this._draggable.hide();
    this.$el.attr('opacity', 1);
    this._grid.element().classed('dragging-pending', false);
  }

  /**
   * Some rendering for draggable
   * @returns {Node}
   */
  protected dragRender() {
    const clone = d3.select<any, any>(this.clone());
    clone.attr('transform', null).classed('drag', true);
    clone.select('rect.shadow').classed('drag', true);
    return clone.node();
  }

  /**
   * Area for drag
   * @returns {IArea}
   */
  protected dragArea(): IArea {
    const sizes = this._timeline.sizes();
    const items = this._menu.visible;
    return {
      left: 0,
      top: 0,
      right: sizes.intervalWidth * this._grid.length(),
      bottom: items[items.length - 1].y()
    };
  }

  /**
   * Return start coordinates for drag
   * can be d3.mouse(this.el);
   */
  protected dragCoords() {
    const x = this._interval.x();
    const y = this._item.shiftPosition(this);
    return [x, y];
  }

  /**
   * Check if drop task on openable item
   */
  protected dragCheck() {
    return this.getNearItemByCoord() instanceof OpenableItem &&
      this.checkDropPlace();
  }

  protected dropOnSamePosition() {
    const newItem = this.getNearItemByCoord();
    const newInterval = this.getNearIntervalByCoord();

    return this._interval === newInterval && this._item === newItem;
  }

  /**
   * Should we show dialog after drag?
   */
  protected dragDialog(d) {
    return false;
  }

  /**
   * Success drag end event
   */
  protected dragSuccess() {
    const _item = this.getNearItemByCoord();
    const interval = this.getNearIntervalByCoord();
    const item = this._item as OpenableItem;

    this._interval.removeShift(this);
    this.interval(interval);
    interval.addShift(this);

    item.removeShift(this);
    this.item(_item);
    _item.addShift(this);

    this.setDates(interval.dateFrom());

    this.render();
  }

  /**
   * Checking existing under draggable element another shifts
   * @returns {boolean}
   */
  protected checkDropPlace(allowedItems = [], passedItem: any = false) {
    const item = passedItem || this.getNearItemByCoord();
    if (!item.visible || allowedItems.indexOf(item.constructor) > -1) { return true; }

    const leftInterval = this.getNearIntervalByCoord();
    const rightInterval = this.getNearIntervalByCoord(this._draggable.x() + this._draggable.width);

    const visibleItem = item.visible().find((vItem) => {
      return vItem.overlapByCoords(leftInterval.x(), rightInterval.x());
    });

    return !visibleItem || visibleItem === this;
  }

  /**
   * If !this.dragCheck();
   */
  protected dragError() {
  }

  protected getNearItemByCoord() {
    // item is getting by coordinates, but actually it is in almost all cases equal to this._item
    return this._menu.getNearItemByCoord(this._draggable.y(), this.height()) as OpenableItem;
  }

  protected getNearIntervalByCoord(customX?: number) {
    // interval is getting by coordinates, but actually it is in almost all cases equal to this._interval
    // Note: this._grid may NOT be for some shifts like X or SM.
    return this._grid.getNearIntervalByCoord(customX || this._draggable.x());
  }
}


import * as d3 from 'd3';
import { translate } from '@helpers';
import { ScheduleModel } from '@models';
import { BasePartialHeader } from '../base-partial.header';
import { ScheduleRow } from './schedule-row';
import { IBaseScheduleHeader } from './base-schedule-header.interface';

export class SchedulesHeader extends BasePartialHeader implements IBaseScheduleHeader {

  get scheduleRows() {
    return this._scheduleRows;
  }

  get height() {
    return 49;
  }

  protected $backgroundContainer: d3.Selection<SVGGElement, any, any, any>;
  protected $background: d3.Selection<SVGRectElement, any, any, any>;
  protected $backgroundPath: d3.Selection<SVGPathElement, any, any, any>;
  protected $backgroundShadow: d3.Selection<SVGPathElement, any, any, any>;

  protected $title: d3.Selection<SVGTextElement, any, any, any>;

  protected $scheduleRows: d3.Selection<SVGGElement, any, any, any>;

  _schedule: ScheduleModel;
  private _scheduleRows: ScheduleRow[] = [];
  private _scheduleRowsByGroup = new Map<number, ScheduleRow>();

  public init() { }

  public initElements() {
    super.initElements();

    this.$el.classed('schedules-header', true);

    this.$backgroundContainer = this.$el
      .append<SVGGElement>('g')
      .classed('schedules-header-background', true);

    this.$background = this.$backgroundContainer.append<SVGRectElement>('rect');
    this.$backgroundShadow = this.$backgroundContainer
      .append<SVGPathElement>('path')
      .classed('schedules-header-background-shadow', true);
    this.$backgroundPath = this.$backgroundContainer
      .append<SVGPathElement>('path')
      .classed('schedules-header-background-path', true);

    this.$title = this.$el
      .append<SVGTextElement>('text')
      .classed('schedules-header-title', true);

    this.$scheduleRows = this.$el
      .append<SVGGElement>('g')
      .classed('schedules-header-schedules-row', true);
  }

  public render() {
    const { menuWidth } = this.timeline.sizes();

    this.$el.attr('transform', translate(0, this.y));

    this.$title
      .text('Schedule')
      .attr('x', menuWidth - 18)
      .attr('y', this.height / 2)
      .attr('dy', '0.5ex');

    this.$scheduleRows.attr('transform', translate(menuWidth, 1));

    this.renderBackground();
  }

  public subscribe() { }

  public addSchedules(
    schedules: ScheduleModel[],
    groupId: number,
    indicationFillColor: string
  ) {
    const scheduleRow = new ScheduleRow({ indicationFillColor, schedules }, this);

    this._schedule = schedules[0];
    this._scheduleRows.push(scheduleRow);
    this._scheduleRowsByGroup.set(groupId, scheduleRow);

    scheduleRow.init();

    scheduleRow.render();
    this.$scheduleRows.node().appendChild(scheduleRow.node);

    scheduleRow.subscribe();
  }

  public getScheduleModel(): ScheduleModel {
    return this._schedule;
  }

  protected renderBackground() {
    const { width, menuWidth } = this.timeline.sizes();
    const height = this.height;

    this.$background
      .attr('width', width)
      .attr('height', height);

    this.$backgroundPath.attr('d', `
      M 0 0
      V 21
      H ${menuWidth / 2 - 18}
      c 2.2 0 5.04 1.45 6.34 3.24
      l 14.44 19.25
      c 1.82 2.38 4.53 3.89 7.5 4.22
      H ${width}
      V 0
      z
    `);
    this.$backgroundShadow
      .attr('d', this.$backgroundPath.attr('d'))
      .attr('transform', translate(1, 1));
  }

}

import { IBaseModel } from '@model/interfaces';
import { DivisionModelData } from '..';

export interface SiteModelData extends IBaseModel {
  name?: string;
  divisionId?: number;
  division?: DivisionModelData;
  position?: number;
  type?: string;
  typeSingular?: string;
}

import { RequiredShiftsPendingChanges } from '@shared/enums/required-shift-pending-changes.enum';
import { ShiftCodeTypeEnum } from '../../enums/shift-code-type.enum';
import { Activity } from './activity.model';
import { AssignedActivity } from './assigned-activity.model';

export class RequiredShift extends Activity {
  timestamp: string;
  group: string;
  skills: string[];
  endorsements: string[];
  licences: string[];
  isAssigned = true;
  isLocked: boolean;
  isExcluded: boolean;
  type: ShiftCodeTypeEnum;
  source: RequiredShiftSource;
  pendingChanges: RequiredShiftsPendingChanges;
  planId: number;
  shiftCodeId: number;

  constructor(activity: AssignedActivity) {
    super();
    this.id = activity.parentId;
    this.alias = activity.alias;
    this.code = activity.code;
    this.dateFrom = activity.dateFrom;
    this.dateTo = activity.dateTo;
    this.parentId = activity.parentId;
    this.parentType = activity.parentType;
    this.timestamp = null;
    this.type = activity.type;
    this.shiftCodeId = activity.shiftCodeId;
  }
}

export type RequiredShiftSource = 'planning' | 'rostr' | 'argus';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-basic-widget',
  templateUrl: './basic-widget.component.html',
  styleUrls: ['./basic-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicWidgetComponent {
  @Input()
  title = '';

  @Input()
  subtitle = '';

  @Input()
  loading = false;

  @Input()
  backgroundColor: 'violetLight' | 'violetDark' | 'blue' | 'tangerine' | 'green' | 'yellow' | 'grey' | '' = '';
}

import * as d3 from 'd3';

import { translate, xmlns } from '@shared/helpers/d3';
import {
  weekDay, formatMonth,
} from '@shared/helpers/date';

import { Calendar } from './calendar';
import { Day } from './day';
import { Week } from './week';
import { MonthFormat } from '@src/app/_shared';


export class Month {

  protected el: SVGElement;
  protected $el: d3.Selection<SVGElement, any, any, any>;

  protected _label: string;
  protected $label: d3.Selection<SVGElement, any, any, any>;
  protected $labelRect: d3.Selection<SVGRectElement, any, any, any>;
  protected $labelText: d3.Selection<SVGTextElement, any, any, any>;

  protected $path: d3.Selection<SVGPathElement, any, any, any>;

  protected _weeks: Week[] = [];
  protected _days: Day[] = [];

  constructor(protected _dateFrom: Date,
    protected _dateTo: Date,
    protected _calendar: Calendar) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).attr('class', 'month');

    this.$label = this.$el.append<SVGElement>('g').attr('class', 'month-label');
    this.$labelRect = this.$label.append<SVGRectElement>('rect');
    this.$labelText = this.$label.append<SVGTextElement>('text');

    this.$path = this.$el.append<SVGPathElement>('path');
  }

  get weeks() {
    return this._weeks;
  }

  get days() {
    return this._days;
  }

  get dateFrom() {
    return this._dateFrom;
  }

  get dateTo() {
    return this._dateTo;
  }

  get indexByCalendar() {
    return this._calendar.months.indexOf(this);
  }

  get countWeeks() {
    return this._weeks.length;
  }

  get label() {
    if (!this._label) {
      let label = formatMonth(this.dateFrom, MonthFormat.long);
      let maxLabelLenght = 20;

      if (this.countWeeks === 1) {
        maxLabelLenght = 1;
      } else if (this.countWeeks <= 2) {
        maxLabelLenght = 3;
      }

      if (label.length > maxLabelLenght) {
        label = formatMonth(this.dateFrom, MonthFormat.short);
      }
      if (label.length > maxLabelLenght) {
        label = `${label.charAt(0)}...`;
      }

      this._label = label;
    }

    return this._label;
  }

  get sizes() {
    return this._calendar.sizes;
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.el;
  }

  /**
   * Add related day by month
   *
   * @param {Day} day
   */
  public addRelatedDay(day: Day) {
    this._days.push(day);
  }

  /**
   * Add related week by month
   *
   * @param {Week} week
   */
  public addRelatedWeek(week: Week) {
    this._weeks.push(week);
  }

  /**
   * Render month
   */
  public render() {
    if (!this.countWeeks) { return; }

    const cell = this.sizes.cell;
    const firstWeek = this._weeks[0];

    this.$el.attr('transform', translate(0, firstWeek.indexByCalendar * cell));

    this.renderLabel();
    this.renderPath();
  }

  /**
   * Render label
   */
  protected renderLabel() {
    const cell = this.sizes.cell;
    const width = cell;
    const height = cell * this._weeks.length;

    this.$labelRect
      .attr('width', width)
      .attr('height', height);

    this.$labelText
      .text(this.label)
      .attr('transform', 'rotate(-90)')
      .attr('y', width / 2)
      .attr('x', -height / 2)
      .attr('dy', '0.5ex');
  }

  /**
   * Render path
   */
  protected renderPath() {
    const cell = this.sizes.cell;
    const height = this.sizes.cell * this._weeks.length;
    const width = this.sizes.scheduleWidth;
    const weekDayOfLastDay = 3 + weekDay(this._dateTo);

    this.$path.attr('d', () => {
      return `M 0 ${height} H ${weekDayOfLastDay * cell} v -${cell} H ${width}`;
    });
  }

}

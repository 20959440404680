import { HolidayModel, ShiftAliasModel, VacayRequestStatisticModel, CommentModel } from '@shared/models';
import { lower } from '@shared/helpers/data';

import { keyFormatFromDate } from './helpers';
import { Day, HealthGroup, VacayRequestActivity, AgendaRequestActivity, MasterplanActivity } from '../models';
import { VacationShiftAlias } from '../enums/vacation-shift-alias.enum';

export class ScheduleCalendarStorage {
  public days: Day[] = [];
  public mapDaysByDate = new Map<string, Day>();

  public healthGroups: HealthGroup[] = [];

  public mapVacayRequestsById = new Map<number, VacayRequestActivity>();
  public mapVacayRequestsByDate = new Map<string, VacayRequestActivity>();

  public mapAgendaRequestsById = new Map<number, AgendaRequestActivity>();
  public mapAgendaRequestsByDate = new Map<string, AgendaRequestActivity>();

  public mapMasterplanById = new Map<number, MasterplanActivity>();
  public mapMasterplanByDate = new Map<string, MasterplanActivity>();

  public mapStatisticByDate = new Map<string, VacayRequestStatisticModel>();

  public dayOffAlias: ShiftAliasModel;
  public vacationAlias: ShiftAliasModel;
  public aliases: ShiftAliasModel[] = [];
  public mapAliasesById = new Map<number, ShiftAliasModel>();

  public holidays: HolidayModel[] = [];
  public vacayLockedDays = new Set();
  public commentsByDate = new Map<string, CommentModel[]>();

  /**
   * Add schedule day
   *
   * @param {Day} targetDay
   */
  public addDay(targetDay: Day) {
    const nearDayIndex = lower(this.days, targetDay.date.getTime(), day => day.date.getTime());

    this.days.splice(nearDayIndex, 0, targetDay);
    this.mapDaysByDate.set(targetDay.keyDate, targetDay);
  }

  /**
   * Add health group
   *
   * @param {HealthGroup} targetHealthGroup
   */
  public addHealthGroup(targetHealthGroup: HealthGroup) {
    const nearHealthGroupIndex = lower(this.healthGroups, targetHealthGroup.dateFrom.getTime(), group => group.dateFrom.getTime());

    this.healthGroups.splice(nearHealthGroupIndex, 0, targetHealthGroup);
  }

  /**
   * Remove health group
   *
   * @param {HealthGroup} targetHealthGroup
   */
  public removeHealthGroup(targetHealthGroup: HealthGroup) {
    this.healthGroups.splice(targetHealthGroup.index, 1);
  }

  /**
   * Add aliases
   *
   * @param {ShiftAliasModel[]} aliases
   */
  public addAliases(aliases: ShiftAliasModel[]) {
    aliases.forEach((alias: ShiftAliasModel) => {
      switch (alias.type) {
        case VacationShiftAlias.DayOff:
          this.dayOffAlias = alias;
          break;

        case VacationShiftAlias.Vacation:
          this.vacationAlias = alias;
          break;

        default:
          this.aliases.push(alias);
          break;
      }

      this.mapAliasesById.set(alias.id, alias);
    });

    if (this.dayOffAlias) {
      this.aliases.push(this.dayOffAlias);
    }
  }

  /**
   * Add statistic
   *
   * @param {VacayRequestStatisticModel} statistic
   */
  public addStatistic(statistic: VacayRequestStatisticModel) {
    const keyDate = statistic.date.format(keyFormatFromDate);
    this.mapStatisticByDate.set(keyDate, statistic);
  }

  /**
   * Add masterplan activity
   *
   * @param {MasterplanActivity} masterplan
   */
  public addMasterplan(masterplan: MasterplanActivity) {
    const keyId = masterplan.id;
    this.mapMasterplanById.set(keyId, masterplan);

    const keyDate = masterplan.model.iDate.date.format(keyFormatFromDate);
    this.mapMasterplanByDate.set(keyDate, masterplan);
  }

  /**
   * Add vacay request
   *
   * @param {VacayRequestActivity} targetRequest
   */
  public addVacayRequest(targetRequest: VacayRequestActivity) {
    const keyId = targetRequest.id;
    this.mapVacayRequestsById.set(keyId, targetRequest);

    const keyDate = targetRequest.model.iDate.date.format(keyFormatFromDate);
    this.mapVacayRequestsByDate.set(keyDate, targetRequest);
  }

  /**
   * Add agenda request
   *
   * @param {AgendaRequestActivity} targetRequest
   */
  public addAgendaRequest(targetRequest: AgendaRequestActivity) {
    const keyId = targetRequest.id;
    this.mapAgendaRequestsById.set(keyId, targetRequest);

    const keyDate = targetRequest.model.iDate.date.format(keyFormatFromDate);
    this.mapAgendaRequestsByDate.set(keyDate, targetRequest);
  }

  /**
   * Delete vacay request
   *
   * @param {VacayRequestActivity} targetRequest
   */
  public deleteVacayRequest(targetRequest: VacayRequestActivity) {
    const keyId = targetRequest.id;
    this.mapVacayRequestsById.delete(keyId);

    const keyDate = targetRequest.model.iDate.date.format(keyFormatFromDate);
    this.mapVacayRequestsByDate.delete(keyDate);

    targetRequest.remove();
  }

  public addCommentByDay(comment: CommentModel): string {
    const keyDate = comment.correspondingDay.replace('-', '').replace('-', '');
    let existingValue = this.commentsByDate.get(keyDate);
    if (!existingValue) {
      existingValue = [];
      this.commentsByDate.set(keyDate, existingValue);
    }

    existingValue.push(comment);
    return keyDate;
  }
}

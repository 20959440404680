import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import Chart, { ChartData } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

@Component({
  selector: 'app-donut-chart-widget',
  templateUrl: './donut-chart-widget.component.html',
  styleUrls: ['./donut-chart-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonutChartWidgetComponent implements AfterViewInit {
  @Input()
  title: string;

  @Input()
  uniqueId: string;

  @Input()
  subtitle: string;

  _donutData: ChartData<'doughnut'>;
  get donutData() {
    return this._donutData;
  }

  @Input()
  set donutData(value) {
    this._donutData = value;
    if (this.initialized) {
      this.plotCharts();
    }
  }

  private initialized = false;

  activityChart: Chart<'doughnut'>;

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.initialized = true;
    this.plotCharts();
  }

  private plotCharts() {
    if (this.activityChart) {
      this.activityChart.destroy();
    }
    if (this.donutData) {
      this.activityChart = new Chart(this.uniqueId, {
        type: 'doughnut',
        data: this.donutData,
        options: {
          plugins: {
            legend: {
              position: 'bottom'
            },
            datalabels: {
              anchor: 'center',
              backgroundColor: null,
              formatter: Math.round,
              color: 'black',
              font: {
                weight: 'bold',
                size: 16
              },
              display(context) {
                return (context.dataset.data[context.dataIndex] as number) > 0;
              }
            }
          },
          responsive: true,
          cutout: 100
        }
      });
    }
    this.changeDetector.markForCheck();
  }
}

import { Subject, merge, fromEvent, of } from 'rxjs';
import { EventTargetLike } from 'rxjs/internal/observable/fromEvent';
import { flatMap, delay, takeUntil } from 'rxjs/operators';

export class Clicker {
  public pressDown;
  public pressUp;

  public click = new Subject<MouseEvent>();
  public longClick = new Subject<MouseEvent>();

  private _longClickDetected = false;
  private onDestroy = new Subject<any>();

  constructor(public target: EventTargetLike<MouseEvent>) {
    /**
     * Subscribe to dom events
     */
    const mouseDown = fromEvent(target, 'mousedown');
    const mouseUp = fromEvent(target, 'mouseup');
    const touchDown = fromEvent(target, 'touchstart');
    const touchUp = fromEvent(target, 'touchend');

    /**
     * Merge events from desktop and touch devices
     */
    this.pressDown = merge<MouseEvent>(mouseDown, touchDown);
    this.pressUp = merge<MouseEvent>(mouseUp, touchUp);

    /******************* Long click ******************/
    this.pressDown
      .pipe(
        flatMap(e =>
          of(e)
            .pipe(delay(700))
            .pipe(takeUntil(this.pressUp))
        )
      )
      .pipe(takeUntil(this.onDestroy))
      .subscribe(e => {
        e.preventDefault();
        this._longClickDetected = true;
        this.longClick.next(e);
      });

    /******************* Simple click ******************/
    this.pressUp.pipe(takeUntil(this.onDestroy)).subscribe(e => {
      e.preventDefault();
      if (this._longClickDetected) {
        this._longClickDetected = false;
        return;
      }
      this.click.next(e);
    });
  }

  public destroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}

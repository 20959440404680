import * as d3 from 'd3';

import { translate, textEllipsis } from '@shared/helpers/d3';
import { VacayAgendaRequestModel } from '@shared/models';

import { ScheduleTimeline } from '../timeline';
import { Activity } from './activity';


export class AgendaRequestActivity extends Activity<VacayAgendaRequestModel> {

  protected $code: d3.Selection<SVGTextElement, any, any, any>;

  constructor(model: VacayAgendaRequestModel, timeline: ScheduleTimeline) {
    super(model, timeline);

    this.$el.attr('class', 'agenda-request');
    this.$code = this.$el.append<SVGTextElement>('text')
      .attr('class', 'request-code');
  }

  /**
   * Render agenda request
   */
  public render() {
    const cellSize = this.sizes.cell;
    const offset = cellSize * .1;
    const size = cellSize - offset * 2;
    const halfSize = size / 2;

    this.$el
      .attr('transform', translate(offset, offset));

    this.$code
      .text(this.model.code)
      .each(textEllipsis(size))
      .attr('x', halfSize)
      .attr('y', halfSize)
      .attr('dy', '0.5ex');
  }

  get isDayOff() {
    return this.model.shiftAlias.isDayOff || this.model.shiftAlias.isVacation;
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { REQUIRED_KEY } from '@app/rostr/_shared/consts/map-keys.const';
import { AuthenticationService } from '@auth';
import { ConversationPanelComment } from '@component/conversation-panel/models/comment.model';
import { CommentsAPIService } from '@component/conversation-panel/services/comments-api.service';
import { norwegianTextComparer } from '@helpers';
import { ScheduleState } from '@model/schedules/schedule-state.model';
import { gridRowHeight } from '@rostr/constants';
import { ScheduleApiService } from '@rostr/overview/api/services/schedule-api.service';
import { ScheduleEventsService } from '@rostr/overview/api/services/schedule-events.service';
import { AssignedActivity, AssignedEmployee, EmployeeFilter, OverviewColumn, OverviewMonth, RequiredShift, ScheduleDto, Timeline } from '@rostr/overview/dto';
import { ConcurrentUserActionData, ConcurrentUserActionType } from '@rostr/overview/dto/concurrent-user-action-data';
import { Group } from '@rostr/overview/dto/group';
import { AvaiFacade } from '@rostr/overview/facades/avai.facade';
import { RostrStatisticsFacade } from '@rostr/overview/facades/rostr-statistics.facade';
import { ShiftsFacade } from '@rostr/overview/facades/shifts.facade';
import { StatisticsFacade } from '@rostr/overview/facades/statistics.facade';
import { ValidationsFacade } from '@rostr/overview/facades/validations.facade';
import { getSortingFunction } from '@rostr/overview/helpers/sorting-functions';
import {
  AssignmentEvent,
  ConcurrentUserSpottedEvent,
  EmployeeColumnInvalidatedEvent,
  EmployeeColumnInvalidationService
} from '@rostr/overview/services/employee-column-invalidation.service';
import { GridSelectionState } from '@rostr/overview/state/grid-selection.state';
import { KeyboardState } from '@rostr/overview/state/keyboard.state';
import { RostrState } from '@rostr/overview/state/rostr.state';
import { ShiftsState } from '@rostr/overview/state/shifts.state';
import { RequiredShiftsPendingChanges } from '@shared/enums/required-shift-pending-changes.enum';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, take, takeUntil, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RostrFacade implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private readonly shiftsFacade: ShiftsFacade,
    private readonly rostrState: RostrState,
    private readonly shiftsState: ShiftsState,
    private readonly validationsFacade: ValidationsFacade,
    private readonly gridSelectionState: GridSelectionState,
    private readonly scheduleApi: ScheduleApiService,
    private readonly avaiFacade: AvaiFacade,
    private readonly rostrStatisticsFacade: RostrStatisticsFacade,
    private readonly statisticsFacade: StatisticsFacade,
    private readonly commentsApi: CommentsAPIService,
    private readonly cellsChangesNotificationService: EmployeeColumnInvalidationService,
    private readonly authService: AuthenticationService,
    private readonly scheduleEventsService: ScheduleEventsService,
    private readonly keyboardState: KeyboardState
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isGridLoading$: Observable<boolean> = this.rostrState.isGridLoading$;
  isScheduleLocked$: Observable<boolean> = this.avaiFacade.isJobRunning$;
  schedule$: Observable<ScheduleDto> = this.rostrState.scheduleModel$;
  scheduleState$: Observable<ScheduleState> = this.rostrState.scheduleState$;
  unassignedShiftsLength$: Observable<number> = this.rostrState.unassignedShiftsLength$;
  unassignedShiftsHeight$: Observable<number> = this.rostrState.unassignedShiftsLength$.pipe(map((length: number) => gridRowHeight * (length + 1)));
  months$: Observable<OverviewMonth[]> = this.rostrState.months$;
  columns$: Observable<OverviewColumn[]> = this.rostrState.columns$;
  groups$: Observable<Group[]> = this.rostrState.groups$;
  skills$: Observable<string[]> = this.rostrState.skills$;
  isUnassignedShiftsExpanded$: Observable<boolean> = this.rostrState.isUnassignedShiftsExpanded$;
  unassignedShiftsLocked$: Observable<boolean> = this.rostrState.unassignedShiftsLocked$;
  activeColumnId$: Observable<number> = this.rostrState.isCurrentActiveColumnId$;
  isEmployeeListLoading$: Observable<boolean> = this.rostrState.isEmployeeListLoading$;
  shiftsFilter$: Observable<string> = this.rostrState.shiftsFilter$;
  employeeFilter$: Observable<EmployeeFilter> = this.rostrState.employeeFilter$;
  scheduleAssignedEmployees$: Observable<AssignedEmployee[]> = combineLatest([
    this.rostrState.scheduleAssignedEmployees$,
    this.rostrState.employeeSortingMode$,
    this.rostrState.employeeSortingAscending$,
    this.rostrState.employeeFilter$
  ]).pipe(
    map(([employees, mode, ascending, employeeFilter]) => {
      return employees
        .filter(employee => !employeeFilter?.groups.length || employeeFilter.groups.find(group => employee.groups.find(x => x.name === group)))
        .filter(employee => !employeeFilter?.skills.length || employeeFilter.skills.every(skill => employee.skills.includes(skill)))
        .sort(getSortingFunction(mode, ascending));
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true
    })
  );

  scheduleAssignedEmployeesNoFilter$ = combineLatest([
    this.rostrState.scheduleAssignedEmployees$,
    this.rostrState.employeeSortingMode$,
    this.rostrState.employeeSortingAscending$
  ]).pipe(
    map(
      ([employees, mode, ascending]) => employees.sort(getSortingFunction(mode, ascending)),
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    )
  );

  scheduleRequiredShifts$: Observable<Map<string, RequiredShift[]>> = combineLatest([this.rostrState.requiredShifts$, this.rostrState.employeeFilter$]).pipe(
    map(([requiredShifts, employeeFilter]) => {
      const filtered = new Map<string, RequiredShift[]>();
      requiredShifts.forEach((v, k) => {
        filtered.set(
          k,
          v.filter(a => (!employeeFilter?.groups.length || employeeFilter.groups.includes(a.group)) && a.pendingChanges !== RequiredShiftsPendingChanges.Insert)
        );
      });
      return filtered;
    })
  );

  dragDisabled$ = combineLatest([
    this.gridSelectionState.selections$.pipe(startWith([])),
    this.keyboardState.isCtrlDown$.pipe(startWith(false)),
    this.rostrState.scheduleState$
  ]).pipe(
    map(([selections, isCtrlDown, scheduleState]) => selections.length > 1 || isCtrlDown || scheduleState === ScheduleState.DISTRIBUTED),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  private onScheduleEventsSub: Subscription;

  public cleanUp(): void {
    this.rostrState.cleanUp();
  }

  public expandUnassignedShifts(expand: boolean): void {
    this.rostrState.expandUnassignedShifts(expand);
  }

  public setUnassignedShiftsLength(timestamp: string, length: number): void {
    this.rostrState.requiredShiftsColumnsLengths.set(timestamp, length);
  }

  public setActiveColumnId(id: number): void {
    this.rostrState.setCurrentActiveColumnIdLoading(id);
  }

  public setEmployeeFilter(employeeFilter: EmployeeFilter): void {
    this.rostrState.setEmployeeFilter(employeeFilter);
    this.gridSelectionState.resetSelections();
  }

  public setShiftsFilter(code: string): void {
    this.rostrState.setShiftsFilter(code.toLowerCase());
  }

  public loadTimeline(timeline: Timeline): void {
    this.rostrState.setMonths(timeline.months);
    this.rostrState.setColumns(timeline.months.flatMap(x => x.columns));
    this.shiftsFacade.setAllTimestamps(this.extractAllTimeStamps(timeline));
  }

  public toggleUnassignedLock(): void {
    this.rostrState.toggleUnassignedLock();
  }

  public loadSchedule(scheduleId: number): void {
    this.scheduleApi
      .getSchedule(scheduleId)
      .pipe(take(1))
      .subscribe(schedule => {
        this.rostrState.setSchedule(schedule);

        const assignedEmployees = schedule.assignedEmployees.map(employee => new AssignedEmployee(employee));

        this.rostrState.setAssignedEmployees(assignedEmployees);

        const assignedActivities = assignedEmployees.flatMap(employee => employee.assigned);
        this.populateRequiredShiftsContainers(schedule.requiredShifts, assignedActivities);
        this.rostrState.setGroups(schedule.groups);

        const uniqueSkills = [...new Set(assignedEmployees.flatMap(employee => employee.skills))].sort((a, b) => norwegianTextComparer(a, b, true));
        this.rostrState.setSkills(uniqueSkills);

        for (const employee of assignedEmployees) {
          this.shiftsFacade.initializeAllStatuses(employee);
        }

        this.rostrState.setIsGridLoading(false);
        this.rostrState.setIsEmployeeListLoading(false);

        this.avaiFacade.watch(schedule);

        schedule.assignedEmployees.forEach(employee => {
          this.rostrState.detailsVisibilityEntries.set(employee.id, false);
          this.rostrState.isBeingUsedByConcurrentUserEntries.set(employee.id, new Map<string, ConcurrentUserActionData>());
        });
        this.rostrState.isBeingUsedByConcurrentUserEntries.set(REQUIRED_KEY, new Map<string, ConcurrentUserActionData>());

        this.validationsFacade.setValidations(schedule.validations);
        this.statisticsFacade.setStatistics(schedule.statistics);

        if (this.onScheduleEventsSub) {
          this.onScheduleEventsSub.unsubscribe();
        }
        this.onScheduleEventsSub = this.scheduleEventsService
          .getScheduleEvents(schedule.id)
          .pipe(
            takeUntil(this.destroy$),
            filter(e => e.type === 'scheduleStateChanged')
          )
          .subscribe(r => this.rostrState.updateScheduleState(r.payload.scheduleStateChanged.state));
      });
  }

  public reloadSchedule(): void {
    this.avaiFacade.stopWatching();
    this.rostrState.setIsGridLoading(true);
    this.rostrStatisticsFacade.cleanUp();

    this.rostrState.scheduleModel$.pipe(take(1)).subscribe(schedule => {
      this.loadSchedule(schedule.id);
    });
  }

  public updateScheduleState(newStage: ScheduleState): void {
    this.rostrState.updateScheduleState(newStage);
  }

  public setGridBusy(): void {
    this.rostrState.setIsGridLoading(true);
  }

  public calculateMaxNumberOfUnassignedShifts(): void {
    let max = 0;

    for (const [, value] of this.rostrState.requiredShiftsColumnsLengths) {
      if (value > max) {
        max = value;
      }
    }

    this.rostrState.setMaxNumberOfUnassignedShifts(max);
  }

  toggleRow(employeeId: number): void {
    this.rostrState.detailsVisibilityEntries.set(employeeId, !this.rostrState.detailsVisibilityEntries.get(employeeId));

    this.rostrState.notifyRowExpandedOrCollapsed();
  }

  expandAllRows(): void {
    Array.from(this.rostrState.detailsVisibilityEntries.keys()).forEach(key => {
      this.rostrState.detailsVisibilityEntries.set(key, true);
    });

    this.rostrState.notifyRowExpandedOrCollapsed();
  }

  collapseAllRows(): void {
    Array.from(this.rostrState.detailsVisibilityEntries.keys()).forEach(key => {
      this.rostrState.detailsVisibilityEntries.set(key, false);
    });

    this.rostrState.notifyRowExpandedOrCollapsed();
  }

  public unassignActivity(evt: AssignmentEvent) {
    this.rostrState.unassignActivity(evt);
    this.updateActivityState(evt);
  }

  public upsertActivity(evt: AssignmentEvent, isNewAssignment = true) {
    this.rostrState.upsertActivity(evt);
    if (isNewAssignment) {
      this.updateActivityState(evt);
    }
  }

  private updateActivityState(evt: AssignmentEvent) {
    const employee = this.rostrState.getAssignedEmployee(evt.employeeId);
    this.shiftsFacade.calculateStateForGivenTimestamp(employee, evt.timestamp);
    this.rostrStatisticsFacade.updateStatisticForEmployees([employee.id]);
  }

  private populateRequiredShiftsContainers(requiredShifts: RequiredShift[], assignedActivities: Map<string, AssignedActivity>[]): void {
    this.rostrState.columns$.pipe(take(1)).subscribe(columns => {
      let assignedActivitiesParentIds = [];
      const rsMap = new Map<string, RequiredShift[]>();

      columns.forEach(column => {
        rsMap.set(column.timestamp, []);
      });

      assignedActivities
        .filter(assignedActivity => !!assignedActivity)
        .forEach(assignedActivityMap => {
          const parentIds = Array.from(assignedActivityMap.values()).map(element => element.parentId);
          assignedActivitiesParentIds = [...assignedActivitiesParentIds, ...parentIds];
        });

      requiredShifts.forEach(requiredShift => {
        requiredShift.isAssigned = assignedActivitiesParentIds.some(parentId => parentId === requiredShift.id);

        rsMap.get(requiredShift.timestamp).push(requiredShift);
      });

      this.calculateUnassignedShiftsLength([...rsMap.values()]);
      this.rostrState.setRequiredShifts(rsMap);
    });
  }

  private calculateUnassignedShiftsLength(requiredShifts: RequiredShift[][]): void {
    const lengths = requiredShifts.map(rs => rs.length);
    const maxLength = Math.max(...lengths);
    this.rostrState.setMaxNumberOfUnassignedShifts(maxLength);
  }

  private extractAllTimeStamps(timeline: Timeline) {
    return timeline.months.flatMap(month => month.columns.filter(column => !column.isHours)).map(column => column.timestamp);
  }

  newCommentNotifications$ = () =>
    combineLatest([this.rostrState.scheduleAssignedEmployees$, this.commentsApi.onCommentsCreated$()]).pipe(
      tap(([employees, comments]) => {
        comments.forEach(comment => {
          const employee = employees.find(e => e.id === comment.employeeId);
          if (employee && this.shiftsState.allTimestamps.find(x => x === comment.timestamp)) {
            if (employee.comments.get(comment.timestamp)) {
              employee.comments.get(comment.timestamp).push(comment);
            } else {
              employee.comments.set(comment.timestamp, [comment]);
            }
            this.triggerCommentsInvalidations(comment, ConcurrentUserActionType.Create);
          }
        });
      })
    );

  updatedCommentNotifications$ = () =>
    combineLatest([this.rostrState.scheduleAssignedEmployees$, this.commentsApi.onCommentUpdated$()]).pipe(
      tap(([employees, comment]) => {
        const employee = employees.find(e => e.id === comment.employeeId);
        if (employee && employee.comments.get(comment.timestamp)) {
          const commentToUpdate: ConversationPanelComment = employee.comments.get(comment.timestamp).find(c => c.id === comment.id);
          if (commentToUpdate) {
            commentToUpdate.message = comment.message;
            commentToUpdate.isRead = comment.isRead;
            this.triggerCommentsInvalidations(comment, ConcurrentUserActionType.Update);
          }
        }
      })
    );

  deletedCommentNotifications$ = () =>
    combineLatest([this.rostrState.scheduleAssignedEmployees$, this.commentsApi.onCommentDeleted$()]).pipe(
      tap(([employees, comment]) => {
        const employee = employees.find(e => e.id === comment.employeeId);
        if (employee && employee.comments.get(comment.timestamp)) {
          const commentToDelete: ConversationPanelComment = employee.comments.get(comment.timestamp).find(c => c.id === comment.id);
          if (commentToDelete) {
            employee.comments.set(
              comment.timestamp,
              employee.comments.get(comment.timestamp).filter(c => c.id !== comment.id)
            );
            this.triggerCommentsInvalidations(comment, ConcurrentUserActionType.Delete);
          }
        }
      })
    );

  liveUpdates$() {
    return combineLatest([this.newCommentNotifications$(), this.updatedCommentNotifications$(), this.deletedCommentNotifications$()]);
  }

  private triggerCommentsInvalidations(comment: ConversationPanelComment, type: ConcurrentUserActionType): void {
    this.cellsChangesNotificationService.invalidateEmployeeColumn(new EmployeeColumnInvalidatedEvent(comment.timestamp, comment.employeeId));
    if (comment.authorId !== this.authService.user.id) {
      const data = new ConcurrentUserActionData(null, type, 'edit');
      const evt = new ConcurrentUserSpottedEvent(comment.timestamp, comment.employeeId, data);
      this.cellsChangesNotificationService.indicatedConcurrentUserSpotted(evt);
    }
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-capybara',
  templateUrl: './capybara.component.html',
  styleUrls: ['./capybara.component.scss']
})
export class CapybaraComponent {
  showAnimation = false;

  start(): void {
    const eatableClass = 'filled';
    this.showAnimation = true;
    let counter = 0;

    // removes scrollbar triggered by capybara
    const contentWrapper = document.getElementsByClassName('content-wrapper') as HTMLCollectionOf<HTMLElement>;
    contentWrapper[0].style.overflow = 'hidden';

    setTimeout(() => {
      const capybara = document.getElementById('capybara');
      const eaten = document.getElementById('counter');
      const eatableElements = document.getElementsByClassName(eatableClass) as HTMLCollectionOf<HTMLElement>;
      const eatableArray: HTMLElement[] = Array.from(eatableElements);

      const interval = setInterval(() => {
        const pos = eatableArray[counter].getBoundingClientRect();
        capybara.style.left = `${pos.left + 60}px`;
        capybara.style.top = `${pos.top}px`;

        const newHeight = 50 + counter / 2;
        capybara.style.height = `${newHeight}px`;
        capybara.style.width = `${newHeight}px`;

        eatableArray[counter].classList.remove('faded-out');
        eatableArray[counter].classList.add('faded-out');

        counter++;
        eaten.innerText = `${counter}`;
        if (counter === eatableArray.length) {
          clearInterval(interval);
          this.showAnimation = false;
        }
      }, 500);
    }, 0);
  }
}

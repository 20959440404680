export interface ShiftCodeDetails {
  id?: number;
  start: number;
  duration: number;
  workingTimeInMinutes: number;
  workingTimePercentage: number;
  typeId: number;
  type?: {
    id: number;
    name: string;
  };
}

<div class="menu-container">
  <span *ngFor="let circle of circlesData"
        (click)="select(circle)"
        [ngClass]="{'disabled': circle.disabled}"
        [class]="circle.activeClass"
        [style.top]="circle.top"
        [style.left]="circle.left">
    {{circle.activeIcon}}
  </span>
</div>

import { EmployeeModel } from '../auth';
import { RostrRequestBaseModel } from '../rostr/rostr-request-base.model';


export class AgendaActivityModel extends RostrRequestBaseModel {

  public employee: EmployeeModel;

  constructor(data: any = {}) {
    super(data);

    this.employee = (data.employee && new EmployeeModel(data.employee)) || undefined;
  }

  get duration() {
    return this.dateTo.diff(this.dateFrom, 'minutes');
  }

  get group() {
    return this.employee && this.employee.group || undefined;
  }

}

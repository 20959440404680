export class VacayStatistics {
  public requests: number;
  public agendaOverrides: number;
  public satisfied: number;

  constructor(data: any = {}) {
    this.requests = data.requests || 0;
    this.agendaOverrides = data.agendaOverrides || 0;
    this.satisfied = data.satisfied || 0;
  }
}

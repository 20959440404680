import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'shColorName'})

export class ColorNamePipe implements PipeTransform {
  transform(value, secondValue) {
    return value
      .replace(/([A-Z])/g, ' $1')
      .replace(/color /, '');
      // uppercase the first character
      // .replace(/^./, function(str){ return str.toUpperCase(); });
  }
}

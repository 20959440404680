import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-number-with-progress-bar-widget',
  templateUrl: './number-with-progress-bar-widget.component.html',
  styleUrls: ['./number-with-progress-bar-widget.component.scss']
})
export class NumberWithProgressBarWidgetComponent {
  @Input()
  title = '';

  @Input()
  subtitle = '';

  @Input()
  progressBarValue;

  @Input()
  value = 0;

  @Input()
  color: 'red' | 'green' | 'yellow' | '' = '';
}

import { Option } from './../interfaces/options.interface';

export class QualificationsOptions {
    public skills: Option[] = [];
    public licenses: Option[] = [];
    public ratings: Option[] = [];
    public unitEndorsements: Option[] = [];
    public ratingEndorsements: Option[] = [];
    public licensEndorsements: Option[] = [];
    public certificates: Option[] = [];
}
import { EventEmitter } from '@angular/core';
import * as d3 from 'd3';
import { CountableTimeInterval } from 'd3-time';
import * as moment from 'moment-mini';

import { translate, xmlns } from '@helpers';

import { Grid } from '../grid/grid';
import { Timeline } from '../../basetimeline/timeline';
import { BaseItem } from '../items/base.item';
import { ShiftsBasedItem } from '../items/shifts-based.item';
import { IShift } from '../shifts/base.shift';


export interface IBrushConfig {
  startDay?: number | null
  endDay?: number | null
  shortDateFormat?: string
  fullDateFormat?: string
  tickFunction?: CountableTimeInterval
  tickInterval?: number
  yPos?: number
  leftLimit?: number
  rightLimit?: number
}

export class Brush {

  public onDragStart = new EventEmitter<any>();
  public onDragEnd = new EventEmitter<any>();

  protected el: Element;
  protected $el: d3.Selection<any, any, any, any>;

  protected x: number;
  protected x2: number;
  protected localWwidth: number;
  protected shiftMargin: number;

  protected $leftTime: d3.Selection<any, any, any, any>;
  protected $leftTimeBg: d3.Selection<any, any, any, any>;
  protected $leftTimeText: d3.Selection<any, any, any, any>;

  protected $rightTime: d3.Selection<any, any, any, any>;
  protected $rightTimeBg: d3.Selection<any, any, any, any>;
  protected $rightTimeText: d3.Selection<any, any, any, any>;

  protected $background: d3.Selection<any, any, any, any>;
  protected $leftCircle: d3.Selection<any, any, any, any>;
  protected $rightCircle: d3.Selection<any, any, any, any>;

  protected dragLeftTag: d3.DragBehavior<any, any, any>;
  protected dragRightTag: d3.DragBehavior<any, any, any>;

  protected scale = d3.scaleTime();

  protected sizes = {
    circleRadius: 11,
    timeTooltipHeight: 18,
    timeTooltipBgRadius: 3,
    timeTooltipTextOffsetY: 13,
    height: 0,
    offset: 0
  };

  protected config: IBrushConfig = {
    startDay: null,
    endDay: null,
    shortDateFormat: 'HH:mm',
    fullDateFormat: 'D MMM HH:mm',
    tickFunction: d3.timeMinute,
    tickInterval: 30,
    yPos: 0
  };

  protected ticksPositionsList: number[] = [];

  protected endPosition: number;

  protected tagsPositions = {
    left: 0,
    right: 0
  };

  constructor(protected startPosition: number,
    protected item: BaseItem,
    protected timeline: Timeline,
    protected grid: Grid,
    endPosition = 0,
    config: IBrushConfig = null) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).classed('task', true);

    // this._endPosition = endPosition || this._grid.nextInterval(_startPosition);

    this.endPosition = endPosition || this.findRightNearPosition(startPosition);
    if (this.item) {
      this.config.yPos = this.item.y();
    }
    this.updateConfig(config);

    this.dragLeftTag = d3.drag();
    this.dragRightTag = d3.drag();

    this.events();
  }

  /**
   * Render brush
   * @param startPosition
   * @param endPosition
   * @param item
   * @param config
   */
  public render(startPosition = 0,
    endPosition = 0,
    item: BaseItem = null,
    config: IBrushConfig = null) {

    if (item) {
      this.item = item;
    }

    this.config.yPos = this.item.y();
    // update config (merge)
    this.updateConfig(config);

    // destroy previous brush
    this.destroy();

    this.scaleInit();
    // init basic elements
    this.initElements(startPosition, endPosition, item);

    // Render

    this.initSizes();

    const pos = this.findLeftNearPosition(startPosition);

    this.$el.attr('transform', translate(pos, this.config.yPos));

    this.$leftCircle.attr('cx', 1).attr('cy', this.sizes.height / 2 + this.sizes.offset);
    this.$leftCircle.call(this.dragLeftTag);

    this.$rightCircle.attr('cx', this.localWwidth + this.shiftMargin).attr('cy', this.sizes.height / 2 + this.sizes.offset);
    this.$rightCircle.call(this.dragRightTag);

    this.tagsPositions.left = this.x;
    this.tagsPositions.right = this.localWwidth + this.shiftMargin;

    this.$background.classed('brush-background', true)
      .attr('height', this.sizes.height)
      .attr('y', this.sizes.offset)
      .attr('x', this.shiftMargin)
      .attr('width', this.localWwidth)
      .attr('fill', '#fff')
      .attr('rx', 3)
      .attr('ry', 3);

    /**
     * Append to system container
     */
    this.timeline.overMenuSysContainer.node().appendChild(this.el);

    // Init of scale for ticks
    // this.scaleInit();

    const startTime = this.scale.invert(this.startPosition).getTime();
    const endTime = this.scale.invert(this.endPosition).getTime();

    this.config.startDay = moment(startTime).dayOfYear();
    this.config.endDay = moment(endTime).dayOfYear();

    // Create and move tooltips to their positions
    this.moveTooltip(this.$leftTime, this.$leftTimeBg, this.$leftTimeText, this.tagsPositions.left, this.findLeftNearTick);
    this.moveTooltip(this.$rightTime, this.$rightTimeBg, this.$rightTimeText, this.tagsPositions.right, this.findRightNearTick);
  }

  /**
   * Destroy brush
   */
  public destroy() {
    this.startPosition = null;
    this.endPosition = null;

    this.ticksPositionsList.length = 0;

    this.$el.selectAll('*').remove();
    this.$el.node().remove();
  }

  /**
   * Events for drag and any events
   */
  public events() {
    this.dragLeftTag.on('start', () => this.onDragStart.emit());
    this.dragRightTag.on('start', () => this.onDragStart.emit());

    this.dragLeftTag.on('drag', () => this.moveLeft(d3.event.dx));
    this.dragRightTag.on('drag', () => this.moveRight(d3.event.dx));

    this.dragLeftTag.on('end', () => this.onDragEnd.emit(this.brushResult()));
    this.dragRightTag.on('end', () => this.onDragEnd.emit(this.brushResult()));
  }

  /**
   * Initialize scale line and ticks position list
   */
  public scaleInit() {
    const sizes = this.timeline.sizes();

    const intervals = this.grid.intervals();
    const firstInterval = intervals[0];
    const lastInterval = intervals[intervals.length - 1];

    this.scale.domain([firstInterval.dateFrom(), lastInterval.dateTo()])
      .range([firstInterval.x(), lastInterval.x() + sizes.intervalWidth]);

    const ticks = this.scale.ticks(this.config.tickFunction.every(this.config.tickInterval));
    ticks.forEach((tick) => {
      this.ticksPositionsList.push(Math.round(this.scale(tick)));
    });
  }

  /**
   * Move left tag
   * @param dx
   */

  public moveLeft(dx) {
    const circle = this.$leftCircle;
    const x = this.x + d3.event.dx;

    const nearPos = this.findLeftNearPosition(this.startPosition + x);
    const newX = nearPos - this.startPosition;

    const intervals = this.grid.intervals();
    const intervalAfterMenu = intervals[this.grid.countIntervalsUnderMenu()];

    if (
      this.tagsPositions.right - newX + this.shiftMargin < 10
      || (this.config.leftLimit && nearPos < this.config.leftLimit)
      || (intervalAfterMenu && nearPos < intervalAfterMenu.x())
    ) {
      return;
    }

    if (this.tagsPositions.left !== newX) {

      this.tagsPositions.left = newX + this.shiftMargin;

      circle.attr('cx', this.tagsPositions.left);

      this.$background
        .attr('x', this.tagsPositions.left)
        .attr('width', this.tagsPositions.right - this.tagsPositions.left);

      this.moveTooltip(
        this.$leftTime,
        this.$leftTimeBg,
        this.$leftTimeText,
        this.tagsPositions.left,
        this.findLeftNearTick,
      );
    }

    this.x = x;
  }

  /**
   * Move right tag
   * @param dx
   */
  public moveRight(dx) {
    const circle = this.$rightCircle;
    const x = this.x2 + dx;

    const nearPos = this.findRightNearPosition(this.startPosition + x);
    const newX = nearPos - this.startPosition;

    if (newX + this.shiftMargin - this.tagsPositions.left < 10
      || (this.config.rightLimit && nearPos > this.config.rightLimit)) { return; }

    if (+circle.attr('cx') !== newX) {

      this.tagsPositions.right = newX - this.shiftMargin;

      circle.attr('cx', this.tagsPositions.right);
      this.$background.attr('width', this.tagsPositions.right - this.tagsPositions.left);
      this.moveTooltip(this.$rightTime, this.$rightTimeBg, this.$rightTimeText, this.tagsPositions.right, this.findRightNearTick);
    }

    this.x2 = x;
  }

  /**
   * Width of task in pixes
   * @returns {number}
   */
  public width() {
    return this.endPosition - this.startPosition;
  }

  protected initSizes() {
    const sizes = this.timeline.sizes();
    const rh = this.item.config.height;
    const shiftMargin = sizes.shiftMargin;
    let width = Math.round(this.width() - shiftMargin * 2); // fixme any ideas ?

    this.sizes.height = rh * 0.825;
    this.sizes.offset = rh * 0.0925;


    if (width < 0) {
      width = 0;
    }

    this.shiftMargin = shiftMargin;
    this.x = shiftMargin;
    this.localWwidth = width;
    this.x2 = width + shiftMargin;
  }

  /**
   * Initialize of elements
   * @param startPosition
   * @param endPosition
   * @param item
   */
  protected initElements(startPosition, endPosition, item) {
    this.startPosition = this.findLeftNearPosition(startPosition);
    this.endPosition = (endPosition) ? this.findRightNearPosition(endPosition) : this.findRightNearPosition(this.startPosition);

    this.item = item || this.item;

    this.$background = this.$el.append('rect');

    // Circles Init
    this.$leftCircle = this.$el.append('circle')
      .classed('circle-tag', true)
      .attr('r', this.sizes.circleRadius);

    this.$rightCircle = this.$el.append('circle')
      .classed('circle-tag', true)
      .attr('r', this.sizes.circleRadius);

    // Tooltips init
    this.initLeftTextTooltips();
    this.initRightTextTooltips();
  }

  /**
   * Init tooltip groups
   */
  protected initLeftTextTooltips() {
    this.$leftTime = this.$el.append('g')
      .classed('time-places', true);
    this.$leftTimeBg = this.$leftTime.append('rect')
      .attr('height', 18)
      .attr('rx', 3)
      .attr('ry', 3);
    this.$leftTimeText = this.$leftTime.append('text').attr('y', 13);
  }

  /**
   * Init tooltip groups
   */
  protected initRightTextTooltips() {
    this.$rightTime = this.$el.append('g')
      .classed('time-places', true);
    this.$rightTimeBg = this.$rightTime.append('rect')
      .attr('height', 18)
      .attr('rx', 3)
      .attr('ry', 3);
    this.$rightTimeText = this.$rightTime.append('text').attr('y', 13);
  }

  /**
   * Looking near tick from left-hand-side
   * @param position - position by x
   * @returns {any}
   */
  protected findLeftNearTick(position) {
    const ticks = this.scale.ticks(this.config.tickFunction.every(this.config.tickInterval));

    let result = null;
    ticks.forEach((tick) => {
      const pos = this.scale(tick);

      if (pos <= position) {
        result = tick;
      }
    });
    return result;
  }

  /**
   * Looking near tick from right-hand-side
   * @param position
   * @returns {any}
   */
  protected findRightNearTick(position) {
    const ticks = this.scale.ticks(this.config.tickFunction.every(this.config.tickInterval));

    let result = null;
    ticks.reverse().forEach((tick) => {
      const pos = this.scale(tick);

      if (pos >= position) {
        result = tick;
      }
    });
    return result;
  }

  /**
   * Looking left-hand-side near position (for ticks)
   * @param position
   * @returns {any}
   */
  protected findLeftNearPosition(position) {
    let result = null;
    this.ticksPositionsList.forEach((pos) => {
      if (pos <= position) {
        result = pos;
      }
    });

    return result;
  }

  /**
   * Looking right-hand-side near position (for ticks)
   * @param position
   * @returns {any}
   */
  protected findRightNearPosition(position) {
    let result = null;
    this.ticksPositionsList.every((pos) => {
      result = pos;
      return pos < position;
    });

    return result;
  }

  /**
   * Overlapping with some shift
   */
  protected overlap() {
    let shifts = [];

    if (this.item instanceof ShiftsBasedItem) {
      shifts = this.item.shifts() as IShift[];
    }

    if (!shifts.length) { return []; }

    const from = this.scale.invert(this.tagsPositions.left + this.startPosition).getTime();
    const to = this.scale.invert(this.tagsPositions.right + this.startPosition).getTime();

    return shifts.reduce((acc, shift) => {
      const start = shift.dateFrom().getTime();
      const end = shift.dateTo().getTime();

      if ((from < end) && (to > start)) {
        acc.push(shift);
      }

      return acc;
    }, []);
  }

  /**
   * Move tooltip and change format if it's needed
   * @param el
   * @param elBg
   * @param elText
   * @param correctPosition
   * @param tickFunction
   */
  protected moveTooltip(el, elBg, elText, correctPosition, tickFunction) {
    const tick = tickFunction.call(this, correctPosition + this.startPosition);

    const currentDate = moment(tick.getTime());
    this.config.startDay = currentDate.dayOfYear();

    let format;

    if (this.config.startDay !== this.config.endDay) {
      format = this.config.fullDateFormat;
    } else {
      format = this.config.shortDateFormat;
    }


    elText.text(moment(tick.getTime()).format(format));
    const tw = elText.node().getBoundingClientRect();
    const w = tw.width + 6;
    elText.attr('x', w / 2);
    elBg.attr('width', w);

    el.attr('transform', translate(-w / 2 + correctPosition, -10));
  }

  /**
   * Merge config
   * @param config
   */
  protected updateConfig(config: IBrushConfig) {
    Object.assign(this.config, config);
  }

  /**
   * Will return selected result
   * @returns {{from: Date, to: Date, overlappedShifts: Array[BaseShifts]}}
   */
  protected brushResult() {
    const from = this.findLeftNearTick(this.tagsPositions.left + this.startPosition);
    const to = this.findRightNearTick(this.tagsPositions.right + this.startPosition);
    return {
      from,
      to,
      overlappedShifts: this.overlap()
    };
  }
}



import { VacayRequestStatisticActivity } from '@shared/models/vacay/vacay-request-statistic-activity';


export class ShiftStatistic {

  protected _statistic = new VacayRequestStatisticActivity();

  set statistic(statistic: VacayRequestStatisticActivity) {
    this._statistic = statistic;
  }

  get coverage() {
    return this._statistic.coverage;
  }
}

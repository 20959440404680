import * as d3 from 'd3';
import { RootItem } from './root.item';
import { OpenableItem } from './openable.item';
import { SimpleItem } from './simple.item';
import { SubItem } from './sub.item';
import { Draggable } from '../draggable/draggable';
import { IItemConfig } from './interfaces';

export class DraggableRootItem extends RootItem {

  protected $drag: d3.Selection<any, any, any, any>;
  protected _drag: d3.DragBehavior<any, any, any>;
  protected _draggable: Draggable;

  constructor(protected _title = '',
              protected _items: (OpenableItem | SimpleItem)[] = [],
              protected _subitems: SubItem[] = [],
              protected _opened = true,
              protected _itemConfig: IItemConfig = { height: 40 }) {
    super(_title, _items, _subitems, _opened, _itemConfig);
    this._drag = d3.drag();
    this.$drag = this.$el.append('rect').classed('drag', true);
  }

  public render() {
    super.render();
    const sizes = this._timeline.sizes();
    const rh = sizes.rowHeight;

    this.$drag.attr('visibility', 'hidden')
      .attr('height', rh)
      .attr('width', rh)
      .attr('fill', this._defs.dottedMask())
      .attr('cursor', 'move')
      .call(this._drag);

    return this.el;
  }

  /**
   * DOM event listeners
   */
  public events() {
    super.events();
    this._drag.on('start', () => {
      this.dragStart();
    });
    this._drag.on('drag', () => {
      this.drag();
    });
    this._drag.on('end', () => {
      this.dragEnd();
    });

    this.$el.on('mouseenter', () => {
      this.$drag.attr('visibility', 'visible');
    });

    this.$el.on('mouseleave', () => {
      this.$drag.attr('visibility', 'hidden');
    });
  }

  /************************************ drag ********************************************/
  protected dragStart() {
    const draggable = this._timeline.draggable();
    draggable.content(this.dragRender());
    draggable.offset.apply(draggable, this.dragOffset());
    draggable.area.apply(draggable, this.dragArea());
    draggable.coords.apply(draggable, this.dragCoords());
    draggable.show()
      .render();
    this._draggable = draggable;
  }

  /**
   * Drag event
   */
  protected drag() {
    this._draggable.moveY(d3.event.dy);
  }

  /**
   * Drag end event
   */
  protected dragEnd() {
    this._draggable.hide();
    this.$el.attr('opacity', 1);
  }

  protected dragRender() {
    const $clone = d3.select<any, any>(this.clone());
    $clone.attr('transform', null);
    return $clone.node();
  }

  protected dragOffset() {
    const sizes = this._timeline.sizes();
    return [0, sizes.headerHeight];
  }

  protected dragArea() {
    const menu = this._menu;
    const items = menu.visible;
    const last = items[items.length - 1];
    return [0, 0, 0, last.y()];
  }

  protected dragCoords() {
    return [0, this.y()];
  }

  protected updateChildIndexes() {
    this.items.forEach((item: any, index) => {
      item.index = index;
    });

    this._onIndexUpdate.next();
  }
}

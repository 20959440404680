import { BaseShift } from '@shared/components/basetimeline';

export class DayOff extends BaseShift {

  constructor(protected _title = '',
              protected _dateFrom: Date,
              protected _dateTo: Date) {
    super(_title, _dateFrom, _dateTo);
  }

  /**
   * Renderin
   * @returns {Element}
   */
  public render() {
    super.render();

    const sizes = this._timeline.sizes();
    const rh = this._item.config.height;
    const shiftMargin = sizes.shiftMargin;
    const width = Math.round(this.width() - shiftMargin * 2); // fixme any ideas ?
    const height = rh * 0.825;  // 33px for 40px of row height
    const offset = rh * 0.0925; // same for offset

    this.$bodyContainer
      .insert('rect', 'text')
      .attr('y', offset)
      .attr('x', shiftMargin)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', this._defs.stripeIncrease());

    return this.el;
  }

}

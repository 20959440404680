import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-page-browser',
  templateUrl: './page-browser.component.html',
  styleUrls: ['./page-browser.component.scss']
})

export class PageBrowserComponent {
  @Input() pageTitle: string;
  @Input() showPageTitle: boolean;
  @Input() buttonText: string;
  @Input() buttonIcon: string;
  @Input() showButton: boolean;
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter();
}

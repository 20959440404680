import { EventEmitter } from '@angular/core';

import { of, Subject, Observable } from 'rxjs';
import { concatMap, onErrorResumeNext } from 'rxjs/operators';



export class QueueBuilder {

  public subject = new Subject();

  public elementsInQueue = 0;

  public finished = new EventEmitter();

  constructor(public delay = 0) {

    // this.finished = Observable();
    this.subject
      .pipe(
        concatMap(this.identity),
        onErrorResumeNext(of('error')))
      .subscribe((data: any) => {
        if (data.data) {
          data.cb(data.data);
        } else {
          data.cb();
        }
        this.elementsInQueue--;

        if (this.elementsInQueue === 0) {
          this.finished.emit('empty');
          this.finished.complete();
        }
      });
  }

  public addToQueue(cb, data = void 0, delay: number = void 0) {
    this.elementsInQueue++;
    return this.subject.next(
      Observable.create((obs) => {
        obs.next({cb, data});
        obs.complete();
      }).delay(delay || this.delay)
    );
  }


  private identity(observable) {
    return observable;
  }
}

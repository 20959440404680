import { UntypedFormGroup } from '@angular/forms';

export function requireValueToBeObject(controlName: string) {
  return (formGroup: UntypedFormGroup) => {
    // considering that object is NOT forecasted we can't use FormGroup here.
    const control = formGroup.controls[controlName];

    if (!control.value || typeof control.value === 'string') {
      control.setErrors({ valueNotObject: true });
    } else {
      control.setErrors(null);
    }
  };
}

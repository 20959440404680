import * as d3 from 'd3';

import { takeUntil } from 'rxjs/operators';
import { translate, xmlns } from '@helpers';
import { ScheduleModel } from '@models';

import { Schedule } from './schedule';
import { IBaseScheduleHeader } from './base-schedule-header.interface';
import { IBaseScheduleRow } from './base-schedule-row.interface';

interface IScheduleRowConfig {
  indicationFillColor: string;
  schedules: ScheduleModel[];
}

export class ScheduleRow implements IBaseScheduleRow {

  get index() {
    return this.schedulesHeader.scheduleRows.indexOf(this);
  }

  get properties() {
    return this._properties;
  }

  get schedules() {
    return this._schedules;
  }

  get currentSchedules() {
    return this._currentSchedules;
  }

  get timeline() {
    return this.schedulesHeader.timeline;
  }

  get grid() {
    return this.schedulesHeader.grid;
  }

  get menu() {
    return this.schedulesHeader.menu;
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.$el.node();
  }

  protected $el: d3.Selection<SVGGElement, any, any, any>;

  protected schedulesByDate = new Map<number, ScheduleModel>();

  private _schedules: Schedule[] = [];
  private _currentSchedules: Schedule[] = [];

  private _properties = {
    yPos: 0,
    height: 0,
    fillColor: void 0,
  };

  constructor(
    protected config: IScheduleRowConfig,
    public readonly schedulesHeader: IBaseScheduleHeader,
  ) {
    this.initElements();
  }

  public init() {
    this.initSchedules();
    this.lookupCurrentSchedules();

    this.schedules.forEach(schedule => schedule.init());
  }

  public render() {
    const { yPos } = this.initProperties();

    this.$el.attr('transform', translate(0, yPos));

    this.schedules.forEach((schedule) => {
      schedule.render();
      this.node.appendChild(schedule.node);
    });
  }

  public subscribe() {
    this.grid.onChangeBase
      .pipe(takeUntil(this.timeline.onDestroy))
      .subscribe(() => this.lookupCurrentSchedules());

    this.schedules.forEach(schedule => schedule.subscribe());
  }

  protected initElements() {
    const el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(el).classed('schedules-header-rows', true);
  }

  protected initProperties() {
    const schedulesHeader = this.schedulesHeader;
    const countRows = schedulesHeader.scheduleRows.length;
    const headerHeight = schedulesHeader.height - 3;
    const properties = this.properties;

    properties.fillColor = this.config.indicationFillColor;
    properties.height = (countRows > 1) ? (headerHeight / countRows) : 24;
    properties.yPos = this.index * properties.height;

    return properties;
  }

  protected initSchedules() {
    this._schedules = this.config.schedules
      .sort((a, b) => a.dateFromUnix - b.dateFromUnix)
      .map((scheduleModel) => {
        const schedule = new Schedule(scheduleModel, this);
        this.initScheduleByDates(scheduleModel);
        return schedule;
      });
  }

  protected initScheduleByDates(scheduleModel: ScheduleModel) {
    let dateUnix = scheduleModel.dateFromUnix;

    while (dateUnix <= scheduleModel.dateToUnix) {
      this.schedulesByDate.set(dateUnix, scheduleModel);
      dateUnix += 86400000; // add 1 day
    }
  }

  protected lookupCurrentSchedules() {
    const intervals = this.grid.allIntervals;

    const dateFrom = intervals[0].dateFromUnix;
    const dateTo = intervals[intervals.length - 1].dateToUnix;

    this._currentSchedules = this.schedules.filter((schedule) => (
      schedule.dateFromUnix <= dateTo && schedule.dateToUnix >= dateFrom
    ));
  }

}

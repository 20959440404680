import * as d3 from 'd3';
import { Subject } from 'rxjs';

import { translate, xmlns } from '@helpers';
import { IOptionsButtonsContainer } from './options-buttons-container.interface';

export class OptionsButton {
  public onClick = new Subject<MouseEvent>();

  private $el: d3.Selection<SVGGElement, any, any, any>;
  private $background: d3.Selection<SVGRectElement, any, any, any>;
  private $shadow: d3.Selection<SVGRectElement, any, any, any>;
  private $title: d3.Selection<SVGTextElement, any, any, any>;
  private $icon: d3.Selection<SVGTextElement, any, any, any>;

  private isActive = false;

  constructor(
    private title: string,
    private icon: string,
    private _width: number,
    private container: IOptionsButtonsContainer
  ) {
    this.initElements();
  }

  get index() {
    return this.container.buttons.indexOf(this);
  }

  get prev() {
    return this.container.buttons[this.index - 1];
  }

  get x() {
    const prev = this.prev;
    const offset = 4;

    return (prev && prev.x + prev.width + offset) || 0;
  }

  get width() {
    return this._width;
  }

  set active(status: boolean) {
    this.isActive = status;
    this.$el.classed('active', this.isActive);
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.$el.node();
  }

  public render() {
    const width = this.width;
    const height = 24;
    const radius = height / 2;

    this.$el.attr('transform', translate(this.x, 0));

    this.$shadow.attr('width', width).attr('height', height).attr('rx', radius).attr('ry', radius);

    this.$background.attr('width', width).attr('height', height).attr('rx', radius).attr('ry', radius);

    this.$title
      .text(this.title)
      .attr('x', width / 2)
      .attr('y', height / 2)
      .attr('dy', '0.5ex');

    if (this.icon) {
      this.$icon.text(this.icon).attr('x', 8).attr('y', 20);

      this.$title.attr('x', 22 + (width - 22) / 2);
    }

    this.events();
  }

  protected initElements() {
    const el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(el).classed('button', true);

    this.$shadow = this.$el.append<SVGRectElement>('rect').classed('button-shadow', true);

    this.$background = this.$el.append<SVGRectElement>('rect').classed('button-background', true);

    this.$title = this.$el.append<SVGTextElement>('text').classed('button-title', true);

    this.$icon = this.$el.append<SVGTextElement>('text').classed('button-icon', true).classed('material-icons', true);
  }

  protected events() {
    this.$el.on('click', event => {
      this.onClick.next(event);
    });
  }
}

<button (click)="fileInput.click()" class="container-input">
  <div class="control-wrapper">
    <div class="control-wrapper-control">
      <mat-chip *ngIf="chosenFile" class="mat-chip" (removed)="removeFile()">
        <span class="mat-chip-text">{{ chosenFile.name }}</span>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <span *ngIf="!chosenFile">No file selected</span>
    </div>
    <mat-icon class="mat-icon">file_upload</mat-icon>
  </div>
  <input #fileInput class="pl-input pl-input-invisible" type="file" (click)="onClick($event)" (change)="onChange($event.target.files)"  />
</button>

import { BaseModel } from '../base.model';
import { SectorModel } from './sector.model';


export class RatingModel extends BaseModel {

  public name: string;
  public abbreviation: string;
  public description: string;

  public sectors: SectorModel[];
  public sectorIds: number[];

  public position: number;
  public type: string;

  constructor(data: any = {}) {
    super(data);

    this.name = data.name || '';
    this.abbreviation = data.abbr || '';
    this.description = data.description || '';

    this.sectors = (data.sectors && data.sectors.map(el => new SectorModel(el))) || [];
    this.sectorIds = (data.sectorIds && data.sectorIds.slice()) || [];

    this.position = data.position || null;
    this.type = data.type || '';
  }

}

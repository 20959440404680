export const keyFormatFromDate = 'YYYYMMDD';

export enum StatisticStatus {
  OverDelivery = 1,
  ExactDelivery = 0,
  UnderDelivery = -1,
}

export enum HealthStatus {
  Unhealthy = -1,
  Stressful = -0.5,
  Ok = 0,
  Healthy = 1,
}

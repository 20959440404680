import { ScheduleCalendarSettings } from '../consts/schedule-calendar-settings.const';

export class ScheduleCalendarSizes {

  public tabHeight = ScheduleCalendarSettings.tabHeight;
  public maxScheduleHeight = ScheduleCalendarSettings.maxScheduleSize;
  public zoom = ScheduleCalendarSettings.zoomLevel;

  private minScheduleWidth = ScheduleCalendarSettings.minWidth;
  private offsetLarge = ScheduleCalendarSettings.offsetLarge;
  private offsetSmall = ScheduleCalendarSettings.offsetSmall;

  constructor(protected _element: Element) {}

  get width() {
    return this._element.clientWidth || this._element.getBoundingClientRect().width;
  }

  get height() {
    return this.tabHeight + this.maxScheduleHeight + 1;
  }

  get headerHeight() {
    return this.cell * 0.75;
  }

  get cell() {
    return this.innerWidth / this.countVisibleSchedules / 9;
  }

  get innerWidth() {
    return this.width - (this.offset * 2);
  }

  get scheduleWidth() {
    return this.cell * 9;
  }

  get countVisibleSchedules() {
    const innerWidthWithLargeOffset = this.width - (this.offsetLarge * 2);
    let count = Math.floor(innerWidthWithLargeOffset / (this.minScheduleWidth * this.zoom));

    if (count % 2 === 0) {
      count -= 1;
    }

    if (count < 1) {
      count = 1;
    }

    return count;
  }

  get offset() {
    return this.countVisibleSchedules === 1 ? this.offsetSmall : this.offsetLarge;
  }

}

<div class="tooltip-content" *ngIf="!loading; else loadingContent">
  <ng-container *ngIf="!errorMessage; else error">
    <div>
      <span>Start time: </span>
      <strong> {{ data.start | minutesToTime }} </strong>
    </div>
    <div>
      <span>End time:</span>
      <strong> {{ data.end | minutesToTime }} </strong>
    </div>
    <div>
      <span>Duration:</span>
      <strong> {{ data.duration | humanizeDuration }} </strong>
    </div>
    <div>
      <span>Working Time:</span>
      <strong> {{ data.workingTimeInMinutes | humanizeDuration }} </strong>
    </div>
    <div>
      <span>Global:</span>
      <strong> {{ data.isGlobal ? 'Yes' : 'No' }} </strong>
    </div>
    <div *ngIf="data.group">
      <span>Group:</span>
      <strong> {{ data.group }} </strong>
    </div>
    <div *ngIf="data.skills?.length">
      <span>Skills:</span>
      <strong> {{ data.skills.join(', ') }} </strong>
    </div>
    <div *ngIf="data.licenses?.length">
      <span>Licenses:</span>
      <strong> {{ data.licenses.join(', ') }} </strong>
    </div>
    <div *ngIf="data.unitEndorsements?.length">
      <span>Endorsements:</span>
      <strong> {{ data.unitEndorsements.join(', ') }} </strong>
    </div>
    <div *ngIf="data.creator">
      <span>Creator:</span>
      <strong> {{ data.creator }} </strong>
    </div>
    <ng-container *ngIf="data.project">
      <span>Project:</span>
      <strong> {{ data.project }} </strong>
    </ng-container>
    <ng-container *ngIf="data.batch">
      <span>Batch:</span>
      <strong> {{ data.batch }} </strong>
    </ng-container>
    <div *ngIf="data.dates">
      <span>Requested dates:</span>
      <div>
        <strong> {{ data.dates.dateFrom | date: 'dd/MM/yyyy' }} - {{ data.dates.dateTo | date: 'dd/MM/yyyy' }} </strong>
      </div>
    </div>
    <div class="description" *ngIf="data.description">{{ data.description }}</div>
  </ng-container>
</div>

<ng-template #loadingContent>
  <mat-spinner [diameter]="80" color="accent"></mat-spinner>
</ng-template>

<ng-template #error>
  <div class="error">{{ errorMessage }}</div>
</ng-template>

import { SimpleItem } from '@shared/components/basetimeline';

export class EmployeeItem extends SimpleItem {

  constructor(protected _title = '',
              protected _activities: any[] = []) {
    super(_title, _activities);
  }

}

import * as d3 from 'd3';

import { url, xmlns } from '@shared/helpers/d3';


export class Defs {

  protected el: SVGDefsElement;
  protected $el: d3.Selection<SVGDefsElement, any, any, any>;

  protected $lockMask: d3.Selection<SVGPatternElement, any, any, any>;
  protected lockMaskID = 'lock-mask';

  constructor() {
    this.el = document.createElementNS(xmlns, 'defs');
    this.$el = d3.select(this.el);

    this.renderLockMask();
  }

  get lockMask() {
    return url(window.location.pathname, this.lockMaskID);
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.el;
  }

  /**
   * Render holiday mask
   */
  protected renderLockMask() {
    const size = 4;

    this.$lockMask = this.$el
      .append<SVGPatternElement>('pattern')
      .attr('id', this.lockMaskID)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', size)
      .attr('height', size / 2)
      .attr('patternTransform', `rotate(45)`);

    this.$lockMask.append('rect')
      .attr('x', '0')
      .attr('y', '0')
      .attr('width', size / 4)
      .attr('height', size / 2)
      .style('stroke', 'none')
      .style('fill', `#d0d0d0`);
  }

}

import { NotificationPopupType } from './notification-popup-type.model';
import { NotificationSeverity } from './notification-severity.model';
import { NotificationSource } from './notification-source.model';

export class NotificationModel {
  id: number;
  title: string;
  severity: NotificationSeverity;
  isRead: boolean;
  source: NotificationSource;
  content: string;
  popupType: NotificationPopupType;
  createdAt: string;
  link?: string;
}

import { ActivityDateModel } from '../activities';
import { CommentModel } from '../comment';
import { VacayRequestModel } from './vacay-request.model';

export class VacayAgendaRequestModel extends VacayRequestModel {

  protected commentsModel: CommentModel[] = [];

  constructor(data: any = {}) {
    super(data);

    this.startTime = data.start || void 0;

    this.iDate = new ActivityDateModel({
      from: data.selectedDate,
      to: data.selectedDate,
      date: data.selectedDate
    });
  }

  get type() {
    return 'agenda';
  }

  get wishStatus(): any {
    return {
      icon: 'do_not_disturb',
      shiftAlias: 1,
      status: 1,
      textStatus: 'agenda'
    };
  }
}


import { sortBy as _sortBy } from 'lodash-es';

import { BaseItem } from '../items/base.item';
import { IOpenableItem, IBaseItem } from '../items/interfaces';
import { Menu } from '../menu/menu';


export class SortItems {

  constructor(protected menu: Menu) { }

  public sortBy(
    items: BaseItem[],
    key: string,
    asc = true,
    excludeItems: BaseItem[] = [],
  ) {
    let sortedItems = items.slice();
    const excludeItemsByIndex = new Map<number, BaseItem>();

    excludeItems.forEach((item) => {
      const index = items.indexOf(item);

      if (index > -1) {
        excludeItemsByIndex.set(index, item);
      }
    });
    excludeItemsByIndex.forEach((item, index) => {
      sortedItems.splice(index, 1);
    });

    sortedItems = _sortBy(sortedItems, key);

    if (!asc) { sortedItems.reverse(); }

    excludeItemsByIndex.forEach((item, index) => {
      sortedItems.splice(index, 0, item);
    });

    this.order(sortedItems);
  }

  public order(items: BaseItem[]) {
    const firstItem = items[0];
    const level = firstItem.level;
    let container: (Menu | IOpenableItem) = firstItem.parent;

    if (level === 0) { container = this.menu; }

    const hasUniqParent = items.every(
      (item) => (item.parent === container || item.level === 0)
    );

    if (!hasUniqParent) {
      console.warn('Items should have same parent');
      return;
    }

    container.updateItemsPosition(items);
  }

}

import * as d3 from 'd3';

import { translate, xmlns } from '@shared/helpers/d3';

import { Calendar } from './calendar';
import { Day } from './day';


export class WeekDay {

  protected el: SVGElement;
  protected $el: d3.Selection<SVGElement, any, any, any>;

  protected $line: d3.Selection<SVGLineElement, any, any, any>;
  protected $text: d3.Selection<SVGTextElement, any, any, any>;

  protected _days: Day[] = [];

  constructor(protected _index: number,
              protected _label: string,
              protected _calendar: Calendar) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).attr('class', 'week-day');

    this.$line = this.$el.append<SVGLineElement>('line');
    this.$text = this.$el.append<SVGTextElement>('text');
  }

  get index() {
    return this._index;
  }

  get label() {
    return this._label;
  }

  get sizes() {
    return this._calendar.sizes;
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.el;
  }

  /**
   * Add related day by week day
   *
   * @param {Day} day
   */
  public addRelatedDay(day: Day) {
    this._days.push(day);
  }

  /**
   * Render week day
   */
  public render() {
    const cell = this.sizes.cell;
    const headerHeight = this.sizes.headerHeight;

    this.$el.attr('transform', translate(this.index * cell, 0));

    this.$line
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 4)
      .attr('y2', headerHeight);

    this.$text
      .text(this.label)
      .attr('x', cell / 2)
      .attr('y', headerHeight / 2)
      .attr('dy', '0.5em');
  }

}

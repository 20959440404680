<mat-card [class]="backgroundColor">
  <mat-card-header>
    <mat-card-title>
      {{ title }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
    <div class="icon-container">
      <ng-content select="[icon]"></ng-content>
    </div>
  </mat-card-header>
  <mat-card-content *ngIf="!loading; else spinner">
    <ng-content></ng-content>
  </mat-card-content>

  <mat-card-actions>
    <ng-content select="[actions]"></ng-content>
  </mat-card-actions>

  <mat-card-footer>
    <ng-content select="[footer]"></ng-content>
    <mat-progress-bar color="accent" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  </mat-card-footer>
</mat-card>

<ng-template #spinner>
  <div class="filler"></div>
</ng-template>

import { takeUntil } from 'rxjs/operators';
import { VacayRequestStatisticActivity } from '@app/_shared/models/vacay/vacay-request-statistic-activity';
import { VacayScheduleService } from '@app/vacay/_shared/services/vacay-schedule.service';
import { ShiftAliasModel, VacayRequestStatisticModel } from '@shared/models';
import { Day } from '../calendar';
import { ShiftStatistic } from './shift-statistic';


export class DayStatistic {

  protected _statistic: VacayRequestStatisticModel;
  protected _statisticShifts = new Map<number, ShiftStatistic>();

  constructor(protected readonly _day: Day, private readonly vacayScheduleService: VacayScheduleService) {
    if (this._day) {
      this.initStatisticShifts(this._day.storage.aliases);
      this.subscribes();
    } else {
      this.initStatisticShifts(this.vacayScheduleService.shiftAliases);
    }
  }

  set statistic(statistic: VacayRequestStatisticModel) {
    this._statistic = statistic;

    if (this._statistic && this._statistic.activities) {
      this.setStatisticByShifts(statistic.activities);
    }
  }

  get status() {
    return this._statistic && this._statistic.warningStatus;
  }

  get statisticShifts() {
    return this._statisticShifts;
  }

  protected initStatisticShifts(shifts: ShiftAliasModel[]) {
    shifts.forEach((shift) => {
      this._statisticShifts.set(shift.id, new ShiftStatistic());
    });

    if (this._statistic && this._statistic.activities) {
      this.setStatisticByShifts(this._statistic.activities);
    }
  }

  protected setStatisticByShifts(
    statistic: Map<number, VacayRequestStatisticActivity>,
  ) {
    this._statisticShifts.forEach((item, shiftId) => {
      const shiftStatistic = statistic.get(shiftId);

      if (!shiftStatistic) { return; }

      item.statistic = shiftStatistic;
    });
  }

  /**
   * Subscribes
   */
  protected subscribes() {
    this._day.timeline.onInitAdditionalData
      .pipe(
        takeUntil(this._day.timeline.onDestroy)
      )
      .subscribe(() => this.initStatisticShifts(this._day.storage.aliases));
  }

}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ShiftDetailsService } from '@app/rostr/overview/api/services/shift-details.service';
import { Activity } from '@app/rostr/overview/dto';
import { ShiftType } from '@enums';
import { ShiftService, VacayRequestsService } from '@services';

export interface ShiftSummaryData {
  start: number;
  end: number;
  duration: number;
  skills?: string[];
  licenses?: string[];
  unitEndorsements?: string[];
  creator?: string;
  project?: string;
  description?: string;
  group?: string;
  workingTimeInMinutes: number;
  batch?: number;
  noRequested?: number;
  dates?: {
    dateFrom: Date;
    dateTo: Date;
  };
  isGlobal?: boolean;
}

@Component({
  selector: 'app-shift-summary-tooltip',
  templateUrl: './shift-summary-tooltip.component.html',
  styleUrls: ['./shift-summary-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShiftSummaryTooltipComponent implements OnInit {
  @Input() activity: Activity;

  data: ShiftSummaryData;
  loading = false;
  errorMessage: string;

  constructor(
    private readonly shiftService: ShiftService,
    private readonly shiftDetailsService: ShiftDetailsService,
    private readonly vacayRequestsService: VacayRequestsService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadShift();
  }

  async loadShift() {
    this.loading = true;
    switch (this.activity.parentType) {
      case ShiftType.Required:
        this.loadRequiredShift();
        break;
      case ShiftType.Agenda:
        this.loadAgenda();
        break;
      case ShiftType.Vacay:
        this.loadVacay();
        break;
      case ShiftType.Manual:
        this.loadRegularShift();
        break;
      case ShiftType.Master:
        this.loadRegularShift();
        break;
      default:
        this.loadRegularShift();
    }
  }

  private loadAgenda() {
    this.shiftDetailsService.getAgendaRequestWithShiftDetails(this.activity.parentId).subscribe(agendaRequest => {
      if (!agendaRequest) {
        this.errorMessage = 'This Agenda request has been removed.';
        this.loading = false;
        this.changeDetector.markForCheck();
        return;
      }
      const shift = agendaRequest.shiftCode;
      const createdBy = agendaRequest.createdBy;
      this.data = {
        start: shift.start,
        end: shift.start + shift.duration,
        duration: shift.duration,
        workingTimeInMinutes: shift.workingTimeInMinutes,
        creator: createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : 'Unknown',
        description: agendaRequest.description,
        project: agendaRequest.objective.project.title,
        skills: shift.skills?.map(skill => skill.name),
        unitEndorsements: shift.unitEndorsements?.map(endorsement => endorsement.title),
        licenses: shift.licenses?.map(license => license.name)
      };
      this.loading = false;
      this.changeDetector.markForCheck();
    });
  }

  private loadRequiredShift() {
    const id = this.activity.parentId || this.activity.id;
    this.shiftDetailsService.getRequiredShiftDetails(id).subscribe(shift => {
      this.data = {
        start: shift.start,
        end: shift.start + shift.duration,
        duration: shift.duration,
        skills: shift?.skills,
        unitEndorsements: shift?.unitEndorsements,
        licenses: shift?.licenses,
        group: shift.group.name,
        workingTimeInMinutes: shift.workingTimeInMinutes,
        isGlobal: shift.isGlobal,
        description: shift.shiftCode?.comment
      };
      this.loading = false;
      this.changeDetector.markForCheck();
    });
  }

  private loadRegularShift() {
    this.shiftService.get(this.activity.shiftCodeId ?? this.activity.id).subscribe(shift => {
      this.data = {
        start: shift.start,
        end: shift.start + shift.duration,
        duration: shift.duration,
        workingTimeInMinutes: shift.workingTimeInMinutes,
        isGlobal: shift.isGlobal,
        skills: shift.skills?.map(x => x.name),
        unitEndorsements: shift.unitEndorsements?.map(endorsement => endorsement.title),
        licenses: shift.licenses?.map(license => license.title),
        description: shift.comment
      };
      this.loading = false;
      this.changeDetector.markForCheck();
    });
  }

  private loadVacay() {
    this.vacayRequestsService.getVacayRequestInfoWithBatch(this.activity.parentId).subscribe(request => {
      this.data = {
        start: request.start,
        end: request.start + request.duration,
        duration: request.duration,
        workingTimeInMinutes: request.shiftCode?.workingTimeInMinutes ?? request.duration,
        description: request.vacayRequestBatch?.comment,
        batch: request.vacayRequestBatch?.id,
        dates: request.vacayRequestBatch && {
          dateFrom: request.vacayRequestBatch.dateFrom,
          dateTo: request.vacayRequestBatch.dateTo
        }
      };
      this.loading = false;
      this.changeDetector.markForCheck();
    });
  }
}

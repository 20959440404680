export interface ISchedulesTimelineSizes {
    height: number;
    groupHeight: number;
    groupWidth: number;
    lineHeight: number;
    scheduleHeight: number;
    marginRight: number;
    topMargin: number;
    footerHeight: number;
    groupsHeight?: number;
    groupNameOffset: number;
    addNewHeight: number;
    schedulePadding: number;
    defaultMargin: number;
  }
  
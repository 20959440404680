import { Moment } from 'moment-mini';

export interface DateRange {
  dateFrom?: Date;
  dateTo?: Date;
}
export interface DateRangeMoment {
  dateFrom?: Moment;
  dateTo?: Moment;
}

import { RostrRequestBaseModel } from './rostr-request-base.model';
import { EmployeeModel } from '../auth';
import { AgendaBaselineActivityModel } from '../schedules';
import { IRostrRequestBaseModelParams } from '../interfaces';

export class RostrAgendaRequestBaseModel extends RostrRequestBaseModel {
  public employee: EmployeeModel;
  public duration: number;
  public group;

  constructor(data: AgendaBaselineActivityModel) {
     const baseClassValues: IRostrRequestBaseModelParams = {
      __typename: data.__typename,
      code: data.code,
      createdAt: data.createdAt,
      createdBy: data.createdBy,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      deletedAt: data.deletedAt,
      iDate: data.iDate,
      id: data.id,
      shiftAlias: data.alias,
      updatedAt: data.updatedAt,
      updatedBy: data.updatedBy
    };
    super(baseClassValues);
    this.employee = data.employee;
    this.duration = data.duration;
    this.group = data.employee ? data.employee.group : null;
  }
}


import { UserBasic } from '../../../_shared/models/rostr/user-basic';
import { ScheduleHistoryEntryAction } from './schedule-history-entry-action.enum';
export interface ScheduleHistoryEntry {
  id: number;

  createdAt: string;
  createdBy: UserBasic;

  scheduleId: number;
  action: ScheduleHistoryEntryAction;
  batch: number;
  isUndone: boolean;
}

import * as d3 from 'd3';

import { translate, xmlns } from '@shared/helpers/d3';
import { isoWeek } from '@shared/helpers/date';

import { VacayRequestActivity } from '../activities';
import { Calendar } from './calendar';
import { Day } from './day';
import { Month } from './month';


export class Week {

  protected el: SVGElement;
  protected $el: d3.Selection<SVGElement, any, any, any>;

  protected $labelBackground: d3.Selection<SVGRectElement, any, any, any>;
  protected $labelBorder: d3.Selection<SVGLineElement, any, any, any>;
  protected $labelNumber: d3.Selection<SVGTextElement, any, any, any>;

  protected _month: Month;
  protected _days: Day[] = [];

  protected _vacayRequest: VacayRequestActivity;

  constructor(protected _dateFrom: Date,
              protected _dateTo: Date,
              protected _calendar: Calendar) {
    this.el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(this.el).attr('class', 'week');

    this.$labelBackground = this.$el.append<SVGRectElement>('rect').attr('class', 'week-background');
    this.$labelBorder = this.$el.append<SVGLineElement>('line').attr('class', 'week-border');
    this.$labelNumber = this.$el.append<SVGTextElement>('text').attr('class', 'week-number');
  }

  set month(month: Month) {
    this._month = month;
  }

  get month() {
    return this._month;
  }

  get days() {
    return this._days;
  }

  set vacayRequest(request: VacayRequestActivity) {
    this._vacayRequest = request;
    this.days.forEach(day => day.vacayRequest = request);

    this.checkClasses();
  }

  get vacayRequest() {
    return this._vacayRequest;
  }

  get hasVacayRequest() {
    return !!this.vacayRequest;
  }

  get isLocked() {
    return this._calendar.isLocked;
  }

  get indexByCalendar() {
    return this._calendar.weeks.indexOf(this);
  }

  get indexByMonth() {
    return this._month.weeks.indexOf(this);
  }

  get dateFrom() {
    return this._dateFrom;
  }

  get dateTo() {
    return this._dateTo;
  }

  get timeline() {
    return this._calendar.timeline;
  }

  get defs() {
    return this._calendar.defs;
  }

  get sizes() {
    return this._calendar.sizes;
  }

  get storage() {
    return this._calendar.storage;
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.el;
  }

  /**
   * Add related day by week
   *
   * @param {Day} day
   */
  public addRelatedDay(day: Day) {
    this._days.push(day);
  }

  /**
   * Render week
   */
  public render() {
    const cell = this.sizes.cell;

    this.$el.attr('transform', translate(0, this.indexByCalendar * cell));

    this.$labelBackground
      .attr('width', cell)
      .attr('height', cell);

    this.$labelBorder
      .attr('x1', cell)
      .attr('x2', cell)
      .attr('y1', 0)
      .attr('y2', cell);

    this.$labelNumber
      .text(isoWeek(this.dateFrom))
      .attr('y', cell / 2)
      .attr('x', cell / 2)
      .attr('dy', '0.5ex');

    this.checkClasses();

    // Vacation adding is disabled
    // this.events();
  }

  /**
   * Check classes
   */
  protected checkClasses() {
    this.$el
      .classed('hasVacayRequest', this.hasVacayRequest)
      .classed('isLocked', this.isLocked);
  }

  /**
   * Events
   */
  protected events() {
    this.$el
      .on('click', () => {
        if (this.isLocked) { return; }

        if (this.hasVacayRequest) {
          this.timeline.context.scheduleService.deleteRequest(this.vacayRequest.id);
          return;
        }

        if (!this.storage.vacationAlias) { return; }

        this.timeline.context.scheduleService
          .createVacayRequest({
            date: this.dateFrom,
            shiftAlias: this.storage.vacationAlias.id,
          })
          .subscribe();
      })
      .on('mouseenter', () => {
        if (this.isLocked) { return; }

        this.$el.classed('hover', true);
        this._days.forEach(day => day.element.classed('hover', true));
      })
      .on('mouseleave', () => {
        if (this.isLocked) { return; }

        this.$el.classed('hover', false);
        this._days.forEach(day => day.element.classed('hover', false));
      });
  }

}

import { IBaseModel } from '../models/interfaces';
export interface ShiftAliasModelData extends IBaseModel {
  timeFrom?: string;
  timeTo?: string;
  alias?: string;
  value?: string;
  active?: boolean;
  start?: number;
  window?: number;
  duration?: number;
  type?: string;
}

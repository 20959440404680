import { RequiredShiftModel } from '../schedules';
import { TimelineEmployeeModel } from './employee.model';
import { ScheduleModel } from '../schedules/schedule.model';


export class TimelineGroupModel {

  public employees: TimelineEmployeeModel[];
  public requiredShifts: RequiredShiftModel[];
  public schedule: ScheduleModel;

  constructor(data: any = {}, schedule: ScheduleModel = void 0, useEmployeesFromSchedule = false) {

    if (useEmployeesFromSchedule) {
      this.employees = (schedule.assignedEmployees && schedule.assignedEmployees.map((employee: any) => new TimelineEmployeeModel(employee))) || [];
    }
    else {
      this.employees = (data.employees && data.employees.map(employee => new TimelineEmployeeModel(employee))) || [];
    }

    this.requiredShifts = (data.requiredShifts && data.requiredShifts.map(requiredShift => new RequiredShiftModel(requiredShift))) || [];
    this.schedule = schedule || void 0;
  }
}

import { EmployeeModel } from '../auth';
import { ActivityDateModel } from '../activities';
import { RostrRequestBaseModel } from '../rostr/rostr-request-base.model';
import { IAgendaBaselineRequestParams } from '../interfaces';


export class AgendaBaselineActivityModel extends RostrRequestBaseModel {

  public dates: Date[];
  public employee: EmployeeModel;
  public code: string;
  public iDate: ActivityDateModel;
  public duration: number;

  constructor(data: IAgendaBaselineRequestParams = {
    id: undefined,
    createdAt: undefined,
    createdBy: undefined,
    updatedAt: undefined,
    updatedBy: undefined,
    deletedAt: undefined,
    __typename: undefined,
    code: '',
    dateFrom: undefined,
    dateTo: undefined,
    iDate: undefined,
    shiftAlias: undefined,
    matchedDates: undefined,
    shiftCode: undefined
  }) {
    super(data);
    this.dates = (data.matchedDates && data.matchedDates.map(d => new Date(d))) || [];
    this.code = data.shiftCode ? data.shiftCode.code : null;
    this.duration = data.shiftCode ? data.shiftCode.duration : null;
  }

}


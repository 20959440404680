import { MatrixGroupDataModel } from './matrix-group-data.model';
export class MatrixDataModel {

  public cols: MatrixGroupDataModel[];
  public rows: MatrixGroupDataModel[];

  constructor(data: MatrixDataModel) {
    this.cols = data.cols;
    this.rows = data.rows;
  }

}

import * as d3 from 'd3';
import { IShift } from '../shifts/base.shift';
import { IItemConfig, IShiftableItem } from './interfaces';
import { Menu } from '../menu/menu';
import { Defs } from '../defs/defs';
import { ShiftsBasedItem } from './shifts-based.item';
import { IBaseGrid } from '../grid/base-grid.interface';
import { IBaseTimeline } from '../utils/interfaces';

export class SubItem extends ShiftsBasedItem implements IShiftableItem {

  protected $gridBackground: d3.Selection<any, any, any, any>;
  protected _visible: IShift[] = [];

  constructor(protected _title = '',
    protected _shifts: IShift[] = [],
    protected _itemConfig: IItemConfig = { height: 40 }) {
    super(_title, _shifts, _itemConfig);
    this.shifts(_shifts);
  }

  /**
   * Rendering functionality
   * @returns {Element}
   */
  public render() {
    super.render();
    this.$line.attr('visibility', 'hidden');
    return this.el;
  }

  /**
   * Subscribe to event emitters
   */
  public subscribe() {
    this._shifts.forEach((shift: IShift) => {
      shift.subscribe();
    });
    this._grid.onPrevInterval.subscribe(() => {
      this.lookup();
    });
    this._grid.onNextInterval.subscribe(() => {
      this.lookup();
    });
    this._grid.onRenderIntervals.subscribe(() => {
      this.lookup();
    });
  }

  /****************************************** Some main functionality ***************************************/
  /**
   * Setter for grid property
   * @param grid
   * @returns {Baseitem}
   */
  public grid(grid: IBaseGrid): this {
    super.grid(grid);
    this._shifts.forEach((shift) => {
      shift.grid(grid);
    });
    this.$gridBackground = this._grid.itemBackgroundsContainer.append('rect');
    this.lookup();
    return this;
  }

  /**
   * Setter for timeline property
   * @param timeline
   * @returns {Baseitem}
   */
  public timeline(timeline: IBaseTimeline): this {
    super.timeline(timeline);
    this._shifts.forEach((shift) => {
      shift.timeline(timeline);
    });
    return this;
  }

  /**
   * Menu setter
   * @param menu
   * @returns {Baseitem}
   */
  public menu(menu: Menu): this {
    super.menu(menu);
    this._shifts.forEach((shift) => {
      shift.menu(menu);
    });
    return this;
  }

  /**
   * Defs setter
   * @param defs
   * @returns {BaseItem}
   */
  public defs(defs: Defs): this {
    this._defs = defs;
    this._shifts.forEach((shift) => {
      shift.defs(defs);
    });
    return this;
  }

  /*********************************** Shifts ********************************/
  /**
   * Getter and setter for shifts property
   * @param shifts
   * @returns {any}
   */
  public shifts(shifts?: IShift[]): this | IShift[] {
    if (!arguments.length) {
      return this._shifts;
    }
    this._shifts = shifts;
    shifts.forEach((shift: IShift) => {
      shift.item(this);
    });
    return this;
  }

  /**
   * Add shift to item shifts
   * @param shift
   * @returns {OpenableItem}
   */
  public addShift(shift: IShift) {
    this.insertInOrderShift(shift);
    // this._shifts.push(shift);
    // this._shifts.sort((a: IShift, b: IShift) => {
    //   return a.dateFrom().getTime() - b.dateFrom().getTime();
    // });
    return this;
  }

  /**
   * Remove shift
   * @param shift
   * @returns {OpenableItem}
   */
  public removeShift(shift: IShift) {
    const index = this._shifts.indexOf(shift);
    if (index > -1) {
      this._shifts.splice(index, 1);
    }
    return this;
  }

  /**
   * Y position for shift
   * @param shift
   */
  public shiftPosition(shift: IShift): number {
    return this.y();
  }

  public visible() {
    return this._visible;
  }

  /*******************************************************************/
  /**
   * Remove functionality
   */
  public remove() {
    this._shifts.forEach((shift) => {
      shift.remove();
    });
    super.remove();
  }

  /**
   * Separate background rendering
   */
  public renderBackground() {
    const sizes = this._timeline.sizes();
    const width = sizes.width;
    const rh = sizes.rowHeight;
    const mw = sizes.menuWidth;

    this.$background.attr('height', rh)
      .attr('width', mw)
      .attr('fill', this.isOdd() ? '#dcdee2' : '#d5d7da');

    this.$gridBackground.attr('y', this.y())
      .attr('height', rh)
      .attr('width', width)
      .attr('fill', '#dcdee2');
  }

  protected lookup() {
    const visible = this._grid.visible();
    this._visible = this._shifts.filter((shift: IShift) => {
      return visible.indexOf(shift) > -1;
    });
  }
}

import * as moment from 'moment-mini';

export class VacayRequestStatisticActivityData {
  public date: moment.Moment;
  public coverage: number;
  public warningStatus: number;

  constructor() {
    this.coverage = 0;
    this.warningStatus = 0;
  }
}

export const introspectionQueryResultData = {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'ReportPluginParams',
        possibleTypes: [
          { name: 'LineChartParams' },
          { name: 'DemandParams' },
          { name: 'HeatMapParams' },
          { name: 'TextEditorParams' },
          { name: 'ShiftrParams' },
          { name: 'ClusterParams' }
        ]
      },
      {
        kind: 'UNION',
        name: 'ReportPluginData',
        possibleTypes: [
          { name: 'LineChartData' },
          { name: 'DemandData' },
          { name: 'HeatMapData' },
          { name: 'TextEditorData' },
          { name: 'ShiftrData' },
          { name: 'ClusterData' }
        ]
      }
    ]
  }
};

import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() class: string;
  @Input() ariaLabel: string;
  @Input() required: boolean;
  @Input() value: File;

  public onChangeRegisteredFunctions: ((value: File) => void)[] = [];
  public onTouchedRegisteredFunctions: ((value: File) => void)[] = [];
  public isDisabled: boolean;
  public chosenFile: File = null;

  public onChange(files: File[]) {
    if (files.length > 0) {
      this.chosenFile = files[0];
    }

    this.onChangeRegisteredFunctions.forEach(f => f(this.chosenFile));
  }

  public removeFile() {
    this.chosenFile = null;
    this.onChange([]);
  }

  public onClick(event:Event){
    const element = event.target as HTMLInputElement;
    element.value = '';
  }


  writeValue(value: File): void {
    this.value = value;
  }

  registerOnChange(fn: (value: File) => void): void {
    this.onChangeRegisteredFunctions.push(fn);
  }

  registerOnTouched(fn: (value: File) => void): void {
    this.onTouchedRegisteredFunctions.push(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}

import { IRouteChild } from '@interfaces';
import { SettingsKey } from '@model/settings/settings-key';
export interface IRouteModule {
  path: string;
  title: string;
  children: IRouteChild[];
  icon: string;
  moduleToggle?: {
    active: boolean,
    settingIdentifier: SettingsKey
  }
}

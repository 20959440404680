import * as d3 from 'd3';
import { translate } from '@helpers';

import { OpenableItem } from '../../items/openable.item';
import { RootItem } from '../../items/root.item';
import { BasePartialHeader } from '../base-partial.header';
import { OptionsButton } from './options-button';
import { IOptionsButtonsContainer } from './options-buttons-container.interface';

const ORDER = 'order';
const LAST_NAME = 'lastName';
type EmployeesSortKey = 'order' | 'lastName';

interface IOptionsHeaderConfig {
  orderGroupsCallback: (items) => void;
  sortEmployeesCallback: (type: EmployeesSortKey, asc: boolean) => void;
}


export class OptionsHeader extends BasePartialHeader implements IOptionsButtonsContainer {

  public readonly buttons: OptionsButton[] = [];

  protected $title: d3.Selection<SVGTextElement, any, any, any>;
  protected $background: d3.Selection<SVGRectElement, any, any, any>;
  protected $buttonsContainer: d3.Selection<SVGGElement, any, any, any>;

  protected $sortButtonsContainer: d3.Selection<SVGGElement, any, any, any>;

  protected $orderGroups: d3.Selection<SVGGElement, any, any, any>;
  protected $orderGroupsBackground: d3.Selection<SVGCircleElement, any, any, any>;

  protected $sortEmployees: d3.Selection<SVGGElement, any, any, any>;
  protected $sortEmployeesBackground: d3.Selection<SVGCircleElement, any, any, any>;
  protected $sortEmployeesLabel: d3.Selection<SVGTextElement, any, any, any>;

  protected $orderEmployees: d3.Selection<SVGGElement, any, any, any>;
  protected $orderEmployeesShadow: d3.Selection<SVGCircleElement, any, any, any>;
  protected $orderEmployeesBackground: d3.Selection<SVGCircleElement, any, any, any>;
  protected $orderEmployeesLabel: d3.Selection<SVGTextElement, any, any, any>;

  protected orderedGroupItems: (RootItem | OpenableItem)[] = [];
  protected employeesSortBy: EmployeesSortKey = ORDER;
  protected employeesOrderByAsc = true;

  constructor(protected config: IOptionsHeaderConfig) {
    super();
  }

  get height() {
    return 30;
  }

  public init() { }

  public render() {
    const { width, menuWidth } = this.timeline.sizes();
    const height = this.height;

    this.$el.attr('transform', translate(0, this.y));

    this.$background
      .attr('width', width)
      .attr('height', height);

    if (this.header.schedulesController) {
      this.$title
        .text('Options')
        .attr('x', 7)
        .attr('y', -5);
    }

    this.$buttonsContainer
      .attr('transform', translate(menuWidth, 3));

    this.renderSortButtons();

    this.events();
  }

  public subscribe() { }

  public addButton(title: string, icon: string, width: number) {
    const button = new OptionsButton(title, icon, width, this);

    this.buttons.push(button);

    button.render();
    this.$buttonsContainer.node().appendChild(button.node);

    return button;
  }

  public addGroupItem(item: RootItem | OpenableItem) {
    this.orderedGroupItems.push(item);
    this.renderOrderGroupsButton();
  }

  protected initElements() {
    super.initElements();

    this.$el.classed('options-header', true);

    this.$background = this.$el
      .append<SVGRectElement>('rect')
      .classed('options-header-background', true);

    this.$title = this.$el
      .append<SVGTextElement>('text')
      .classed('options-header-title', true);

    this.$buttonsContainer = this.$el
      .append<SVGGElement>('g')
      .classed('options-header-buttons', true);

    this.$sortButtonsContainer = this.$el
      .append<SVGGElement>('g')
      .classed('options-header-sort-buttons', true);

    // Order groups button
    this.$orderGroups = this.$sortButtonsContainer
      .append<SVGGElement>('g')
      .classed('options-header-order-groups', true);
    this.$orderGroupsBackground = this.$orderGroups
      .append<SVGCircleElement>('circle');

    // Sort employees button
    this.$sortEmployees = this.$sortButtonsContainer
      .append<SVGGElement>('g')
      .classed('options-header-sort-employees', true);
    this.$sortEmployeesBackground = this.$sortEmployees
      .append<SVGCircleElement>('circle');
    this.$sortEmployeesLabel = this.$sortEmployees
      .append<SVGTextElement>('text');

    // Order employees button
    this.$orderEmployees = this.$sortButtonsContainer
      .append<SVGGElement>('g')
      .classed('options-header-order-employees', true);
    this.$orderEmployeesShadow = this.$orderEmployees
      .append<SVGCircleElement>('circle')
      .classed('shadow', true);
    this.$orderEmployeesBackground = this.$orderEmployees
      .append<SVGCircleElement>('circle');
    this.$orderEmployeesLabel = this.$orderEmployees
      .append<SVGTextElement>('text');
  }

  protected renderSortButtons() {
    this.$sortButtonsContainer.attr('transform', translate(7, 5));

    this.renderOrderGroupsButton();
    this.renderSortEmployeesButton();
    this.renderOrderEmployeesButton();
  }

  protected renderOrderGroupsButton() {
    const firstGroup = this.orderedGroupItems[0];

    this.$orderGroupsBackground
      .attr('r', 10)
      .attr('cx', 10)
      .attr('cy', 10)
      .attr('fill', firstGroup ? firstGroup.indicationFillColor : '#f6a623');
  }

  protected renderSortEmployeesButton() {
    this.$sortEmployees
      .attr('transform', translate(24, 0));

    this.$sortEmployeesBackground
      .attr('r', 10)
      .attr('cx', 10)
      .attr('cy', 10);

    this.$sortEmployeesLabel
      .text(this.employeesSortBy === ORDER ? '1' : 'A')
      .attr('x', 10)
      .attr('y', 10)
      .attr('dy', '0.5ex');
  }

  protected renderOrderEmployeesButton() {
    this.$orderEmployees
      .attr('transform', translate(48, 0));

    this.$orderEmployeesShadow
      .attr('r', 10)
      .attr('cx', 10)
      .attr('cy', 10);

    this.$orderEmployeesBackground
      .attr('r', 10)
      .attr('cx', 10)
      .attr('cy', 10);

    this.$orderEmployeesLabel
      .text(this.employeesOrderByAsc ? 'arrow_downward' : 'arrow_upward')
      .attr('x', 10)
      .attr('y', 18)
      .classed('material-icons', true);
  }

  protected events() {
    this.$orderGroups.on('click', () => {
      if (this.orderedGroupItems.length <= 1) { return; }

      const firstGroup = this.orderedGroupItems.shift();
      this.orderedGroupItems.push(firstGroup);

      this.renderOrderGroupsButton();
      this.config.orderGroupsCallback(this.orderedGroupItems);
    });

    this.$sortEmployees.on('click', () => {
      this.employeesSortBy = this.employeesSortBy === ORDER ? LAST_NAME : ORDER;

      this.renderSortEmployeesButton();

      this.config.sortEmployeesCallback(
        this.employeesSortBy,
        this.employeesOrderByAsc,
      );
    });

    this.$orderEmployees.on('click', () => {
      this.employeesOrderByAsc = !this.employeesOrderByAsc;

      this.renderOrderEmployeesButton();

      this.config.sortEmployeesCallback(
        this.employeesSortBy,
        this.employeesOrderByAsc,
      );
    });
  }

  public sort() {
    this.config.sortEmployeesCallback(
      this.employeesSortBy,
      this.employeesOrderByAsc,
    );
  }
}

import { ZoomLevel } from '../enums/zoom-level.enum';

export const ScheduleCalendarSettings = {
  minWidth: 270,
  offsetLarge: 80,
  offsetSmall: 0,
  tabHeight: 39,
  maxScheduleSize: 0,
  zoomLevel: ZoomLevel.L100
};

import * as d3 from 'd3';
import { merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { shadeBlendConvert, translate, xmlns, lower, upper } from '@helpers';
import { ScheduleModel } from '@models';

import { BaseInterval } from '../../intervals/base.interval';
import { IBaseScheduleRow } from './base-schedule-row.interface';

export class Schedule {
  protected $el: d3.Selection<SVGGElement, any, any, any>;
  protected $background: d3.Selection<SVGRectElement, any, any, any>;
  protected $indication: d3.Selection<SVGCircleElement, any, any, any>;
  protected $title: d3.Selection<SVGTextElement, any, any, any>;

  protected properties = {
    xPos: 0,
    width: 0,
    height: 0,
    fillColor: void 0,
    isVisible: false,
    titleIsVisible: true
  };

  private _leftInterval: BaseInterval;
  private _rightInterval: BaseInterval;

  constructor(protected model: ScheduleModel, protected row: IBaseScheduleRow) {
    this.initElements();
  }

  get isCurrent() {
    return this.row.currentSchedules.indexOf(this) > -1;
  }

  get dateFrom() {
    return this.model.dateFrom;
  }

  get dateFromUnix() {
    return this.model.dateFromUnix;
  }

  get dateTo() {
    return this.model.dateTo;
  }

  get dateToUnix() {
    return this.model.dateToUnix;
  }

  get leftInterval() {
    return this._leftInterval;
  }

  get rightInterval() {
    return this._rightInterval;
  }

  get timeline() {
    return this.row.timeline;
  }

  get grid() {
    return this.row.grid;
  }

  get menu() {
    return this.row.menu;
  }

  get element() {
    return this.$el;
  }

  get node() {
    return this.$el.node();
  }

  public init() {
    this.lockupIntervals();
  }

  public render() {
    const { xPos, width, height, fillColor, isVisible, titleIsVisible } = this.initProperties();

    this.$el.attr('transform', translate(xPos, 0)).classed('hide', !isVisible);

    this.$indication
      .attr('r', 5)
      .attr('cx', 14)
      .attr('cy', height / 2)
      .attr('visibility', titleIsVisible ? 'visible' : 'hidden')
      .style('fill', fillColor);

    this.$title
      .text(this.model.name)
      .attr('x', 25)
      .attr('y', height / 2)
      .attr('dy', '0.5ex')
      .attr('visibility', titleIsVisible ? 'visible' : 'hidden');

    this.$background
      .attr('width', width)
      .attr('height', height)
      .style('fill', shadeBlendConvert(0.35, fillColor, '#ffffff'));
  }

  public subscribe() {
    merge(this.grid.onDragMove, this.grid.onDragEnd)
      .pipe(takeUntil(this.timeline.onDestroy))
      .subscribe(() => this.render());

    this.grid.onChangeBase.pipe(takeUntil(this.timeline.onDestroy)).subscribe(() => this.lockupIntervals());

    this.grid.onRenderIntervals.pipe(takeUntil(this.timeline.onDestroy)).subscribe(() => {
      this.lockupIntervals();
      this.render();
    });
  }

  protected initElements() {
    const el = document.createElementNS(xmlns, 'g');
    this.$el = d3.select(el).classed('schedule', true);

    this.$background = this.$el.append<SVGRectElement>('rect').classed('schedule-background', true);

    this.$indication = this.$el.append<SVGCircleElement>('circle').classed('schedule-indication', true);

    this.$title = this.$el.append<SVGTextElement>('text').classed('schedule-title', true);
  }

  protected initProperties() {
    const properties = this.properties;
    const { height, fillColor } = this.row.properties;
    const { menuWidth } = this.timeline.sizes();

    properties.xPos = 0;
    properties.width = 0;
    properties.height = height;
    properties.fillColor = fillColor;

    if (this.leftInterval) {
      const xPos = this.leftInterval.leftSide - menuWidth;
      properties.xPos = xPos < 0 ? 0 : xPos;
    }

    if (this.leftInterval && this.rightInterval && this.isCurrent) {
      const width = this.rightInterval.leftSide - menuWidth;
      properties.width = width < 0 ? 0 : width - properties.xPos;
    }

    properties.isVisible = this.isCurrent && !!properties.width;
    properties.titleIsVisible = properties.isVisible && this.row.schedulesHeader.scheduleRows.length <= 2;

    return properties;
  }

  protected lockupIntervals() {
    const intervals = this.grid.allIntervals;

    const start = lower(intervals, this.dateFromUnix, interval => interval.dateFromUnix);
    const end = upper(intervals, this.dateToUnix, interval => interval.dateFromUnix, true);

    this._leftInterval = intervals[start];
    this._rightInterval = intervals[end] || intervals[intervals.length - 1];
  }
}

import { AssignedEmployeeResult } from './assigned-employee.model';
import { ScheduleValidation } from './schedule-validation';
import { IAvaiJob } from './avai/avai.job';
import { Group } from './group';
import { ManualShift } from './manual-shift.model';
import { RequiredShift } from '../../../_shared/models/rostr/required-shift.model';
import { ScheduleState } from '@model/schedules/schedule-state.model';
import { ScheduleStatistic } from './schedule-statistic';

export class ScheduleDto {
  id: number;
  name: string;
  dateFrom: string;
  dateTo: string;
  assignedEmployees: AssignedEmployeeResult[];
  requiredShifts: RequiredShift[];
  job?: IAvaiJob;
  manualShifts: ManualShift[];
  isVacayCommentingBlocked: boolean;
  algorithms: string[];
  groups: Group[];
  skills: string[];
  validations: ScheduleValidation[];
  state: ScheduleState;
  statistics: ScheduleStatistic[];
}

import * as d3 from 'd3';
import { Type } from '@angular/core';
import { Subject } from 'rxjs';
import { translate } from '@helpers';
import { CommentModel } from '@models';
import { TooltipService } from '../tooltip/tooltip.service';
import { CommentTooltipComponent } from './tooltip-component/comment-tooltip.component';
import { CommentsService } from './services/comments.service';
import { ICustomTooltipConfig, ITargetElementRect, TooltipPlacement } from '../tooltip/tooltip.config';
import { CommentTypes } from '@enums';

export interface ICommentTooltipParams extends Partial<ICustomTooltipConfig> {
  targetElement: ITargetElementRect | ClientRect;
  targetSelection: d3.Selection<any, any, any, any>;
  correspondingDay: string;
  correspondingEmployeeId: number;
  commentType: CommentTypes;
  component?: Type<any>;
  placement?: TooltipPlacement;
  createClasses?: string;
  editClasses?: string;
}

export class CommentsTooltip {
  public $commentsIndicator: d3.Selection<any, any, any, any>;

  public isOpen = false;
  public onRemoveComment = new Subject<CommentModel>();

  protected tooltipConfig: ICommentTooltipParams = {
    targetSelection: null,
    targetElement: null,
    correspondingDay: null,
    correspondingEmployeeId: null,
    commentType: null,
    autoclose: 3000,
    placement: 'top',
    width: 316,
    component: CommentTooltipComponent,
    styles: null,
    createClasses: 'comment mobile-centered editable',
    editClasses: 'comment mobile-centered'
  };

  protected $target: d3.Selection<any, any, any, any>;
  private correspondingDay: string;
  private correspondingEmployeeId: number;
  private commentType: CommentTypes;

  constructor(
    public tooltipConfigParams: ICommentTooltipParams,
    public tooltipService: TooltipService,
    public commentsService: CommentsService,
    protected comments: CommentModel[] = [],
    protected indicatorPosition = { left: 0, top: 0 },
    protected deleteAlert: any = null,
    protected context: any = null
  ) {
    const configKeys = Object.keys(tooltipConfigParams);
    configKeys.forEach(key => {
      this.tooltipConfig[key] = tooltipConfigParams[key];
    });

    this.$target = tooltipConfigParams.targetSelection;
    this.correspondingDay = tooltipConfigParams.correspondingDay;
    this.correspondingEmployeeId = tooltipConfigParams.correspondingEmployeeId;
    this.commentType = tooltipConfigParams.commentType;
  }

  /************************* Operations with comments **************************/

  public addComment(newComment: CommentModel) {
    const dupl = this.comments.find(comment => comment.id === newComment.id);

    if (!dupl) {
      this.comments.push(newComment);
    }
  }

  public removeComment(comment: CommentModel) {
    const commentIndex = this.comments.indexOf(comment);
    this.removeCommentByIndex(commentIndex);
    this.onRemoveComment.next(comment);
  }

  public removeCommentByIndex(index: number) {
    if (this.comments[index]) {
      this.comments.splice(index, 1);
    }
  }

  public removeCommentById(id) {
    const targetComment = this.comments.find(comment => comment.id === id);
    if (targetComment) {
      const targetIndex = this.comments.indexOf(targetComment);
      this.removeCommentByIndex(targetIndex);
    }
  }

  public removeAllComments() {
    this.comments.length = 0;
    this.destroy();
  }

  /************************* Operations with tooltip **************************/

  public renderCommentsIndicator() {
    this.$target.select('.comment-circle').remove();

    this.$commentsIndicator = this.$target
      .append('circle')
      .classed('comment-circle', true)
      .attr('r', 4)
      .attr('transform', translate(this.indicatorPosition.left, this.indicatorPosition.top))
      .attr('fill', this.comments.length ? this.comments[0].color : CommentModel.defaultColor());
  }

  public destroy() {
    this.comments.length = 0;
    this.removeCommentsIndicator();
  }

  public removeCommentsIndicator() {
    if (this.$commentsIndicator) {
      this.$commentsIndicator.on('mouseenter', null);
      this.$commentsIndicator.on('mouseleave', null);
      this.$commentsIndicator.remove();
      this.$commentsIndicator = void 0;
    }
  }

  public updateCommentIndicatorCallback(hasComments: boolean) {
    if (hasComments) {
      this.renderCommentsIndicator();
    } else {
      this.removeCommentsIndicator();
    }
  }

  public showCommentTooltip() {
    if (!this.$commentsIndicator) {
      this.renderCommentsIndicator();
    } else {
      this.$commentsIndicator.attr('fill', CommentModel.defaultColor());
    }
    // Update position
    this.tooltipConfig.targetElement = this.tooltipConfig.targetSelection.node().getBoundingClientRect();
    this.isOpen = true;

    const tooltip = this.tooltipService.openCustomTooltip({
      targetElement: this.tooltipConfig.targetElement,
      component: this.tooltipConfig.component,
      width: this.tooltipConfig.width,
      placement: this.tooltipConfig.placement,
      autoPlacement: true,
      context: {
        comments: this.comments,
        correspondingDay: this.correspondingDay,
        correspondingEmployeeId: this.correspondingEmployeeId,
        commentType: this.commentType,
        callback: this.updateCommentIndicatorCallback.bind(this)
      },
      classes: this.tooltipConfig.createClasses
    });

    tooltip.onClose.subscribe(() => {
      if (!this.comments || !this.comments.length) {
        this.removeCommentsIndicator();
      } else {
        this.$commentsIndicator.attr('fill', this.comments[0].color);
      }
      this.isOpen = false;
    });

    return tooltip;
  }
}
